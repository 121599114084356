import React from 'react'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { PROJECT_LIST } from '../urls'
import Table from '../components/common/Table'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'
import ProjectTabs from '../components/ProjectTabs'
import ProjectItem from '../components/ProjectItem'

export default function Projects() {
    const projects = useLoad({ url: PROJECT_LIST })

    if (!cp('project.view_project')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <ProjectTabs />
            <span className="is-size-4">Проекты</span><br /><br />

            <Table
                loading={projects.loading}
                totalCount={projects.response ? projects.response.count : 0}
                items={projects.response ? projects.response.results : []}
                columns={{
                    name: 'Имя',
                    actions: '',
                }}
                renderItem={(item) => (
                    <ProjectItem key={item.id} onUpdate={projects.request} onDelete={projects.request} item={item} />
                )} />
        </Layout>
    )
}
