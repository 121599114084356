import React from 'react'
import map from 'lodash/map'
import { usePostRequest } from '../hooks/request'
import { MEMBERS_LIST } from '../urls'
import ProjectMemberForm from './ProjectMemberForm'

export default function ProjectMemberCreate({ onCancel, onSuccess, employees, project, onBack }) {
    const memberCreate = usePostRequest({ url: MEMBERS_LIST })

    async function onSubmit(values, actions) {
        const data = {
            ...values,
            roles: map(values.roles, 'value'),
            project: project.id,
        }
        await memberCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Добавить новый участник</b><br /><br />

            <ProjectMemberForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                employees={employees}
                onBack={onBack}
                loading={memberCreate.loading} />
        </div>
    )
}
