import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as queryString from "query-string";
import { stringify } from "query-string";
import { StyleSheet } from "aphrodite";

import { useModal } from "../hooks/modal";
import { getDate } from "../utils/date";
import Layout from "../components/Layout";
import Table from "../components/common/Table";
import Button from "../components/common/Button";
import FinanceItem from "../components/FinanceItem";
import { useQueryParams } from "../hooks/queryString";
import FinanceCreate from "../components/FinaceCreate";
import { formatNumberWithSpaces } from "../utils/number";
import { FINANCE_LIST, MANTHLY_FINANCE_LIST } from "../urls";
import { useLoad, useSortTableData } from "../hooks/request";
import TransactionSearch from "../components/TransactionSearch";
import cn from "classnames";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Finance({ history }) {
  const params = useQueryParams();
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    includeName: true,
    includeStir: true,
    includeDirectorName: true,
    includeDirectorStir: true,
    includeBank: true,
    includeAccount_number: true,
    mfo: true,
    inspector: false,
    phone_number: true,
    login: false,
    type: true,
    tax_date: true,
    contract_sum: true,
    includeMonthly: true,
    includeZero: true,
  });

  function filterByType(status) {
    history.push(`?${stringify({ ...params, status })}`);
  }
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: type === "checkbox" ? checked : value }));
  };

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const finance = useLoad({ url: FINANCE_LIST, params }, [params]);
  const { items } = useSortTableData(finance.response ? finance.response.results : []);

  const finance_manthly = useLoad({ url: MANTHLY_FINANCE_LIST, params }, [params]);
  const { items: items_manthly } = useSortTableData(finance_manthly.response ? finance_manthly.response.results : []);
  const allItems = [...items.map((item) => ({ ...item, month_type: "manthly" })), ...items_manthly.map((item) => ({ ...item, month_type: "zero" }))].sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const [showModal, hideModal] = useModal(
    <FinanceCreate
      onSuccess={() => {
        hideModal();
        finance.request();
        finance_manthly.request();
      }}
      onCancel={() => hideModal()}
    />,
    styles.modal,
  );

  function onSearch(query) {
    const search = !isEmpty(query) ? query : undefined;
    history.push(`?${queryString.stringify({ search })}`);
  }

  const exportToPdf = () => {
    const headers = [{ text: "№", bold: true }];
    const data = [];

    let index = 1;

    // const allData = [];
    // if (formData.includeZero) {
    //   allData.push(...(finance_manthly.response ? finance_manthly.response.results : []));
    // }
    // if (formData.includeMonthly) {
    //   allData.push(...(finance.response ? finance.response.results : []));
    // }

    // allData.sort((a, b) => {
    //   const nameA = a.name ? a.name.toLowerCase() : "";
    //   const nameB = b.name ? b.name.toLowerCase() : "";
    //   return nameA.localeCompare(nameB, "uz", { sensitivity: "base" });
    // });

    allItems.forEach((row) => {
      const rowData = [index++];

      if (formData.includeName) {
        headers.find((header) => header.text === "Корхона номи") || headers.push({ text: "Корхона номи", bold: true });
        rowData.push(row.name);
      }
      if (formData.includeStir) {
        headers.find((header) => header.text === "Юридик стир") || headers.push({ text: "Юридик стир", bold: true });
        rowData.push(row.stir);
      }
      if (formData.includeDirectorName) {
        headers.find((header) => header.text === "Корхона рахбари") || headers.push({ text: "Корхона рахбари", bold: true });
        rowData.push(row.directorName);
      }
      if (formData.includeDirectorStir) {
        headers.find((header) => header.text === "Жисмоний стир") || headers.push({ text: "Жисмоний стир", bold: true });
        rowData.push(row.directorStir);
      }
      if (formData.includeBank) {
        headers.find((header) => header.text === "Банк") || headers.push({ text: "Банк", bold: true });
        rowData.push(row.bank);
      }
      if (formData.includeAccount_number) {
        headers.find((header) => header.text === "Х/р") || headers.push({ text: "Х/р", bold: true });
        rowData.push(row.accountNumber);
      }
      if (formData.mfo) {
        headers.find((header) => header.text === "МФО") || headers.push({ text: "МФО", bold: true });
        rowData.push(row.mfo);
      }
      if (formData.inspector) {
        headers.find((header) => header.text === "Солик инспектори") || headers.push({ text: "Солик инспектори", bold: true });
        rowData.push(row.inspector);
      }
      if (formData.phone_number) {
        headers.find((header) => header.text === "Корхона рахбари телефони") || headers.push({ text: "Корхона рахбари телефони", bold: true });
        rowData.push(row.phoneNumber);
      }
      if (formData.login) {
        headers.find((header) => header.text === "Интернет банк логин") || headers.push({ text: "Интернет банк логин", bold: true });
        rowData.push(row.login);
      }
      if (formData.type) {
        headers.find((header) => header.text === "Солиқ тури") || headers.push({ text: "Солиқ тури", bold: true });
        rowData.push(row.type === "rotation" ? "Айланма" : "ҚҚC-ойлик");
      }
      if (formData.tax_date) {
        headers.find((header) => header.text === "ҚҚСга ўтган ой") || headers.push({ text: "ҚҚСга ўтган ой", bold: true });
        rowData.push(row.taxDate ? getDate(row.taxDate) : null);
      }
      if (formData.contract_sum) {
        headers.find((header) => header.text === "Сумма контракта") || headers.push({ text: "Сумма контракта", bold: true });
        rowData.push(formatNumberWithSpaces(row.contractSum));
      }

      data.push(rowData);
    });

    const docDefinition = {
      pageOrientation: "landscape",
      pageMargins: [20, 20, 20, 20],
      content: [
        { text: "Мижозлар", style: "header", alignment: "center" },
        {
          alignment: "center",
          table: {
            headerRows: 1,
            widths: headers.map(() => "auto"),
            body: [headers.map((header) => ({ text: header.text, bold: true, fillColor: "lightgray" })), ...data],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          textAlign: "center",
          color: "black",
        },
        tableHeader: { bold: true, fontSize: 12, color: "black" },
      },
      defaultStyle: { fontSize: 8 },
    };

    pdfMake.createPdf(docDefinition).download("Мижозлар.pdf");
  };

  return (
    <Layout>
      <div style={{ position: "sticky", top: 0, left: 0, width: "100%", zIndex: 10, backgroundColor: "white", margin: "0 auto", padding: "0px", display: "flex", justifyContent: "space-between" }}>
        <div className="column mt-3" style={{ padding: "5px", maxWidth: "700px" }}>
          <TransactionSearch onSearch={onSearch} />
        </div>

        <div className="mt-3 is-flex" style={{ padding: "5px" }}>
          <div className="is-pulled-right is-success is-outlined">
            <Button icon="ion-md-download" text="PDF" className="is-pulled-right is-success is-outlined mr-3" onClick={exportToPdf} />

            {isModalOpen && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "24px",
                    borderRadius: "12px",
                    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
                    maxWidth: "400px",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <h2 style={{ fontSize: "20px", fontWeight: "600", marginBottom: "16px" }}>PDF экспорт учун майдонлар ва маълумотларни танланг</h2>

                  <div style={{ marginBottom: "12px" }}>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="includeName" checked={formData.includeName} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Корхона номи
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="includeStir" checked={formData.includeStir} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Юридик стир
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="includeDirectorName" checked={formData.includeDirectorName} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Корхона рахбари
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="includeDirectorStir" checked={formData.includeDirectorStir} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Жисмоний стир
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="includeBank" checked={formData.includeBank} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Банк
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="includeAccount_number" checked={formData.includeAccount_number} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Х/р
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="mfo" checked={formData.mfo} onChange={handleInputChange} style={{ marginRight: "8px" }} /> МФО
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="phone_number" checked={formData.phone_number} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Корхона рахбари телефони
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="type" checked={formData.type} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Солиқ тури
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="tax_date" checked={formData.tax_date} onChange={handleInputChange} style={{ marginRight: "8px" }} /> ҚҚСга ўтган ой
                    </label>
                    <label style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                      <input type="checkbox" name="contract_sum" checked={formData.contract_sum} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Сумма контракта
                    </label>
                  </div>

                  <h2 className="is-size-4 is-capitalized">маълумотлар</h2>

                  <div style={{ marginBottom: "16px" }}>
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input type="checkbox" name="includeMonthly" checked={formData.includeMonthly} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Ойлик маълумотлар
                    </label>
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input type="checkbox" name="includeZero" checked={formData.includeZero} onChange={handleInputChange} style={{ marginRight: "8px" }} /> Ноллик маълумотлар
                    </label>
                  </div>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button onClick={() => setModalOpen(false)} style={{ marginRight: "8px", padding: "8px 16px", backgroundColor: "#e0e0e0", borderRadius: "4px", border: "none", cursor: "pointer" }}>
                      Отмена
                    </button>
                    <button onClick={exportToPdf} style={{ padding: "8px 16px", backgroundColor: "#007bff", color: "white", borderRadius: "4px", border: "none", cursor: "pointer" }}>
                      Экспорт
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <Button
						icon='ion-md-search'
						text='Корхона номи'
						className={cn(getClassNamesFor('name'), getClassNamesFor_manthly('name'), 'is-pulled-right is-success is-outlined mr-3')}
						onClick={() => {
							requestSort('name');
							requestSort_manthly('name');
						}}
					/> */}
          {/* <Button
						icon='ion-md-search'
						text='Корхона стири'
						onClick={() => {
							requestSort('stir');
							requestSort_manthly('stir');
						}}
						className={cn(getClassNamesFor('stir'), getClassNamesFor_manthly('stir'), 'is-pulled-right is-success is-outlined mr-3')}
					/>
					<Button
						icon='ion-md-search'
						text='Рахбари стири'
						onClick={() => {
							requestSort('directorStir');
							requestSort_manthly('directorStir');
						}}
						className={cn(getClassNamesFor('directorStir'), getClassNamesFor_manthly('directorStir'), 'is-pulled-right is-success is-outlined mr-3')}
					/> */}
          {/* <Button
						icon='ion-md-search'
						text='Сумма'
						onClick={() => {
							requestSort('contractSum');
							requestSort_manthly('contractSum');
						}}
						className={cn(getClassNamesFor('contractSum'), getClassNamesFor_manthly('contractSum'), 'is-pulled-right is-success is-outlined mr-3')}
					/> */}
          <Button icon="ion-md-add" onClick={showModal} text="Янги мижоз қўшиш" className="is-pulled-right is-success is-outlined" />
        </div>
      </div>

      <div style={{ margin: "15px 0 20px 0", display: "flex", alignItems: "center", gap: "30px" }}>
        <hgroup>
          <div className="tags is-right">
            <a onClick={() => filterByType("active")} className={cn("tag", { "light-primary": params.status === undefined || params.status === "active" })}>
              <i className="icon ion-ios-flame" />
              Активные
            </a>

            <a onClick={() => filterByType("archive")} className={cn("tag", { "light-gray": params.status === "archive" })}>
              <i className="icon ion-ios-filing" />
              Архив
            </a>
          </div>
        </hgroup>
        <span className="is-size-4">Мижозлар сони: {allItems?.length && allItems?.length}</span>
        <span className="is-size-4" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} onClick={handleClick}>
          Сумма контракта: {allItems?.reduce((a, b) => a + b.contractSum, 0).toLocaleString("ru-RU")} Сўм
        </span>
      </div>

      <div style={{ overflow: "scroll" }}>
        <div style={{ overflow: "scroll" }}>
          <Table
            loading={finance.loading}
            items={allItems}
            columns={{
              id: "№",
              name: "Корхона номи",
              stir: "Корхона стири",
              directorName: "Корхона рахбари",
              directorStir: "Рахбари стири",
              first: "ЭРИ (Корхона)",
              second: "ЭРИ (Раҳбар)",
              assds: "",
              asds: "",
            }}
            renderItem={(item, index) => (
              <FinanceItem
                page={1}
                key={item.id}
                index={index}
                onUpdate={() => {
                  finance.request(); // Call finance request
                  finance_manthly.request(); // Call finance monthly request
                }}
                onDelete={() => {
                  finance.request(); // Call finance request
                  finance_manthly.request(); // Call finance monthly request
                }}
                item={item}
              />
            )}
          />

          {/* <br />

					<Table
						loading={finance_manthly.loading}
						items={items_manthly}
						columns={{ id: '№', name: 'Корхона номи', stir: 'Корхона стири', directorName: 'Корхона рахбари', directorStir: 'Рахбари стири', first: 'ЭРИ(Корхона)', second: 'ЭРИ(Раҳбар)', assds: '', asds: '' }}
						renderItem={(item, index) => <ManthlyFinanceItem key={item.id} index={index} page={1} onUpdate={finance_manthly.request} onDelete={finance_manthly.request} item={item} />}
					/> */}
        </div>
      </div>
    </Layout>
  );
}

const styles = StyleSheet.create({ modal: { width: 800 } });
