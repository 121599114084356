import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp } from '../utils/auth'

export default function FinanceTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                {cp('finance.view_wallet') ? (
                    <li className={cn({ 'is-active': path.startsWith('/finance/wallet') })}>
                        <NavLink to="/finance/wallet">Счета</NavLink>
                    </li>
                ) : null}

                {cp('finance.view_asset') ? (
                    <li className={cn({ 'is-active': path.startsWith('/finance/asset') })}>
                        <NavLink to="/finance/asset">Активы</NavLink>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
