/* eslint-disable no-nested-ternary */
import React from 'react'
import { checkPermission as cp } from '../utils/auth'
import Loader from './common/Loader'
import { useDeleteRequest } from '../hooks/request'
import { PROJECT_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import { useModal } from '../hooks/modal'
import ProjectUpdate from './ProjectUpdate'
import LeadInfo from './LeadInfo'

export default function ProjectItem({ item, onDelete, onUpdate }) {
    const projectDelete = useDeleteRequest({ url: PROJECT_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    const [showUpdateModal, hideUpdateModal] = useModal(
        <ProjectUpdate project={item} onCancel={() => hideUpdateModal()} onUpdate={async () => {
            await onUpdate()
            hideUpdateModal()
        }} />,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <LeadInfo
            onCancel={() => hideInfoModal()}
            onChange={() => {
                hideInfoModal()
                showUpdateModal()
            }}
            lead={{ ...item.lead, project: item }} />,
    )

    async function deleteProject() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await projectDelete.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        onDelete()
    }

    return (
        <tr>
            <td onClick={showInfoModal}>{item.lead.name}</td>

            <td className="has-text-right">
                {cp('project.delete_project') ? (
                    !projectDelete.loading ? (
                        <i onClick={() => deleteProject()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('project.change_project') ? (
                    <i onClick={() => showUpdateModal()} className="icon pointer ion-md-create" />
                ) : null}
            </td>
        </tr>
    )
}
