export const positions = {
    admin: 'Админ / директор',
    accountant: 'Бухгалтер',
    // marketing: 'Маркетолог',
    // team_lead: 'Лидер команды',
    // developer: 'Программист',
    // hr: 'HR менеджер',
    // seller: 'Продавец',
    // lawyer: 'Юрист',
    // supply: 'Снабженец',
    // quality: 'Контроль качества',
    // qualification: 'Квалификация',
    // cleaner: 'Уборщик',
}

export const classes = {
    admin: 'is-success',
    accountant: 'is-danger',
    marketing: 'is-primary',
    team_lead: 'is-warning',
    developer: 'is-info',
    hr: 'is-link',
    seller: 'is-success is-light',
    lawyer: 'is-danger is-light',
    supply: 'is-primary is-light',
    quality: 'is-warning is-light',
    qualification: 'is-info is-light',
}

export const developersLevel = [
    { name: 'Студент (On Boarding)', id: 'onboarding_student' },
    { name: 'Интерн (Джуниор)', id: 'junior_intern' },
    { name: 'Практик (Джуниор)', id: 'junior_practitioner' },
    { name: 'Красавчик(ица) (Джуниор)', id: 'junior_attaboy' },
    { name: 'Шаолинь (мидл)', id: 'middle_shaolin' },
    { name: 'Ниндзя (мидл)', id: 'middle_ninja' },
    { name: 'Бэтмен (мидл)', id: 'middle_batman' },
    { name: 'Мудрец (сеньор)', id: 'senior_wise' },
    { name: 'Рок звезда (сеньор)', id: 'senior_rockstar' },
    { name: 'Джедаи (сеньор)', id: 'senior_jedi' },
]

export function position(name) {
    return positions[name]
}

export function positionClass(name) {
    return classes[name]
}

export const phoneOwnerOptions = [
    { label: 'Активные', value: 'active' },
    { label: 'Архив', value: 'archive' },
]

export function getPhoneOwnerLabel(value){
    return phoneOwnerOptions.find(item => item.value === value)?.label
}
export const financeTypes = {
    rotation: 'Айланма',
    qqc_monthly: 'ҚҚC-ойлик',
}

export function financeType(name) {
    return financeTypes[name]
}
