import React from 'react';
import { StyleSheet } from 'aphrodite';
import { MANTHLY_FINANCE_DETAIL } from '../urls';
import { useDeleteRequest } from '../hooks/request';
import { useModal } from '../hooks/modal';
import { useMessage } from '../hooks/message';
import { domain } from '../utils/request';
import Button from './componentsFinance/common/Button';
import ManthlyFinanceUpdate from './ManthlyFinanceUpdate';
import ManthlyFinanceInfo from './ManthlyFinanceInfo';

export default function ManthlyFinanceItem({ item, index, onDelete, onUpdate, page }) {
	const financeDelete = useDeleteRequest({ url: MANTHLY_FINANCE_DETAIL.replace('{id}', item.id) });
	const [showUpdateModal, hideUpdateModal] = useModal(
		<ManthlyFinanceUpdate
			finance={item}
			onUpdate={() => {
				onUpdate();
				hideUpdateModal();
			}}
			onCancel={() => hideUpdateModal()}
		/>,
		styles.modal
	);

	const [showMessage] = useMessage();

	async function deleteFinance() {
		if (!global.confirm('Вы действительно хотите удалить?')) return;

		const { error } = await financeDelete.request();
		if (error) {
			showMessage(error.data.detail, 'is-danger');
			return;
		}
		onDelete();
	}

	const [showViewModal, hideViewModal] = useModal(<ManthlyFinanceInfo lead={item} onSuccess={() => hideViewModal()} onCancel={() => hideViewModal()} />, styles.modal);

	const itemsPerPage = 10;
	const position = (page - 1) * itemsPerPage + index + 1;

	return (
		<tr>
			<td style={{ width: '40px', textAlign: 'center' }}>{index + 1}</td>
			<td onClick={showViewModal} className='has-text-center pr-3 pl-3'>
				{item.name}
			</td>
			<td className='has-text-center pr-3 pl-3'>{item.stir}</td>
			<td className='has-text-center pr-3 pl-3'>{item.directorName}</td>
			<td className='has-text-center pr-3 pl-3'>{item.directorStir}</td>
			<td className='pr-6'>
				<a href={domain + item.first} className='has-text-dark has-text-center'>
					<Button icon='icon ion-md-download' className='is-pulled-right is-black is-outlined' />
				</a>
			</td>
			<td className='pr-6'>
				<a href={domain + item.second} className='has-text-dark'>
					<Button icon='icon ion-md-download' className='is-pulled-right is-black is-outlined' />
				</a>
			</td>
			<td className='has-text-center pr-3 pl-3'>
				<Button icon='icon ion-md-trash' onClick={deleteFinance} className='is-pulled-right is-danger is-outlined' />
			</td>
			<td className='has-text-center pr-3 pl-3'>
				<Button icon='icon ion-md-create' onClick={showUpdateModal} className='is-pulled-right is-success is-outlined' />
			</td>
		</tr>
	);
}
const styles = StyleSheet.create({ modal: { width: 800 } });
