import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import { types } from '../utils/types'
import Input from './common/Input'
import Button from './common/Button'
import Select from './common/Select'

export default function TransactionCategoryForm({ onSubmit, onCancel, loading, initialValues }) {
    const defaultAmount = initialValues && initialValues.defaultAmount ? initialValues.defaultAmount : ''
    const descriptionTemplate = initialValues && initialValues.descriptionTemplate
        ? initialValues.descriptionTemplate : ''

    const values = { name: '', type: '', ...initialValues, defaultAmount, descriptionTemplate }

    return (
        <Formik initialValues={values} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="name"
                    label="Название"
                    disabled={loading}
                    validate={required}
                    placeholder="Введите имя" />

                <Input
                    optional
                    name="defaultAmount"
                    type="number"
                    label="Сумма по умолчанию"
                    disabled={loading}
                    placeholder="Введите сумму" />

                <Input
                    optional
                    name="descriptionTemplate"
                    label="Описание по умолчанию"
                    disabled={loading}
                    placeholder="Введите текст" />

                <Select
                    label="Тип"
                    optionValue="value"
                    optionLabel="label"
                    name="type"
                    options={types}
                    validate={required} />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
