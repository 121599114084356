import React from 'react'
import { usePostRequest } from '../hooks/request'
import { PROJECT_LIST } from '../urls'
import ProjectForm from './ProjectForm'

export default function ProjectCreate({ onCancel, onSuccess, lead, customer }) {
    const projectCreate = usePostRequest({ url: PROJECT_LIST })

    async function onSubmit(data, actions) {
        const project = await projectCreate.request({ data: { ...data, lead: lead.id } })
        actions.resetForm()

        if (project.success) {
            onSuccess(project.response)
        }
    }

    const initialValues = {
        planDay: '1',
        planTime: '09:30',
        planPlace: 'Офис',
        teamLeadShare: '10',
        monthlyPrice: '',
        customer,
        gitUrl: '',
    }

    return (
        <div>
            <b>Добавить проект</b><br /><br />

            <ProjectForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                initialValues={initialValues}
                loading={projectCreate.loading} />
        </div>
    )
}
