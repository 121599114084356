import React, { Fragment } from 'react'
import { Form, Formik } from 'formik'
import Input from './common/Input'
import { required } from '../utils/validators'
import Select from './common/Select'
import { DAYS } from '../utils/date'
import Button from './common/Button'
import { useLoad } from '../hooks/request'
import { CUSTOMER_LIST, SALES_CHANNEL_LIST } from '../urls'

export default function ProjectForm({ onCancel, onSubmit, initialValues, loading, updateWithLead = false }) {
    const salesChannel = useLoad({ url: SALES_CHANNEL_LIST })
    const customers = useLoad({ url: CUSTOMER_LIST })

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            <Form>
                {updateWithLead ? (
                    <Fragment>
                        <div className="columns">
                            <div className="column">
                                <Input name="name" label="Имя" validate={required} autoFocus autoComplete="off" />
                            </div>

                            <div className="column">
                                <Select
                                    name="salesChannel"
                                    options={salesChannel.response ? salesChannel.response.results : []}
                                    label="Канал продаж"
                                    help="Добавить канал продаж можно в настройках"
                                    loading={salesChannel.loading}
                                    validate={required} />
                            </div>
                        </div>

                        <Input name="description" component="textarea" label="Примечание" optional />
                    </Fragment>
                ) : null}

                <div className="mb-3">
                    <div className="columns mb-0">
                        <div className="column">
                            <Input
                                name="monthlyPrice"
                                label="Ежемесячная плата"
                                validate={required}
                                type="number" />
                        </div>

                        <div className="column">
                            <Input name="teamLeadShare" label="Доля тимлида" validate={required} type="number" />
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <Input name="planPlace" label="Место встречи" />
                        </div>

                        <div className="column">
                            <Select
                                name="planDay"
                                options={DAYS}
                                label="День планирования"
                                optionValue="key"
                                optionLabel="title"
                                validate={required} />
                        </div>

                        <div className="column">
                            <Input name="planTime" label="Время планирования" type="time" />
                        </div>
                    </div>
                    
                    <Input name="gitUrl" className="column"  label="Git URL" />
                </div>

                <Select
                    label="Ответственное лицо"
                    name="customer"
                    className="mb-1"
                    options={customers.response ? customers.response.results : []}
                    loading={customers.loading}
                    validate={required} />

                <div className="mt-3">
                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </div>
            </Form>
        </Formik>
    )
}
