import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp } from '../utils/auth'

export default function ProjectTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                {cp('project.view_lead') ? (
                    <li className={cn({ 'is-active': path.startsWith('/project/leads') })}>
                        <NavLink to="/project/leads">Лиды</NavLink>
                    </li>
                ) : null}

                {cp('project.view_project') ? (
                    <li className={cn({ 'is-active': path.startsWith('/project/projects') })}>
                        <NavLink to="/project/projects">Проекты</NavLink>
                    </li>
                ) : null}

                {cp('project.view_customer') ? (
                    <li className={cn({ 'is-active': path.startsWith('/project/customers') })}>
                        <NavLink to="/project/customers">Клиенты</NavLink>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}
