import React from 'react'
import { day, getDateTime as gdt } from '../utils/date'
import Button from './common/Button'

export default function LeadInfo({ lead, onCancel, onChange }) {
    return (
        <div>
            <b>Информация о Проекте: {lead.name}</b><br /><br />

            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>Название</td>
                        <td>{lead.name || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>

                    <tr>
                        <td>Ежемесячная плата</td>
                        <td>
                            {lead.project ? lead.project.monthlyPrice : <span className="has-text-grey">Пусто</span>}
                        </td>
                    </tr>

                    <tr>
                        <td>Планирование</td>

                        <td>
                            {lead.project ? lead.project.planPlace : <span className="has-text-grey">Пусто</span>}&nbsp;
                            {/* {lead.project && lead.project.planDay ? day(lead.project.planDay).title : null}&nbsp; */}
                            {lead.project && lead.project.planTime ? lead.project.planTime.substring(0, 5) : null}
                        </td>
                    </tr>

                    <tr>
                        <td>Дедлайн</td>

                        <td>
                            {lead.project && lead.project.deadline
                                ? gdt(lead.project.deadline)
                                : <span className="has-text-grey">Пусто</span>}
                        </td>
                    </tr>

                    <tr>
                        <td>Канал продаж:</td>
                        <td>{lead.salesChannel.name || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>

                    <tr>
                        <td>Дата добавления:</td>

                        <td>
                            {lead.createdAt ? gdt(lead.createdAt) : <span className="has-text-grey">Пусто</span>}
                        </td>
                    </tr>

                    <tr>
                        <td>Примечание:</td>
                        <td>{lead.description || <span className="has-text-grey">Пусто</span>}</td>
                    </tr>

                    <tr>
                        <td>Git URL:</td>
                        {/* <td>{ lead.project.gitUrl || <span className="has-text-grey">Пусто</span> }</td> */}
                    </tr>
                </tbody>
            </table>

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger" /> &nbsp;

            <Button
                text="Изменить"
                onClick={onChange}
                icon="ion-md-create"
                className="is-primary" />
        </div>
    )
}
