import { domain } from './utils/request'

export const SIGNIN = '/users/sign-in'
export const SIGNUP = '/users/sign-up'
export const SIGNOUT = '/users/sign-out'
export const USER_SETTINGS = '/users/user_settings'
export const CHANGE_PASSWORD = '/users/change_password'
export const CONFIRM = '/users/confirm'
export const RESET_PASSWORD = '/users/reset_password'
export const RESET_LINK = '/users/reset_link'
export const USERS_LIST = '/users/user'
export const USERS_DETAIL = '/users/user/{id}/'

export const SALES_CHANNEL_LIST = '/marketing/sales_channel/'
export const SALES_CHANNEL_DETAIL = '/marketing/sales_channel/{id}'
export const SALES_CHANNEL_CHANGE_MAIN = '/marketing/sales_channel/change_main/{id}'

export const TRANSACTION_LIST = '/finance/transaction/'
export const TRANSACTION_TYPE_LIST = '/finance/transaction-type/'
export const TRANSACTION_DETAIL = '/finance/transaction/{id}'
export const TRANSACTION_CATEGORY_LIST = '/finance/transaction-category/'
export const TRANSACTION_CATEGORY_DETAIL = '/finance/transaction-category/{id}'
export const TRANSACTION_CATEGORY_CHANGE_MAIN = '/finance/transaction-category/change_main/{id}'
export const TRANSACTION_TRANSFER = '/finance/transaction-transfer/{id}'

export const WALLET_LIST = '/finance/wallet/'
export const WALLET_DETAIL = '/finance/wallet/{id}'
export const WALLET_CHANGE_MAIN = '/finance/wallet/change_main/{id}'

export const EMPLOYEE_LIST = '/staff/employee/'
export const EMPLOYEE_DETAIL = '/staff/employee/{id}'
export const EMPLOYEE_IMAGE = '/staff/employee-image/{id}'

export const EMPLOYEE_SIMPLE_LIST = '/staff/employee-simple-list/'
export const EMPLOYEE_SALARY = '/staff/employee-salary/{id}'
export const EMPLOYEE_SALARY_HISTORY = '/staff/employee-salary/{id}/history'
export const INVITATION = '/staff/invite'
export const INVITATION_SIGNUP = '/staff/invitation-sign-up/{code}'
export const TELEGRAM_INVITATION = `${domain}/telegram/telegram-invite/{id}`
export const DEVELOPERS_LEADER_BOARD = '/staff/developers-leader-board/'

export const SALARY_LIST = 'finance/salary/{employee_id}'

export const BOARD_LIST = 'tasks/board/'
export const BOARD_DETAIL = 'tasks/board/{id}'

export const TASK_LIST = 'tasks/tasks/'
export const TASK_DETAIL = 'tasks/tasks/{id}'
export const COMMENT_LIST = 'tasks/comment/'
export const COMMENT_DETAIL = 'tasks/comment/{id}'
export const MERGE_REQUEST = 'tasks/tasks/{id}/merge-request'

export const BOARD_TASK_LIST = 'tasks/board/{board_id}/board_task/'
export const BOARD_TASK_DETAIL = 'tasks/board/{board_id}/board_task/{id}'
export const BOARD_TASK_MOVE = 'tasks/board/{board_id}/board_task/{id}/move'

export const STATISTICS_OUTCOME = 'stats/outcome'
export const STATISTICS_FINANCE = 'stats/finance'
export const STATISTICS_SALES_FUNNEL = 'stats/sales_funnel'

export const LEAD_LIST = 'project/lead/'
export const LEAD_DETAIL = 'project/lead/{id}/'
export const LEAD_STATUS_LIST = 'project/status/'
export const LEAD_MOVE = 'project/lead/{id}/move/'

export const PROJECT_SIMPLE_DETAIL = 'project/project-simple/{id}/'
export const PROJECT_SIMPLE_LIST = 'project/project-simple/'
export const PROJECT_LIST = 'project/project/'
export const PROJECT_DETAIL = 'project/project/{id}'

export const MEMBERS_LIST = 'project/member/'
export const MEMBERS_DETAIL = 'project/member/{id}'

export const ASSET_LIST = 'finance/asset/'
export const ASSET_DETAIL = 'finance/asset/{id}'
export const CUSTOMER_LIST = 'project/customer/'
export const CUSTOMER_DETAIL = 'project/customer/{id}'


// export const SIGNIN = '/users/sign-in'
// export const SIGNOUT = '/users/sign-out'

// Finance
export const FINANCE_LIST = '/zero/finance/'
export const FINANCE_DETAIL = 'zero/finance/{id}'

// Rotation
export const ROTATION_LIST = 'zero/rotation/'
export const ROTATION_DETAIL = 'zero/rotation/{id}'

// Rotation Item
export const ROTATION_ITEM_LIST = 'zero/rotation/{rotationId}/rotation-item'
export const ROTATION_ALL_ITEM_LIST = 'zero/rotation-item'
export const ROTATION_ITEM_DETAIL = 'zero/rotation/rotation-item/{id}'


// Balance
export const BALANCE_LIST = 'zero/balance/'
export const BALANCE_DETAIL = 'zero/balance/{id}'
// Balance Item
export const BALANCE_ITEM_LIST = 'zero/balance/{balanceId}/balance-item'
export const BALANCE_ALL_ITEM_LIST = 'zero/balance-item'
export const BALANCE_ITEM_DETAIL = 'zero/balance/balance-item/{id}'
// Result
export const RESULT_LIST = 'zero/result/'
export const RESULT_DETAIL = 'zero/result/{id}'
// Result Item
export const RESULT_ITEM_LIST = 'zero/result/{resultId}/result-item'
export const RESULT_ALL_ITEM_LIST = 'zero/result-item'
export const RESULT_ITEM_DETAIL = 'zero/result/result-item/{id}'
// Manthly Balance
export const MANTHLY_BALANCE_LIST = 'manthly/balance/'
export const MANTHLY_BALANCE_DETAIL = 'manthly/balance/{id}'
// Balance Item
export const MANTHLY_BALANCE_ITEM_LIST = 'manthly/balance/{balanceId}/balance-item'
export const MANTHLY_BALANCE_ALL_ITEM_LIST = 'manthly/balance-item'
export const MANTHLY_BALANCE_ITEM_DETAIL = 'manthly/balance/balance-item/{id}'
// Result
export const MANTHLY_RESULT_LIST = 'manthly/result/'
export const MANTHLY_RESULT_DETAIL = 'manthly/result/{id}'
// Result Item
export const MANTHLY_RESULT_ITEM_LIST = 'manthly/result/{resultId}/result-item'
export const MANTHLY_RESULT_ALL_ITEM_LIST = 'manthly/result-item'
export const MANTHLY_RESULT_ITEM_DETAIL = 'manthly/result/result-item/{id}'
// Qqc  Monthly
export const QQC_MONTHLY_LIST = 'zero/qqc-monthly/'
export const QQC_MONTHLY_DETAIL = 'zero/qqc-monthly/{id}'

// Qqc  Monthly Item
export const QQC_MONTHLY_ITEM_LIST = 'zero/qqc-monthly/{qqcMonthlyId}/qqc-monthly-item'
export const QQC_MONTHLY_ALL_ITEM_LIST = 'zero/qqc-monthly-item'
export const QQC_MONTHLY_ITEM_DETAIL = 'zero/qqc-monthly/qqc-monthly-item/{id}'

// Qqc  Quarterly
export const QQC_QUARTERLY_LIST = 'zero/qqc-quarterly/'
export const QQC_QUARTERLY_DETAIL = 'zero/qqc-quarterly/{id}'

// Qqc  Quarterly Item
export const QQC_QUARTERLY_ITEM_LIST = 'zero/qqc-quarterly/{qqcQuarterlyId}/qqc-quarterly-item'
export const QQC_QUARTERLY_ALL_ITEM_LIST = 'zero/qqc-quarterly-item'
export const QQC_QUARTERLY_ITEM_DETAIL = 'zero/qqc-quarterly/qqc-quarterly-item/{id}'

// Income Tax
export const INCOME_TAX_LIST = 'zero/income-tax/'
export const INCOME_TAX_DETAIL = 'zero/income-tax/{id}'

// Income Tax Item
export const INCOME_TAX_ITEM_LIST = 'zero/income-tax/{incomeTaxId}/income-tax-item'
export const INCOME_TAX_ALL_ITEM_LIST = 'zero/income-tax-item'
export const INCOME_TAX_ITEM_DETAIL = 'zero/income-tax/income-tax-item/{id}'

// Income Quarterly
export const INCOME_QUARTERLY_LIST = 'zero/income-quarterly/'
export const INCOME_QUARTERLY_DETAIL = 'zero/income-quarterly/{id}'

// Income
export const INCOME_LIST = 'zero/income/'
export const INCOME_DETAIL = 'zero/income/{id}'
// Income Date
export const INCOME_DATE_LIST = 'zero/income-date/'
export const INCOME_DATE_DETAIL = 'zero/income-date/{id}'

// Income Quarterly Item
export const INCOME_QUARTERLY_ITEM_LIST = 'zero/income-quarterly/{incomeQuarterlyId}/income-quarterly-item'
export const INCOME_QUARTERLY_ALL_ITEM_LIST = 'zero/income-quarterly-item'
export const INCOME_QUARTERLY_ITEM_DETAIL = 'zero/income-quarterly/income-quarterly-item/{id}'

// Manthly Finance
export const MANTHLY_FINANCE_LIST = 'manthly/finance/'
export const MANTHLY_FINANCE_DETAIL = 'manthly/finance/{id}'

// Manthly Rotation
export const MANTHLY_ROTATION_LIST = 'manthly/rotation/'
export const MANTHLY_ROTATION_DETAIL = 'manthly/rotation/{id}'

// Manthly Rotation Item
export const MANTHLY_ROTATION_ITEM_LIST = 'manthly/rotation/{rotationId}/rotation-item'
export const MANTHLY_ROTATION_ALL_ITEM_LIST = 'manthly/rotation-item'
export const MANTHLY_ROTATION_ITEM_DETAIL = 'manthly/rotation/rotation-item/{id}'

// Manthly Qqc  Monthly
export const MANTHLY_QQC_MONTHLY_LIST = 'manthly/qqc-monthly/'
export const MANTHLY_QQC_MONTHLY_DETAIL = 'manthly/qqc-monthly/{id}'

// Manthly Qqc  Monthly Item
export const MANTHLY_QQC_MONTHLY_ITEM_LIST = 'manthly/qqc-monthly/{qqcMonthlyId}/qqc-monthly-item'
export const MANTHLY_QQC_MONTHLY_ALL_ITEM_LIST = 'manthly/qqc-monthly-item'
export const MANTHLY_QQC_MONTHLY_ITEM_DETAIL = 'manthly/qqc-monthly/qqc-monthly-item/{id}'

// Manthly Qqc  Quarterly
export const MANTHLY_QQC_QUARTERLY_LIST = 'manthly/qqc-quarterly/'
export const MANTHLY_QQC_QUARTERLY_DETAIL = 'manthly/qqc-quarterly/{id}'

// Manthly Qqc  Quarterly Item
export const MANTHLY_QQC_QUARTERLY_ITEM_LIST = 'manthly/qqc-quarterly/{qqcQuarterlyId}/qqc-quarterly-item'
export const MANTHLY_QQC_QUARTERLY_ALL_ITEM_LIST = 'manthly/qqc-quarterly-item'
export const MANTHLY_QQC_QUARTERLY_ITEM_DETAIL = 'manthly/qqc-quarterly/qqc-quarterly-item/{id}'

// Manthly Income Tax
export const MANTHLY_INCOME_TAX_LIST = 'manthly/income-tax/'
export const MANTHLY_INCOME_TAX_DETAIL = 'manthly/income-tax/{id}'

// Manthly Income Tax Item
export const MANTHLY_INCOME_TAX_ITEM_LIST = 'manthly/income-tax/{incomeTaxId}/income-tax-item'
export const MANTHLY_INCOME_TAX_ALL_ITEM_LIST = 'manthly/income-tax-item'
export const MANTHLY_INCOME_TAX_ITEM_DETAIL = 'manthly/income-tax/income-tax-item/{id}'

// Manthly Income Quarterly
export const MANTHLY_INCOME_QUARTERLY_LIST = 'manthly/income-quarterly/'
export const MANTHLY_INCOME_QUARTERLY_DETAIL = 'manthly/income-quarterly/{id}'

// Manthly Income
export const MANTHLY_INCOME_LIST = 'manthly/income/'
export const MANTHLY_INCOME_DETAIL = 'manthly/income/{id}'
// Manthly Income Date
export const MANTHLY_INCOME_DATE_LIST = 'manthly/income-date/'
export const MANTHLY_INCOME_DATE_DETAIL = 'manthly/income-date/{id}'
export const GENERAL = 'core/general/'
// Manthly Income Quarterly Item
export const MANTHLY_INCOME_QUARTERLY_ITEM_LIST = 'manthly/income-quarterly/{incomeQuarterlyId}/income-quarterly-item'
export const MANTHLY_INCOME_QUARTERLY_ALL_ITEM_LIST = 'manthly/income-quarterly-item'
export const MANTHLY_INCOME_QUARTERLY_ITEM_DETAIL = 'manthly/income-quarterly/income-quarterly-item/{id}'

export const TELEGRAM = 'bot/telegram-users'


export const MANTHLY_DUE_LIST = '/manthly/due/'
export const MANTHLY_DUE_DETAIL = 'manthly/due/{id}'
export const MANTHLY_DUE_DATE_LIST = 'manthly/due_date/'
export const MANTHLY_DUE_DATE_DETAIL = 'manthly/due_date/{id}'
export const MANTHLY_DUE_ITEM_LIST = 'manthly/due_item/{dueDateId}'
export const MANTHLY_DUE_ALL_ITEM_LIST = 'manthly/due_item'
export const MANTHLY_DUE_ITEM_DETAIL = 'manthly/due-item/{id}'


export const DUE_LIST = '/zero/due/'
export const DUE_DETAIL = 'zero/due/{id}'
export const DUE_DATE_LIST = 'zero/due_date/'
export const DUE_DATE_DETAIL = 'zero/due_date/{id}'
export const DUE_ITEM_LIST = 'zero/due_item/{dueDateId}'
export const DUE__ALL_ITEM_LIST = 'zero/due_item'
export const DUE_ITEM_DETAIL = 'zero/due-item/{id}'
export const TAX = 'zero/finance-tax'
