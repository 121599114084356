import React, { Fragment, useState } from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { Form, Formik } from 'formik'
import pick from 'lodash/pick'
import range from 'lodash/range'
import omit from 'lodash/omit'
import Button from './common/Button'
import Dropdown, { DropdownItem } from './common/Dropdown'
import Avatar from './Avatar'
import { useDeleteRequest, useLoad, usePostRequest, usePutRequest } from '../hooks/request'
import { BOARD_TASK_DETAIL, COMMENT_LIST, EMPLOYEE_SIMPLE_LIST, MERGE_REQUEST, TASK_DETAIL } from '../urls'
import TaskPoints from './TaskPoints'
import Input from './common/Input'
import { required } from '../utils/validators'
import { useMessage } from '../hooks/message'

export default function TaskDetails({
    task: parentsTask,
    board,
    boardTask,
    onUpdate,
    onDelete,
    onPointsUpdate,
    onCompleted,
    onCompletedDoing,
}) {
    const [showMessage] = useMessage()
    const [task, setTask] = useState(parentsTask) // Ugly Hack: to force rerender modal
    const [handleChange, setHandleChange] = useState(false)
    const [showMergeRequest, setShowMergeRequest] = useState(false)
    const employee = useLoad({ url: EMPLOYEE_SIMPLE_LIST, params: { project: task.project } })
    const taskUpdate = usePutRequest({ url: TASK_DETAIL.replace('{id}', task.id) })
    const deleteUrl = boardTask
        ? BOARD_TASK_DETAIL.replace('{board_id}', board.id).replace('{id}', boardTask.id)
        : TASK_DETAIL.replace('{id}', task.id)
    const taskDelete = useDeleteRequest({ url: deleteUrl })
    const createMergeRequest = usePostRequest({ url: MERGE_REQUEST.replace('{id}', task.id) })
    const comment = useLoad({ url: COMMENT_LIST, params: { task: task.id } })
    const commentItem = comment.response ? comment.response.results : []
    console.log(commentItem)
    const commentCreate = usePostRequest({ url: COMMENT_LIST })
    async function onSubmit(data, actions) {
        const { success } = await commentCreate.request({
            data: {
                ...data,
                task: task.id,
            },
        })
        if (success) {
            comment.request()
            actions.resetForm()
        }
    }

    async function handleDelete() {
        if (!global.confirm('Вы действительно хотите удалить задачу?')) return

        const { error } = await taskDelete.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        onDelete(boardTask ? boardTask.id : task.id)
    }

    async function update(data) {
        const { response } = await taskUpdate.request({ data })
        setTask(response)
        onUpdate(response)
        setHandleChange(false)
    }

    async function handleSubmit(data) {
        await update(pick(data, 'title', 'description'))
        setHandleChange(false)
    }

    async function handlePoints(points) {
        if (onPointsUpdate) onPointsUpdate(points - (task.points || 0))
        await update({ ...omit(task, 'assigned'), points })
    }

    async function handleAssign(assigned) {
        await update({ ...task, assigned })
    }

    async function mergeRequest() {
        if (task.branch) {
            setShowMergeRequest(!showMergeRequest)
            return
        }

        const { error } = await createMergeRequest.request()

        if (error && error.data) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        setShowMergeRequest(true)
    }

    return (
        <div className={cn('columns', css(styles.wrapper))}>
            <div className="column">
                {!(createMergeRequest.response || showMergeRequest) ? (
                    <>
                        <Formik initialValues={task} onSubmit={handleSubmit}>
                            {({ submitForm, dirty }) => (
                                <Form className="mb-5">
                                    {handleChange ? (
                                        <Input
                                            name="title"
                                            autoFocus
                                            placeholder="Нет заголовка"
                                            loading={taskUpdate.loading}
                                            large
                                            onBlur={dirty ? submitForm : undefined}
                                            validate={required}
                                            // readOnly={!cp('tasks.change_task')}
                                            className={cn('input', css(styles.input, styles.title))} />
                                    ) : (
                                        <p onClick={() => setHandleChange(!handleChange)}
                                            className={css(styles.taskTitle)}>
                                            {task.title}
                                        </p>
                                    )}

                                    {task.assigned || task.points ? (
                                        <div className="columns is-gapless mb-2">
                                            {task.assigned ? (
                                                <div className="column">
                                                    <p className="has-text-grey-light mb-2">Ответственный</p>
                                                    <div className="columns is-variable is-1">
                                                        <div className="column is-narrow">
                                                            <Avatar employee={task.assigned} />
                                                        </div>
                                                        <div className="column">
                                                            <p>{task.assigned.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {task.points ? (
                                                <div className="column">
                                                    <p className="has-text-grey-light mb-2">Сложность</p>
                                                    <TaskPoints points={task.points} showText />
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </Form>
                            )}
                        </Formik>


                        <div className="column">
                            <p className="mb-2 tag is is-medium is-success is-light">Комментария</p>
                            {commentItem.map((element) => (
                                <div className="columns is-variable is-1">
                                    <div className="column is-narrow">
                                        <Avatar employee={element.user} />
                                    </div>
                                    <div className="column">
                                        <p><span className="is-bold has-text-black">{element?.user.name}</span> -  {element.title?element.title:'No comment'}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Formik initialValues={{ title: '' }} onSubmit={onSubmit}>
                            <Form>
                                <div className="is-flex is-fullwidth mt-3">
                                    <Input
                                        style={{ width: 400 }}
                                        name="title"
                                        placeholder="Введите комментарий..."
                                        className={cn('input is-fullwidth mr-2')} />

                                    <Button
                                        loading={comment.loading}
                                        type="submit"
                                        icon="ion-md-paper-plane"
                                        className="is-success mb-2 ml-2" />
                                </div>
                            </Form>
                        </Formik>
                    </>

                ) : (
                    <div>
                        <p className={cn(css(styles.title), 'has-text-black mb-2')}>Merge Request</p>
                        <pre className="mb-2">$ git checkout {task.branch || createMergeRequest.response.branch}</pre>

                        <a href={task.mergeRequestUrl || createMergeRequest.response.mergeRequestUrl}>
                            Перейти в merge request
                        </a>
                    </div>
                )}
            </div>


            <div className="column is-4">
                {/* {cp('tasks.change_task_details') ? ( */}
                <Fragment>
                    <Dropdown fullwidth className="mb-2" trigger={(
                        <Button
                            text="Ответственный"
                            className="is-fullwidth is-success"
                            icon="ion-md-person-add" />
                    )} right>

                        {employee.loading ? <DropdownItem text="Загрузка" loading /> : null}
                        {employee.response ? employee.response.results.map((item) => (
                            <DropdownItem onClick={() => handleAssign(item.id)} text={item.name} key={item.id} />
                        )) : null}
                    </Dropdown>

                    <Dropdown fullwidth className="mb-2" trigger={(
                        <Button text="Сложность" className="is-fullwidth is-success" icon="ion-md-star" />
                    )} right>
                        {range(1, 6).map((i) => (
                            <DropdownItem key={i}
                                icon={task.points === i ? 'ion-md-checkmark has-text-success' : ''}
                                onClick={() => handlePoints(i)}
                                text={<TaskPoints showText points={i} />} />
                        ))}
                    </Dropdown>

                    {/* <Button */}
                    {/*    disabled */}
                    {/*    text="Дедлайн (скоро)" */}
                    {/*    icon="ion-md-clock" */}
                    {/*    className="is-fullwidth is-success mb-2" /> */}

                    {/* <Button */}
                    {/*    disabled */}
                    {/*    text="Метки (скоро)" */}
                    {/*    icon="ion-md-clock" */}
                    {/*    className="is-fullwidth is-success mb-2" /> */}

                    {/* <Button */}
                    {/*    disabled */}
                    {/*    text="Дедлайн (скоро)" */}
                    {/*    icon="ion-md-clock" */}
                    {/*    className="is-fullwidth is-success mb-2" /> */}


                    {/* <Button */}
                    {/*    onClick={mergeRequest} */}
                    {/*    loading={createMergeRequest.loading} */}
                    {/*    text="Медж рекуест" */}
                    {/*    icon="ion-md-git-pull-request" */}
                    {/*    className="is-fullwidth is-success mb-2" /> */}


                    <Button
                        onClick={onCompleted}
                        text="Завершить"
                        className="is-fullwidth is-link mb-2"
                        icon="ion-md-checkmark" />
                    <Button
                        onClick={onCompletedDoing}
                        text="Делает"
                        className="is-fullwidth is-warning mb-2 has-text-black"
                        icon="ion-md-clock" />
                </Fragment>

                {/* {cp('tasks.delete_task') ? ( */}
                <Button
                    onClick={handleDelete}
                    loading={taskDelete.loading}
                    text="Удалить задачу"
                    icon="ion-md-trash"
                    className="is-fullwidth is-danger mb-2" />
                {/* ) : null} */}
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        // minHeight: '60vh',
    },
    title: {
        fontSize: '1.3em',
    },
    input: {
        paddingLeft: '0.5rem',
        marginLeft: '-0.5rem',
        resize: 'none',
        ':not(:focus)': {
            border: 'none',
            boxShadow: 'none',
        },
    },
    taskTitle: {
        fontSize: '1.3em',
        cursor: 'pointer',
        paddingTop: 10,
        marginBottom: '0.75rem',
    },
})
