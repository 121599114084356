import React from 'react'
import { usePutRequest } from '../hooks/request'
import { MANTHLY_DUE_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import ManthlyDueForm from './ManthlyDueForm'

export default function ManthlyDueUpdate({ onCancel, item, onUpdate }) {
    const brandUpdate = usePutRequest({ url: MANTHLY_DUE_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await brandUpdate.request({ data })

        if (success) {
            showMessage('Успешно изменена', 'is-success')
            onUpdate()
            onCancel()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Изменить</b></h1>

            <ManthlyDueForm
                loading={brandUpdate.loading}
                initialValues={item}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
