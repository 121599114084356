import React from 'react'

const pointsClass = { 1: '', 2: 'is-warning', 3: 'is-link', 4: 'is-success', 5: 'is-danger' }
const pointsText = { 1: 'Пустяк', 2: 'Не сложно', 3: 'Средненькая', 4: 'Сложно', 5: 'Очень сложно' }

export default function TaskPoints({ points, showText, className }) {
    if (!points) {
        return null
    }

    return (
        <div className={`tag is-light ${pointsClass[points]} ${className}`}>
            {points}
            {showText ? <span> &nbsp; {pointsText[points]}</span> : null}
        </div>
    )
}
