import React from 'react';
import Button from './common/Button';
import { useLoad } from '../hooks/request';
import { USERS_DETAIL } from '../urls';
import { checkPermission as cp } from '../utils/auth';
import { financeType } from '../utils/position';

export default function FinanceInfo({ lead, onCancel }) {
	const userDetail = useLoad({ url: USERS_DETAIL.replace('{id}', lead ? lead.user : 0) });
	const item = userDetail.response ? userDetail.response : {};
	return (
		<div>
			<b>Информация о Клиент: {lead.name}</b>
			<br />
			<br />
			<table className='table is-striped is-fullwidth'>
				<tbody>
					<tr>
						<td>Корхона СТИР:</td>
						<td>{lead.stir || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>

					<tr>
						<td>Корхона рахбари:</td>

						<td>{lead.directorName || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>

					<tr>
						<td>Корхона рахбари СТИР:</td>

						<td>{lead.directorStir || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>

					<tr>
						<td>Банк:</td>
						<td>{lead.bank || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>

					<tr>
						<td>Х/р:</td>

						<td>{lead.accountNumber || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>

					<tr>
						<td>МФО:</td>
						<td>{lead.mfo || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>

					<tr>
						<td>Солик инспектори:</td>
						<td>{lead.inspector || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>

					<tr>
						<td>Корхона рахбари телефони:</td>
						<td>{lead.phoneNumber || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>
					<tr>
						<td>Интернет банк логин:</td>
						<td>{lead.login || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>
					<tr>
						<td>Солиқ тури:</td>
						<td>{financeType(lead.type) || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>
					<tr>
						<td>ҚҚСга ўтган ой:</td>
						<td>{lead.taxDate || <span className='has-text-grey'>Пусто</span>}</td>
					</tr>
					{cp('staff.delete_employee') ? (
						<tr>
							<td>Сумма контракта:</td>
							<td>{lead.contractSum || <span className='has-text-grey'>Пусто</span>}</td>
						</tr>
					) : null}
					{/* <tr>
                        <td>Корхонанинг ЭРИ калити:</td>
                        <td>
                            <a href={`${domain + lead.first}`}>
                                <span className="is-link is-light"><Button
                                    icon="icon ion-md-download"
                                    className="is-pulled-right is-outlined is-link is-light"
                                />
                                </span>
                            </a>
                        </td>

                    </tr>
                    <tr>
                        <td>Раҳбарнинг ЭРИ калити:</td>
                        <td><a href={`${domain + lead.second}`}>
                            <span className="is-link is-light"><Button
                                icon="icon ion-md-download"
                                className="is-pulled-right is-outlined is-link is-light"
                            />
                            </span>
                            </a>
                        </td>

                    </tr> */}
					{/* <tr>
						<td>Масул ходим:</td>
						<td>{item.nickname || <span className='has-text-grey'>Пусто</span>}</td>
					</tr> */}
				</tbody>
			</table>
			<Button onClick={onCancel} icon='ion-md-close' text='Закрыть' className='is-danger' /> &nbsp;
		</div>
	);
}
