import React from 'react';
import Layout from '../components/Layout';
import { useLoad } from '../hooks/request';
import { EMPLOYEE_LIST } from '../urls';
import { useQueryParams } from '../hooks/queryString';
import { useModal } from '../hooks/modal';
import EmployeeCreate from '../components/EmployeeCreate';
import Button from '../components/common/Button';
import Table from '../components/common/Table';
import EmployeeItem from '../components/EmployeeItem';
import { checkPermission as cp } from '../utils/auth';
import { PermissionDenied } from '../components/PermissionDenied';

export default function Employee() {
	const params = useQueryParams();
	const employees = useLoad({ url: EMPLOYEE_LIST, params }, [params.page]);

	const [showCreateModal, hideCreateModal] = useModal(
		<EmployeeCreate
			onSuccess={() => {
				hideCreateModal();
				employees.request();
			}}
			onCancel={() => hideCreateModal()}
		/>
	);

	if (!cp('staff.delete_employee')) {
		return <PermissionDenied />;
	}

	return (
		<Layout>
			<span className='is-size-4'>Сотрудники</span>

			{cp('staff.add_employee') ? <Button onClick={showCreateModal} text='Добавить' icon='ion-md-add' className='is-pulled-right is-link is-outlined mb-2' /> : null}

			<br />

			<Table
				loading={employees.loading}
				totalCount={employees.response ? employees.response.count : 0}
				items={employees.response ? employees.response.results : []}
				columns={{
					name: 'Имя',
					birthday: 'День рождения',
					salary: 'Зарплата',
					position: 'Должность',
					actions: '',
				}}
				renderItem={item => <EmployeeItem key={item.id} onUpdate={employees.request} onDelete={employees.request} item={item} />}
			/>
		</Layout>
	);
}
