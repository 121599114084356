import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp } from '../utils/auth'

export default function BoardTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                <li className={cn({ 'is-active': path.startsWith('/task/table-board') })}>
                    <NavLink to="/task/table-board/1">Таблиса задач</NavLink>
                </li>

                <li className={cn({ 'is-active': path.startsWith('/task/board') })}>
                    <NavLink to="/task/board/1">Доска задач</NavLink>
                </li>
            </ul>
        </div>
    )
}
