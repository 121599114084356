import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Layout from "../../components/Layout";
import { useGetRequest, useLoad } from "../../hooks/request";
import {
  MANTHLY_BALANCE_ALL_ITEM_LIST,
  MANTHLY_DUE_LIST,
  MANTHLY_FINANCE_DETAIL,
  MANTHLY_INCOME_QUARTERLY_ALL_ITEM_LIST,
  MANTHLY_INCOME_TAX_ALL_ITEM_LIST,
  MANTHLY_QQC_MONTHLY_ALL_ITEM_LIST,
  MANTHLY_RESULT_ALL_ITEM_LIST,
  MANTHLY_ROTATION_ALL_ITEM_LIST,
} from "../../urls";
import Table from "../../components/common/Table";
import FinanceMonthlyItem from "../../components/FinanceMonthlyItem";

export default function MonthlyClientDetail() {
  const { pathname } = useLocation();

  const clientId = pathname.split("/")[3];
  const finance = useLoad({ url: MANTHLY_FINANCE_DETAIL.replace("{id}", clientId) });
  const data = finance?.response;

  const due = useLoad({ url: MANTHLY_DUE_LIST });
  const dueItem = due.response ? due.response.results : [];
  const [url, setUrl] = useState(MANTHLY_ROTATION_ALL_ITEM_LIST);
  const [selectedTag, setSelectedTag] = useState("ЖШДС");

  const item = useGetRequest({ url, params: { finance: clientId } });
  console.log(item.response ? item.response : []);

  const handleTagClick = (newUrl, tagName) => {
    setUrl(newUrl);
    setSelectedTag(tagName);
    item.request();
  };

  return (
    <Layout>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
        <Link className="button is-white is-link is-outlined" to="/clients">
          <i className="icon ion-md-arrow-back is-size-4" />
        </Link>
        <h1 style={{ fontSize: "24px", fontWeight: "bold", marginLeft: "16px", color: "#111827" }}>{data?.name}</h1>
      </div>

      <div style={{ backgroundColor: "#fff", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "24px", margin: "0 auto" }}>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "24px" }}>
          <div>
            <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "12px", color: "#374151" }}>Компания ҳақида маълумот</h2>
            <p>
              <strong>Корхона номи:</strong> {data?.name}
            </p>
            <p>
              <strong>Корхона СТИР:</strong> {data?.stir}
            </p>
            <p>
              <strong>Банк:</strong> {data?.bank}
            </p>
            <p>
              <strong>Сумма контракта:</strong> {data?.contractSum}
            </p>
          </div>

          <div>
            <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "12px", color: "#374151" }}>Директор ҳақида маълумот</h2>
            <p>
              <strong>Корхона рахбари:</strong> {data?.directorName}
            </p>
            <p>
              <strong>Корхона рахбари СТИР:</strong> {data?.directorStir}
            </p>
           {/* <p>
              <strong>Солик инспектори:</strong> {data?.inspector}
            </p>
            <p>
              <strong>Хизмат кўрсатувчи банк оператори телефон:</strong> {data?.phoneNumber}
            </p>*/}
          </div>

          <div>
            <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "12px", color: "#374151" }}>Молиявий тафсилотлар</h2>
            <p>
              <strong>Х/р:</strong> {data?.accountNumber}
            </p>
            <p>
              <strong>МФО:</strong> {data?.mfo}
            </p>
            <p>
              <strong>ҚҚСга ўтган ой:</strong> {data?.tax_date}
            </p>
            <p>
              <strong>Охирги юбориш санаси :</strong> {new Date(data?.lastSendDate).toLocaleString()}
            </p>
          </div>

          <div>
            <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "12px", color: "#374151" }}>Ҳужжатлар</h2>
            {data?.first && (
              <a href={data?.first} style={{ color: "#1d4ed8", textDecoration: "underline", display: "block", marginBottom: "8px" }} target="_blank" rel="noopener noreferrer">
                Бизнес сертификат
              </a>
            )}
            {data?.second && (
              <a href={data?.second} style={{ color: "#1d4ed8", textDecoration: "underline", display: "block", marginBottom: "8px" }} target="_blank" rel="noopener noreferrer">
                Раҳбар сертификати
              </a>
            )}
            <p>
              <strong>Интернет банк логин:</strong> {data?.login || "Not Available"}
            </p>
          </div>
        </div>
      </div>

      <div className="is-flex mt-4">
        <span
          style={{ backgroundColor: selectedTag === "Айланма" ? "#007bff" : "#f0f0f0", color: selectedTag === "Айланма" ? "white" : "black" }}
          className={`tag is-medium is-light mr-2 ${selectedTag === "Айланма" ? "is-active" : ""}`}
          onClick={() => handleTagClick(MANTHLY_ROTATION_ALL_ITEM_LIST, "Айланма")}
        >
          Айланма
        </span>
        <span
          style={{ backgroundColor: selectedTag === "ЖШДС" ? "#007bff" : "#f0f0f0", color: selectedTag === "ЖШДС" ? "white" : "black" }}
          className={`tag is-medium is-light mr-2 ${selectedTag === "ЖШДС" ? "is-active" : ""}`}
          onClick={() => handleTagClick(MANTHLY_INCOME_TAX_ALL_ITEM_LIST, "ЖШДС")}
        >
          ЖШДС
        </span>
        <span
          style={{ backgroundColor: selectedTag === "ҚҚС Ойлик" ? "#007bff" : "#f0f0f0", color: selectedTag === "ҚҚС Ойлик" ? "white" : "black" }}
          className={`tag is-medium is-light mr-2 is-active ${selectedTag === "ҚҚС Ойлик" ? "is-active" : ""}`}
          onClick={() => handleTagClick(MANTHLY_QQC_MONTHLY_ALL_ITEM_LIST, "ҚҚС Ойлик")}
        >
          ҚҚС Ойлик
        </span>
        <span
          style={{
            backgroundColor: selectedTag === "Фойда солиғи" ? "#007bff" : "#f0f0f0",
            color: selectedTag === "Фойда солиғи" ? "white" : "black",
          }}
          className={`tag is-medium is-light mr-2 ${selectedTag === "Фойда солиғи" ? "is-active" : ""}`}
          onClick={() => handleTagClick(MANTHLY_INCOME_QUARTERLY_ALL_ITEM_LIST, "Фойда солиғи")}
        >
          Фойда солиғи
        </span>
        <span
          style={{ backgroundColor: selectedTag === "Молиявий Натижа" ? "#007bff" : "#f0f0f0", color: selectedTag === "Молиявий Натижа" ? "white" : "black" }}
          className={`tag is-medium is-light mr-2 ${selectedTag === "Молиявий Натижа" ? "is-active" : ""}`}
          onClick={() => handleTagClick(MANTHLY_RESULT_ALL_ITEM_LIST, "Молиявий Натижа")}
        >
          Молиявий Натижа
        </span>
        <span
          style={{ backgroundColor: selectedTag === "Молиявий Баланс" ? "#007bff" : "#f0f0f0", color: selectedTag === "Молиявий Баланс" ? "white" : "black" }}
          className={`tag is-medium is-light mr-2 ${selectedTag === "Молиявий Баланс" ? "is-active" : ""}`}
          onClick={() => handleTagClick(MANTHLY_BALANCE_ALL_ITEM_LIST, "Молиявий Баланс")}
        >
          Молиявий Баланс
        </span>

        {dueItem.map((item) => (
          <div>
            <span className="tag is-medium is-light mr-2">{item.name}</span>
          </div>
        ))}
      </div>

      <div style={{ overflow: "scroll", marginTop: 20 }}>
        <div style={{ overflow: "scroll" }}>
          <Table
            loading={item.loading}
            items={item.response ? item.response.results : []}
            columns={{ id: "№", selectIncomeTax: "Сана", directorStir: "Юкланган файл", directorName: "Коментария", inps: "ИНПС", tax: "Солиқ     " }}
            renderItem={(item, index) => <FinanceMonthlyItem selectedTag={selectedTag} page={1} key={item.id} index={index} onUpdate={finance.request} onDelete={finance.request} item={item} />}
          />
        </div>
      </div>
    </Layout>
  );
}
