import React from "react";
import { getMonth } from "../../utils/date";
import { domain } from "../../utils/request";

export default function RotationItemTableColumn({ item, index, page }) {
  const itemsPerPage = 10;
  const position = (page - 1) * itemsPerPage + index + 1;

  return (
    <tr>
      <td className="has-text-center pr-3 pl-3">{position}</td>
      <td className="has-text-center pr-3 pl-3">{item.rotationComment}</td>
      <td className="has-text-center pr-3 pl-3">
        <a
          href={domain + item?.rotationPdf}
          style={{
            color: "#1d4ed8",
            textDecoration: "underline",
            display: "block",
            marginBottom: "8px",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item?.rotationPdf?.split("/")[4]?.split(".")[0]}
        </a>
      </td>
      <td className="has-text-center pr-3 pl-3">{getMonth(item?.selectRotation?.date)}</td>
    </tr>
  );
}
