import React from 'react'
import { Form, Formik } from 'formik'

import Layout from '../components/Layout'
import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import ManthlyTabs from '../components/ManthlyTabs'
import { useQueryParams } from '../hooks/queryString'
import { MANTHLY_QQC_QUARTERLY_LIST, USERS_LIST } from '../urls'
import ManthlyQqcQuarterlyItem from '../components/ManthlyQqcQuarterlyItem'
import ManthlyQqcQuarterlyCreate from '../components/ManthlyQqcQuarterlyCreate'

import UserSelect from '../components/componentsFinance/common/UserSelect'

export default function ManthlyQqcQuarterly() {
    const params = useQueryParams()
    const qqcMonthly = useLoad({ url: MANTHLY_QQC_QUARTERLY_LIST, params }, [params])
    const reportItem = qqcMonthly.response ? qqcMonthly.response.results : []
    const [showModal, hideModal] = useModal(
        <ManthlyQqcQuarterlyCreate
            onSuccess={() => {
                hideModal()
                qqcMonthly.request()
            }}
            onCancel={() => hideModal()}
        />,
    )
    const users = useLoad({ url: USERS_LIST })
    const userItem = users.response ? users.response.results : []
    const userOptions = Object.entries(userItem).map(([, { id, nickname }]) => ({ value: nickname, name: id }))

    return (
        <Layout>
            <div style={{ position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 10, backgroundColor: 'white' }}>
                <ManthlyTabs />
                <div className="columns mt-3 mr-3">
                    <div className="column is-capitalized ml-4">
                        <span className="is-size-4">ҚҚС Кварталлик:</span>
                    </div>
                    <div className="column mt-3">
                        <div className="columns is-pulled-right">
                            <Formik initialValues={{ user: '' }}>
                                <Form className="mr-3">
                                    <UserSelect emptyMessage="Ходимлар" empty options={userOptions} name="user" />
                                </Form>
                            </Formik>
                            <Button icon="ion-md-calendar" text="Янги ой қўшиш" onClick={showModal} className="button is-success is-outlined" />
                        </div>
                    </div>
                </div>
            </div>
            <Table
                loading={qqcMonthly.loading}
                items={reportItem}
                columns={{ id: '№', name: 'Ой номи', stir: 'Мижозлар', directorStir: 'Ҳисоботлар Жунатилиши', ssuploadRotation: 'Солиқлар тўланиши', xdirectorStir: 'ИНПС реестр жунатилиши', ad: '', uploadRotation: '', assd: '' }}
                renderItem={(item, index) => (
                    <ManthlyQqcQuarterlyItem
                        reportItem={item}
                        index={index}
                        key={item.id}
                        onUpdate={qqcMonthly.request}
                        onDelete={qqcMonthly.request}
                        request={qqcMonthly.request}
                        reportItems={reportItem.response ? reportItem.response.results : []}
                        finance={item}
                    />
                )}
            />
        </Layout>
    )
}
