import reject from 'lodash/reject'
import React from 'react'
import { Form, Formik } from 'formik'
import { usePostRequest, useLoad } from '../hooks/request'
import { TRANSACTION_TRANSFER, WALLET_LIST } from '../urls'
import { required, validator, maxNumber } from '../utils/validators'

import Input from './common/Input'
import Button from './common/Button'
import Select from './common/Select'

export default function TransactionTransfer({ onCancel, onSuccess, wallet }) {
    const initialValues = { amount: '', wallet: '', walletId: wallet.id }
    const transactionTransfer = usePostRequest({ url: TRANSACTION_TRANSFER.replace('{id}', wallet.id) })
    const wallets = useLoad({ url: WALLET_LIST })
    const walletsList = wallets.response ? reject(wallets.response.results, { id: wallet.id }) : []

    async function onSubmit(data) {
        await transactionTransfer.request({ data })
        onSuccess()
    }
    return (
        <div>
            <b>Перевести средства</b><br /><br />
            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                <Form>
                    <Input
                        name="amount"
                        type="number"
                        label="Сумма"
                        placeholder="Сумма"
                        validate={validator(required, maxNumber(wallet.balance))} />

                    <Select
                        empty
                        name="wallet"
                        label="Счёт"
                        help="Вы можете добавить новый счёт в настройках"
                        options={walletsList}
                        loading={wallets.loading}
                        validate={required} />

                    <Button
                        loading={transactionTransfer.loading}
                        text="Перевести"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            </Formik>
        </div>
    )
}
