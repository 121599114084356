import React, { useState } from "react";

import { getMonth } from "../../utils/date";

export default function BalanceItemTableColumn({ item, index, page }) {
  const itemsPerPage = 10;
  const position = (page - 1) * itemsPerPage + index + 1;

  const [date, setDate] = useState(item?.selectBalance?.date);
  const [comment, setComment] = useState(item?.balanceComment);
  // const [inps, setInps] = useState(item?.inps);
  // const [tax, setTax] = useState(item?.tax);
  const [pdf, setPdf] = useState(item?.balancePdf);

  // function handleInpsStatusChange(event) {
  //   const newTaxStatus = event.target.checked;
  //   setInps(newTaxStatus);
  // }
  //
  // function handleTaxStatusChange(event) {
  //   const newTaxStatus = event.target.checked;
  //   setTax(newTaxStatus);
  // }

  return (
    <tr>
      <td className="has-text-center pr-3 pl-3">{position}</td>
      <td className="has-text-center pr-3 pl-3">{getMonth(date)}</td>
      <td className="has-text-center pr-3 pl-3">
        <a
          href={pdf}
          style={{
            color: "#1d4ed8",
            textDecoration: "underline",
            display: "block",
            marginBottom: "8px",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {pdf?.split("/")[4]?.split(".")[0]}
        </a>
      </td>
      <td className="has-text-center pr-3 pl-3">{comment}</td>
      {/* <td className="has-text-centered"> */}
      {/*   <input style={{ width: 22, height: 22 }} type="checkbox" checked={inps} onChange={handleInpsStatusChange} /> */}
      {/* </td> */}
      <td className="has-text-centered">
        <input
          style={{
            width: 22,
            height: 22,
          }}
          type="checkbox"
          checked={item.tax}
        />
      </td>
    </tr>
  );
}

//  {
//     "count": 1,
//     "results": [
//         {
//             "id": 1,
//             "result_pdf": "/files/uploads/ManthlyIncomeTaxItem/2023_moliyaviy_natijalar_ABDULAZIZ_ULUGBEK_TRANS_nUjt2X1.pdf",
//             "result_comment": "2023 moliyaviy natijalar",
//             "result": 3,
//             "finance": 51,
//             "tax": false,
//             "inps": false,
//             "select_result": {
//                 "id": 3,
//                 "date": "2023-12-01"
//             }
//         }
//     ]
// }
