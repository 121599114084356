import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'

export default function ManthlyDueForm({ onCancel, onSubmit, initialValues, loading }) {
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ name: '', ...initialValues }}>
            <Form>
                <Input
                    name="name"
                    label="Имя"
                    validate={required} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="close-outline"
                        text="Закрыть"
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text="Сохранить"
                        icon="add-outline"
                        type="submit"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
