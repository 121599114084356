import React from 'react'
import Layout from '../components/Layout'
import SettingsTabs from '../components/SettingsTabs'
import Card from '../components/common/Card'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'
import SettingsSalesChannels from '../components/SettingsSalesChannels'

export default function SettingsMarketing() {
    if (!cp('marketing.view_saleschannel')) {
        return <PermissionDenied />
    }

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="column">
                <Card>
                    <SettingsSalesChannels />
                </Card>
            </div>
        </Layout>
    )
}
