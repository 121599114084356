import React from 'react'
import { usePostRequest } from '../hooks/request'
import { MANTHLY_QQC_QUARTERLY_ITEM_LIST } from '../urls'
import ManthlyQqcQuarterlyItemForm from './ManthlyQqcQuarterlyItemForm'

export default function ManthlyQqcQuarterlyItemCreate({ onCancel, onSuccess, finance, qqcQuarterlyId }) {
    const monthCreate = usePostRequest({ url: MANTHLY_QQC_QUARTERLY_ITEM_LIST.replace('{qqcQuarterlyId}', finance.id) })

    async function onSubmit(data) {
        const { success } = await monthCreate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <ManthlyQqcQuarterlyItemForm
                finance={finance}
                qqcQuarterlyId={qqcQuarterlyId}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={monthCreate.loading} />
        </div>
    )
}
