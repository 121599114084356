import React from 'react'
import map from 'lodash/map'
import { getRole } from '../utils/memberRoles'
import { useDeleteRequest } from '../hooks/request'
import { MEMBERS_DETAIL } from '../urls'
import { checkPermission as cp } from '../utils/auth'
import Loader from './common/Loader'
import { useModal } from '../hooks/modal'
import ProjectMemberUpdate from './ProjectMemberUpdate'

export default function ProjectMemberItem({ member, reload, onUpdate, employees, project, onBack }) {
    const memberDelete = useDeleteRequest({ url: MEMBERS_DETAIL.replace('{id}', member.id) })
    const roles = map(member.roles, (role) => getRole(role).label)

    const [showUpdateModal, hideUpdateModal] = useModal(
        <ProjectMemberUpdate
            member={member}
            onSuccess={() => {
                hideUpdateModal()
                onUpdate()
            }}
            onCancel={() => hideUpdateModal()}
            employees={employees} project={project}
            onBack={() => {
                hideUpdateModal()
                onBack()
            }} />,
    )

    async function deleteMember() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await memberDelete.request()
            reload()
        }
    }

    return (
        <tr>
            <td>{member.employee.name}</td>
            <td>{roles.join(', ')}</td>

            <td>
                {/* eslint-disable-next-line no-nested-ternary */}
                {cp('project.delete_member') ? (
                    !memberDelete.loading ? (
                        <i onClick={() => deleteMember()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('project.change_member') ? (
                    <i onClick={() => showUpdateModal()} className="icon pointer ion-md-create" />
                ) : null}
            </td>
        </tr>
    )
}
