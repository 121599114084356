import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import Input from './componentsFinance/common/Input'
import Button from './componentsFinance/common/Button'
import Checkbox from './common/Checkbox'
import { domain } from '../utils/request'

export default function ManthlyDueItemForm({ onCancel, loading, initialValues, dueDate, finance, onSubmit }) {
    const [file, setFile] = useState(null)

    async function onSuccess(data) {
        const newData = new FormData()

        if (file) {
            newData.append('duePdf', file, file.name)
        }
        newData.append('finance', finance.id)
        newData.append('dueDate', dueDate)
        newData.append('dueComment', data.dueComment)
        newData.append('inps', data.inps)
        newData.append('tax', data.tax)

        onSubmit(newData)
    }

    return (
        <Formik onSubmit={onSuccess}
            initialValues={{
                dueComment: '',
                tax: false,
                inps: false,
                ...initialValues,
                duePdf: initialValues && !isEmpty(initialValues.duePdf) ? initialValues.duePdf : '',

            }}>
            {({ setFieldValue }) => (

                <Form>
                    <div className="columns">
                        <div className="column">
                            <Input
                                name="dueComment"
                                label="Комментарий"
                                disabled={loading}
                                placeholder="Комментарий" />
                        </div>

                    </div>
                    <Checkbox
                        name="tax"
                        label="Солиқлар тўланиши"
                        disabled={loading}
                        placeholder="Комментарий" />

                    {/* <Checkbox
                        name="inps"
                        label="ИНПС реестр"
                        disabled={loading}
                        placeholder="Комментарий" /> */}


                    <div className="columns">
                        <div className="column">
                            <p className="level-left">
                                Файл
                            </p>
                            <input
                                onChange={(e) => {
                                    setFieldValue('duePdf', e.target.value)
                                    setFile(e.target.files[0])
                                }}
                                disabled={loading}
                                name="duePdf"
                                type="file"
                            />
                            {initialValues ? (domain + initialValues.duePdf).split('/').pop() : 'Файл юкланмаган'}
                        </div>

                    </div>

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}

        </Formik>
    )
}
