import React from 'react'
import { usePostRequest } from '../hooks/request'
import CustomerForm from './CustomerForm'
import { CUSTOMER_LIST } from '../urls'

export default function CustomerCreate({ onCancel, onSuccess }) {
    const customerCreate = usePostRequest({ url: CUSTOMER_LIST })

    async function onSubmit(data, actions) {
        await customerCreate.request({ data })

        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Добавить новый Клиент</b><br /><br />

            <CustomerForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={customerCreate.loading} />
        </div>
    )
}
