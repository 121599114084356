import React from 'react'
import { QQC_MONTHLY_ITEM_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ServerError from './componentsFinance/common/ServerError'
import QqcMonthlyItemForm from './QqcMonthlyItemForm'

export default function QqcMonthlyItemUpdate({ onCancel, onSuccess, reportItem, finance, qqcMonthlyId }) {
    const url = QQC_MONTHLY_ITEM_DETAIL.replace('{id}', reportItem.id)
    const qqcMonthlyItemUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        const { success } = await qqcMonthlyItemUpdate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Изменить данные</b><br /><br />
            <ServerError error={qqcMonthlyItemUpdate.error} />
            <QqcMonthlyItemForm
                initialValues={reportItem}
                onSubmit={onSubmit}
                onCancel={onCancel}
                qqcMonthlyId={qqcMonthlyId}
                finance={finance}
                loading={qqcMonthlyItemUpdate.loading} />
        </div>
    )
}
