import { Form, Formik } from 'formik'
import React, { Fragment, useState } from 'react'
import { min, required, validator } from '../utils/validators'
import Input from './common/Input'
import Button from './common/Button'
import Select from './common/Select'
import { useLoad } from '../hooks/request'
import { EMPLOYEE_LIST, WALLET_LIST } from '../urls'
import { transactionTypeOptions } from '../utils/finance'

export default function AssetForm({ onSubmit, onCancel, loading, initialValues }) {
    const [showTransaction, setShowTransaction] = useState(false)
    const wallets = useLoad({ url: WALLET_LIST })
    const employee = useLoad({ url: EMPLOYEE_LIST })

    return (
        <Formik initialValues={{
            name: '',
            price: '',
            usageStart: '',
            usageEnd: '',
            responsible: '',
            createTransaction: true,
            type: '',
            wallet: '',
            ...initialValues,
        }} onSubmit={onSubmit}>
            <Form>
                <Input
                    label="Имя"
                    name="name"
                    disabled={loading}
                    validate={required}
                    placeholder="Введите имя актива" />

                <Input
                    name="price"
                    label="Цена"
                    type="number"
                    disabled={loading}
                    validate={validator(required, min(0))}
                    placeholder="Введите цену" />

                <Input
                    name="usageStart"
                    type="date"
                    label="Использунтся с"
                    disabled={loading}
                    validate={required} />

                <Input
                    name="usageEnd"
                    type="date"
                    label="Годен до"
                    disabled={loading}
                    validate={required} />

                <Select
                    empty
                    name="responsible"
                    label="Сотрудник"
                    loading={employee.loading}
                    options={employee.response ? employee.response.results : []}
                    help="Вы можете добавить нового сотрудника в меню Персонал"
                    validate={required} />

                <p className="has-text-link pointer mb-2" onClick={() => setShowTransaction(!showTransaction)}>
                    Создать транзакцию
                </p>

                {showTransaction ? (
                    <Fragment>
                        <Select
                            name="type"
                            options={transactionTypeOptions}
                            label="Форма оплаты"
                            empty
                            validate={required} />

                        <Select
                            empty
                            name="wallet"
                            label="Счёт"
                            help="Вы можете добавить новый счёт в настройках"
                            options={wallets.response ? wallets.response.results : []}
                            loading={wallets.loading}
                            validate={required} />
                    </Fragment>
                ) : null}

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
