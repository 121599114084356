import React from 'react'
import { usePostRequest } from '../hooks/request'
import { WALLET_LIST } from '../urls'
import WalletForm from './WalletForm'

export default function WalletCreate({ onCancel, onSuccess }) {
    const walletsCreate = usePostRequest({ url: WALLET_LIST })

    async function onSubmit(data, actions) {
        await walletsCreate.request({ data })
        actions.resetForm()
        onSuccess()
    }

    return (
        <div>
            <b>Создавать счёт</b><br /><br />

            <WalletForm
                loading={walletsCreate.loading}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
