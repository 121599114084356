import React from 'react'

import TaskForm from './TaskForm'
import { TASK_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'

export default function TaskUpdate({ onCancel, onUpdate, task }) {
    const url = TASK_DETAIL.replace('{id}', task.id)
    const taskUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        await taskUpdate.request({
            data: {
                ...data,
                dueTime: data.dueTime ? data.dueTime : null,
            },
        })
        actions.resetForm()
        onUpdate()
    }

    return (
        <div>
            <b>Редактировать задачу</b><br /><br />

            <TaskForm
                initialValues={task}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={taskUpdate.loading} />
        </div>
    )
}
