import React from 'react'
import map from 'lodash/map'
import { getMonth } from '../utils/date'
import Chart from './common/Chart'

export default function StatisticsFinanceAccountantLine({ data, loading }) {
    return (
        <div>
            <Chart
                loading={loading}
                type="bar"
                height="25rem"
                data={{
                    labels: data ? data.map((item) => getMonth(item.month)) : [],
                    datasets: [
                        {
                            data: data ? map(data, 'income') : [],
                            backgroundColor: '#48c774',
                            fill: false,
                            borderColor: '#48c774',
                            label: 'Приход',
                        },
                        {
                            data: data ? map(data, 'outcome') : [],
                            backgroundColor: '#f14668',
                            fill: false,
                            borderColor: '#f14668',
                            label: 'Расход',
                        },
                        {
                            data: data ? map(data, 'profit') : [],
                            backgroundColor: '#ffdd57',
                            fill: false,
                            borderColor: '#ffdd57',
                            label: 'Прибыль',
                        },
                    ],
                }}
                options={{
                    legend: { position: 'bottom' },
                    animation: {
                        animateScale: true,
                        animateRotate: true,
                    },
                    plugins: {
                        datalabels: {
                            color: 'black',
                            font: { weight: 'bold' },
                        },
                    },
                }} />
        </div>
    )
}
