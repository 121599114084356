import React, { Fragment } from 'react'
import cn from 'classnames'
import { checkPermission as cp } from '../utils/auth'
import { useModal } from '../hooks/modal'
import Button from './common/Button'
import CustomerInvite from './CustomerInvite'
import CustomerActions from './CustomerActions'

export default function CustomerItem({ customer, onDelete, onUpdate }) {
    const [showCustomerInviteModal, hideCustomerInviteModal] = useModal(
        <CustomerInvite
            customer={customer}
            onCancel={() => hideCustomerInviteModal()}
            onSuccess={() => {
                hideCustomerInviteModal()
                onUpdate()
            }} />,
    )

    return (
        <tr>
            <td>{customer.name}</td>

            <td>
                {customer.user ? customer.user.email : (
                    <Fragment>
                        <i className="has-text-grey">
                            {customer.invited ? 'Был приглашен' : 'не привязан'}
                        </i> &nbsp;

                        {cp('staff.add_invitation') ? (
                            <Button
                                text={customer.invited ? 'Повторно пригласить' : 'Пригласить'}
                                icon="ion-md-person-add"
                                onClick={showCustomerInviteModal}
                                className={cn('is-small', customer.invited ? 'is-warning' : 'is-link')} />
                        ) : null}
                    </Fragment>
                )}
            </td>

            <td>
                <CustomerActions onDelete={onDelete} onUpdate={onUpdate} customer={customer} />
            </td>
        </tr>
    )
}
