import React, { useEffect } from 'react'
import { useLoad } from '../hooks/request'
import { DEVELOPERS_LEADER_BOARD } from '../urls'
import Table from '../components/common/Table'
import { getMonth } from '../utils/date'

const PLAN = 50
const WINNERS = 2
const REFRESH_INTERVAL = 1000 * 60 * 30 // 30 min

export default function DevelopersLeaderBoard() {
    const employees = useLoad({ url: DEVELOPERS_LEADER_BOARD })

    useEffect(() => {
        const interval = setInterval(employees.request, REFRESH_INTERVAL)
        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="container mt-3">
            <span className="is-size-3">
                Рейтинг программистов за "{getMonth()}"
            </span>

            <span className="is-pulled-right is-size-3">
                План на одного программиста: <span className="has-text-success">{PLAN}</span>
            </span>

            <Table
                className="mt-3"
                totalCount={employees.response ? employees.response.count : 0}
                items={employees.response ? employees.response.results : []}
                columns={{ position: '#', name: 'Имя', rating: 'Рейтинг' }}
                renderItem={(item, index) => (
                    <tr key={item.id} className={
                        index < WINNERS && item.rating !== 0 ? 'has-background-success-light' : null
                    }>
                        <td className="is-narrow">{index + 1}</td>
                        <td>{item.name}</td>
                        <td className={item.rating < PLAN ? 'has-text-danger' : 'has-text-success'}>
                            <b>{item.rating}</b>
                        </td>
                    </tr>
                )} />
        </div>
    )
}
