import React from 'react'
import { SALES_CHANNEL_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import SalesChannelForm from './SalesChannelForm'

export default function SalesChannelUpdate({ onCancel, onUpdate, salesChannel }) {
    const url = SALES_CHANNEL_DETAIL.replace('{id}', salesChannel.id)
    const salesChannelUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        await salesChannelUpdate.request({ data })
        actions.resetForm()
        onUpdate()
    }

    return (
        <div>
            <b>Изменить канал</b><br /><br />

            <SalesChannelForm
                initialValues={salesChannel}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={salesChannelUpdate.loading} />
        </div>
    )
}
