import React from "react";

import { useModal } from "../hooks/modal";
import Layout from "../components/Layout";
import { useLoad } from "../hooks/request";
import Table from "../components/common/Table";
import Button from "../components/common/Button";
import ManthlyTabs from "../components/ManthlyTabs";
import { MANTHLY_INCOME_QUARTERLY_LIST } from "../urls";
import ManthlyIncomeQuarterlyItem from "../components/ManthlyIncomeQuarterlyItem";
import ManthlyIncomeQuarterlyCreate from "../components/ManthlyIncomeQuarterlyCreate";

export default function ManthlyIncomeQuarterly() {
  const report = useLoad({ url: MANTHLY_INCOME_QUARTERLY_LIST });
  const reportItem = report.response ? report.response.results : [];
  const [showModal, hideModal] = useModal(
    <ManthlyIncomeQuarterlyCreate
      onSuccess={() => {
        hideModal();
        report.request();
      }}
      onCancel={() => hideModal()}
    />,
  );

  return (
    <Layout>
      <div style={{ position: "sticky", top: 0, left: 0, width: "100%", zIndex: 10, backgroundColor: "white" }}>
        <ManthlyTabs />
        <div className="columns mb-4 mr-3">
          <div className="column is-capitalized ml-4">
            <span className="is-size-4">Фойда солиғи:</span>
          </div>
          <div className="column mt-3">
            <div className="columns is-pulled-right">
              <Button icon="ion-md-calendar" text="Янги квартал қўшиш" onClick={showModal} className="button is-success is-outlined" />
            </div>
          </div>
        </div>
      </div>
      <Table
        loading={report.loading}
        items={reportItem}
        columns={{ id: "№", name: "Квартал номи", stir: "Мижозлар", directorStir: "Ҳисоботлар Жунатилиши", ssuploadRotation: "Солиқлар тўланиши", ad: "", uploadRotation: "", assd: "" }}
        renderItem={(item, index) => (
          <ManthlyIncomeQuarterlyItem reportItem={item} index={index} key={item.id} onUpdate={report.request} onDelete={report.request} request={report.request} reportItems={reportItem.response ? reportItem.response.results : []} finance={item} />
        )}
      />
    </Layout>
  );
}
