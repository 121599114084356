import React from 'react'
import { NavLink } from 'react-router-dom'
import Progress from './common/Progress'


export default function TaskProjectBoardsItem({ project, item }) {
    const value = (100 / item.total) * item.complated
    const textValue = value ? `${parseInt(value)}%` : ''

    return (
        <tr>
            <td>
                <NavLink to={`/task/projects/${project.id}/board/${item.id}`}>{item.name}</NavLink>

                <div className="is-pulled-right">
                    <span className="has-text-primary">{item.complated}</span> /
                    <span className="has-text-grey-light">{item.total}</span>
                </div>
            </td>

            <td>
                <Progress value={item.complated} text={textValue} max={item.total} />
            </td>

            <td>
                {item.isActive ? 'активный' : ' завершенный'}
            </td>
        </tr>
    )
}
