import React from 'react';

import { CUSTOMER_LIST } from '../urls';
import Layout from '../components/Layout';
import { useModal } from '../hooks/modal';
import { useLoad } from '../hooks/request';
import Table from '../components/common/Table';
import Button from '../components/common/Button';
import ProjectTabs from '../components/ProjectTabs';
import CustomerItem from '../components/CustomerItem';
import { checkPermission as cp } from '../utils/auth';
import CustomerCreate from '../components/CustomerCreate';
import { PermissionDenied } from '../components/PermissionDenied';

export default function Projects() {
	const customers = useLoad({ url: CUSTOMER_LIST });

	const [showCreateCustomer, hideCreateCustomer] = useModal(
		<CustomerCreate
			onSuccess={() => {
				customers.request();
				hideCreateCustomer();
			}}
			onCancel={() => hideCreateCustomer()}
		/>
	);

	if (!cp('project.view_customer')) return <PermissionDenied />;

	return (
		<Layout>
			<ProjectTabs />
			<div className='is-flex'>
				<div className='column m-0 p-0'>
					<span className='is-size-4'>Клиенты</span>
					<br />
				</div>
				<div className='column m-0 p-0'>
					<div className='buttons has-addons is-right'>{cp('finance.add_wallet') ? <Button onClick={showCreateCustomer} text='Создать клиент' icon='ion-md-add' className='is-centered is-link is-outlined' /> : null}</div>
				</div>
			</div>

			<Table
				loading={customers.loading}
				totalCount={customers.response ? customers.response.count : 0}
				items={customers.response ? customers.response.results : []}
				columns={{ name: 'Имя', user: 'Системный пользователь:', actions: '' }}
				renderItem={item => <CustomerItem key={item.id} onUpdate={customers.request} onDelete={customers.request} customer={item} />}
			/>
		</Layout>
	);
}
