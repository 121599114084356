import React, { useState } from "react";

import { useModal } from "../hooks/modal";
import { useLoad } from "../hooks/request";
import Layout from "../components/Layout";
import ZeroTabs from "../components/ZeroTabs";
import Table from "../components/common/Table";
import { INCOME_QUARTERLY_LIST } from "../urls";
import Button from "../components/common/Button";
import IncomeQuarterlyItem from "../components/IncomeQuarterlyItem";
import IncomeQuarterlyCreate from "../components/IncomeQuarterlyCreate";

export default function IncomeQuarterly() {
  const [selectCountry, setSelectCountry] = useState(null);
  const [page, setPage] = useState(1);
  const report = useLoad({ url: INCOME_QUARTERLY_LIST, params: { user: selectCountry, page } }, [selectCountry, page]);
  const reportItem = report.response ? report.response.results : [];

  const [showModal, hideModal] = useModal(
    <IncomeQuarterlyCreate
      onSuccess={() => {
        hideModal();
        report.request();
      }}
      onCancel={() => hideModal()}
    />,
  );

  return (
    <Layout>
      <div style={{ position: "sticky", top: 0, left: 0, width: "100%", zIndex: 10, backgroundColor: "white" }}>
        <ZeroTabs />
        <div className="columns mb-4 mr-3">
          <div className="column is-capitalized ml-4">
            <span className="is-size-4">Фойда солиғи:</span>
          </div>
          <div className="column mt-3">
            <div className="columns is-pulled-right">
              <Button icon="ion-md-calendar" text="Янги квартал қўшиш" onClick={showModal} className="button is-success is-outlined" />
            </div>
          </div>
        </div>
      </div>
      <Table
        loading={report.loading}
        items={reportItem}
        totalCount={report.response ? report.response.count : 0}
        pageSize={10}
        activePage={page}
        onPageChange={setPage}
        columns={{ id: "№", name: "Квартал номи", stir: "Мижозлар", directorStir: "Ҳисоботлар Жунатилиши", ssuploadRotation: "Солиқлар тўланиши", ad: "", uploadRotation: "", assd: "" }}
        renderItem={(item, index) => (
          <IncomeQuarterlyItem reportItem={item} index={index} key={item.id} onUpdate={report.request} onDelete={report.request} request={report.request} reportItems={reportItem.response ? reportItem.response.results : []} finance={item} />
        )}
      />
    </Layout>
  );
}
