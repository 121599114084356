import { Form, Formik } from 'formik'
import React from 'react'
import { required } from '../utils/validators'
import Button from './common/Button'
import Input from './common/Input'
import IconPicker from './common/IconPicker'

export default function WalletForm({ onCancel, onSubmit, values, loading }) {
    return (
        <Formik initialValues={{ name: '', icon: '', ...values }} onSubmit={onSubmit}>
            <Form>
                <Input
                    name="name"
                    label="Имя счёта"
                    validate={required}
                    placeholder="Введите имя счёта" />

                <IconPicker name="icon" validate={required} />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
