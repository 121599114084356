import React from 'react'
import map from 'lodash/map'
import { MEMBERS_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ProjectMemberForm from './ProjectMemberForm'
import { getRole } from '../utils/memberRoles'

export default function ProjectMemberUpdate({ onCancel, onSuccess, member, employees, project, onBack }) {
    const url = MEMBERS_DETAIL.replace('{id}', member.id)
    const projectUpdate = usePutRequest({ url })

    async function onSubmit(values) {
        const { success } = await projectUpdate.request({ data: {
            ...values,
            roles: map(values.roles, 'value'),
            project: project.id,
        } })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Изменить участник</b><br /><br />

            <ProjectMemberForm
                values={{ ...member, employee: member.employee.id, roles: map(member.roles, (role) => getRole(role)) }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onBack={onBack}
                employees={employees}
                loading={projectUpdate.loading} />
        </div>
    )
}
