import React, { Fragment } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { css, StyleSheet } from 'aphrodite'
import TaskBoardCard from './TaskBoardCard'
import { checkPermission as cp } from '../utils/auth'

export default function TaskBoardColumn({
    columnKey,
    column,
    tasks,
    board,
    onDelete,
    onUpdate,
    onPointsUpdate,
    lineColor,
    onCompleted,
}) {
    return (
        <Fragment>
            <div className={css(styles.header)}>
                <span className={css(styles.headerTitle)}>
                    {column.title} &nbsp;
                </span>

                <hr style={{ background: lineColor }} className={css(styles.line)} />
            </div>
            {/* isDropDisabled={!cp('tasks.change_boardtask')} */}
            <Droppable droppableId={columnKey}>
                {(provider, snapshot) => (
                    <div ref={provider.innerRef} className={css(snapshot.isDraggingOver && styles.active)}>
                        {tasks.map((task, index) => (
                            <TaskBoardCard
                                key={task.id}
                                onDelete={onDelete}
                                task={task}
                                board={board}
                                onPointsUpdate={onPointsUpdate}
                                onCompleted={onCompleted}
                                index={index}
                                onUpdate={(data) => onUpdate(task.id, data)} />
                        ))}

                        {provider.placeholder}
                    </div>
                )}
            </Droppable>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    active: {
        background: '#e8e8e8',
        borderRadius: '3px',
    },
    line: {
        margin: '0.5rem 0 1rem',
    },
    header: {
        background: 'whitesmoke',
        margin: '0 -0.6rem',
        padding: '0 0.6rem',
        zIndex: 1,
        position: 'sticky',
        top: '0',
    },
    headerTitle: {
        marginLeft: '0.4rem',
    },
})
