import React from 'react'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import { MANTHLY_INCOME_TAX_LIST } from '../urls'
import Layout from '../components/Layout'
import ManthlyTabs from '../components/ManthlyTabs'
import ManthlyIncomeTaxItem from '../components/ManthlyIncomeTaxItem'
import ManthlyIncomeTaxCreate from '../components/ManthlyIncomeTaxCreate'
import Table from '../components/common/Table'

export default function ManthlyIncomeTax() {
    const report = useLoad({ url: MANTHLY_INCOME_TAX_LIST })
    const reportItem = report.response ? report.response.results : []
    const [showModal, hideModal] = useModal(
        <ManthlyIncomeTaxCreate
            onSuccess={() => {
                hideModal()
                report.request()
            }}
            onCancel={() => hideModal()}
        />,
    )

    return (
        <Layout>
            <div style={{ position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 10, backgroundColor: 'white' }}>
                <ManthlyTabs />
                <div className="columns mb-4 mr-3">
                    <div className="column is-capitalized ml-4">
                        <span className="is-size-4">Жисмоний шахсланинг даромад солиғи:</span>
                    </div>
                    <div className="column mt-3">
                        <div className="columns is-pulled-right">
                            <Button icon="ion-md-calendar" text="Янги ой қўшиш" onClick={showModal} className="button is-success is-outlined" />
                        </div>
                    </div>
                </div>
            </div>
            <Table
                loading={report.loading}
                items={reportItem}
                columns={{ id: '№', name: 'Ой номи', stir: 'Мижозлар', directorStir: 'Ҳисоботлар Жунатилиши', ssuploadRotation: 'Солиқлар тўланиши', xdirectorStir: 'ИНПС реестр жунатилиши', ad: '', uploadRotation: '', assd: '' }}
                renderItem={(item, index) => (
                    <ManthlyIncomeTaxItem reportItem={item} index={index} key={item.id} onUpdate={report.request} onDelete={report.request} request={report.request} reportItems={reportItem.response ? reportItem.response.results : []} finance={item} />
                )}
            />
        </Layout>
    )
}
