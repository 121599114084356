import React, { Fragment } from 'react'
import Button from './common/Button'
import Table from './common/Table'
import SalesChannelItem from './SalesChannelItem'
import { SALES_CHANNEL_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import SalesChannelCreate from './SalesChannelCreate'
import { useLoad } from '../hooks/request'
import { checkPermission as cp } from '../utils/auth'

export default function SettingsSalesChannels() {
    const salesChannels = useLoad({ url: SALES_CHANNEL_LIST }, [])

    const [showSalesChanelCreateModal, hideSalesChanelCreateModal] = useModal(
        <SalesChannelCreate onCancel={() => {
            hideSalesChanelCreateModal()
        }} onSuccess={() => {
            hideSalesChanelCreateModal()
            salesChannels.request()
        }} />,
    )

    return (
        <Fragment>
            <div className="columns">
                <div className="is-size-4 column">Каналы продаж</div>

                {cp('marketing.add_saleschannel') ? (
                    <div className="column is-narrow">
                        <Button
                            onClick={showSalesChanelCreateModal}
                            text="Добавить"
                            icon="ion-md-add"
                            className="is-pulled-right is-link is-outlined" />
                    </div>
                ) : null}
            </div>

            {cp('marketing.view_saleschannel') ? (
                <Table
                    loading={salesChannels.loading}
                    items={salesChannels.response ? salesChannels.response.results : []}
                    renderItem={(item) => (
                        <SalesChannelItem
                            key={item.id}
                            onUpdate={salesChannels.request}
                            onDelete={salesChannels.request}
                            item={item} />
                    )} />
            ) : null}
        </Fragment>
    )
}
