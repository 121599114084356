import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { getMonth } from '../utils/date'
import { format } from '../utils/number'

export default function TransactionTypeItem({ item, type }) {
    return (
        <div className="column is-4-widescreen is-6-desktop is-12-tablet">
            <div className={cn(css(styles.box), 'box')}>
                <div className="columns">
                    <div className="column is-narrow">
                        <div className={cn('icon has-text-grey-light', type.icon, css(styles.icon))} />
                    </div>

                    <div className="column">
                        <b>{type.name}</b>

                        <p>
                            Баланс: &nbsp;
                            <span className="has-text-info">
                                {format(item.balance || 0)} Сум
                            </span>
                        </p>

                        <p>Приход ({getMonth()}): &nbsp;
                            <span className="has-text-success">
                                {format(item.income || 0)} Сум
                            </span>
                        </p>

                        <p>Расход ({getMonth()}): &nbsp;
                            <span className="has-text-danger">
                                {format(item.outcome || 0)} Сум
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}


const styles = StyleSheet.create({
    icon: {
        fontSize: '3em',
        margin: '1rem 0.5rem',
    },
    box: {
        height: '100%',
    },
})
