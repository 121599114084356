import React from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { css, StyleSheet } from 'aphrodite';

import Loader from './Loader';

export default function Table({ loading = false, showEmptyMessage = true, items, columns, renderItem, className, border = false }) {
	if (loading) {
		return (
			<div className={css(styles.space)}>
				<Loader large center />
			</div>
		);
	}
	if (isEmpty(items) && showEmptyMessage) return <div className={cn('is-size-3 has-text-centered has-text-grey', css(styles.space))}>Пусто</div>;
	return (
		<main className={css(styles.parent)}>
			<div className='table-container'>
				<table className={cn('table is-striped is-fullwidth', border ? '' : 'is-bordered', className)} style={{ width: '100%', tableLayout: 'auto' }}>
					<tbody>
						{columns ? (
							<tr>
								{Object.entries(columns).map(([key, value]) => (
									<th key={key}>{value}</th>
								))}
							</tr>
						) : null}

						{items.map(renderItem)}
					</tbody>
				</table>
			</div>
		</main>
	);
}

const styles = StyleSheet.create({
	parent: {
		':nth-child(1n) .table-container': {
			position: 'relative',
			overflow: 'scroll',
			height: '85vh',
			width: '100%',
			':nth-child(1n) table': {
				tableLayout: 'auto',
				width: '100%',
				':nth-child(1n) tr>th': { position: 'sticky', background: 'whitesmoke', left: 0, zIndex: 1 },
				':nth-child(1n) tr>td:first-child': { position: 'sticky', background: 'whitesmoke', left: 0, zIndex: 1 },
				':nth-child(1n) th': { top: 0, zIndex: 2 },
				':nth-child(1n) th:first-child': { zIndex: 3, fontWeight: 400 },
				':nth-child(1n) td': { overflow: 'auto' },
			},
		},
	},
	space: { marginTop: '2rem' },
});
