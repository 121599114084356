import React from 'react'
import map from 'lodash/map'
import { ASSET_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ServerError from './common/ServerError'
import AssetForm from './AssetForm'

export default function AssetUpdate({ onCancel, onUpdate, asset }) {
    const url = ASSET_DETAIL.replace('{id}', asset.id)
    const assetUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const { success } = await assetUpdate.request({
            data: {
                ...data, positions: map(data.positions, 'value'),

            }
        })

        if (success) {
            actions.resetForm()
            onUpdate()
        }
    }

    return (
        <div>
            <b>Редактировать Актив</b><br/><br/>
            <ServerError error={assetUpdate.error}/>

            <AssetForm
                initialValues={{ ...asset, responsible: asset.responsible.id }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={assetUpdate.loading}/>
        </div>
    )
}
