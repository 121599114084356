import React from 'react'
import { usePostRequest } from '../hooks/request'
import { MANTHLY_QQC_MONTHLY_LIST } from '../urls'
import ManthlyQqcMonthlyForm from './ManthlyQqcMonthlyForm'

export default function ManthlyQqcMonthlyCreate({ onCancel, onSuccess }) {
    const qqcMonthlyCreate = usePostRequest({ url: MANTHLY_QQC_MONTHLY_LIST })

    async function onSubmit(data, actions) {
        const { success } = await qqcMonthlyCreate.request({ data })

        if (success) {
            onSuccess()
            actions.resetForm()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <ManthlyQqcMonthlyForm onSubmit={onSubmit} onCancel={onCancel} loading={qqcMonthlyCreate.loading} />
        </div>
    )
}
