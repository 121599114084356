import React from 'react'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { ASSET_LIST } from '../urls'
import FinanceTabs from '../components/FinanceTabs'
import { checkPermission as cp } from '../utils/auth'
import Button from '../components/common/Button'
import Table from '../components/common/Table'
import { useModal } from '../hooks/modal'
import AssetCreate from '../components/AssetCreate'
import AssetItem from '../components/AssetItem'
import { useQueryParams } from '../hooks/queryString'
import { PermissionDenied } from '../components/PermissionDenied'

export default function Assets() {
    const params = useQueryParams()
    const assets = useLoad({ url: ASSET_LIST, params }, [params])

    const [showCreateModal, hideCreateModal] = useModal(
        <AssetCreate onSuccess={() => {
            hideCreateModal()
            assets.request()
        }} onCancel={() => hideCreateModal()} />,
    )

    if (!cp('finance.view_asset')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <FinanceTabs />
            <span className="is-size-4">Активы</span>

            {cp('finance.add_asset') ? (
                <Button
                    onClick={showCreateModal}
                    text="Добавить"
                    icon="ion-md-add"
                    className="is-pulled-right is-link is-outlined" />
            ) : null}

            <br />

            <Table
                loading={assets.loading}
                totalCount={assets.response ? assets.response.count : 0}
                items={assets.response ? assets.response.results : []}
                columns={{
                    name: 'Имя',
                    price: 'Цена',
                    usageStart: 'Использунтся с',
                    usageEnd: 'Годен до',
                    responsible: 'Сотрудник',
                    actions: '',
                }}
                renderItem={(item) => (
                    <AssetItem key={item.id} onUpdate={assets.request} onDelete={assets.request} item={item} />
                )} />
        </Layout>
    )
}
