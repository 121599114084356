import React from 'react'
import { LEAD_DETAIL, PROJECT_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ProjectForm from './ProjectForm'
import ServerError from './common/ServerError'

export default function ProjectUpdate({ onCancel, onUpdate, project }) {
    const projectUpdate = usePutRequest({ url: PROJECT_DETAIL.replace('{id}', project.id) })
    const leadUpdate = usePutRequest({ url: LEAD_DETAIL.replace('{id}', project.lead.id) })

    async function onSubmit(data) {
        await leadUpdate.request({
            data: {
                ...data,
                customer: project.lead.customer,
                status: project.lead.status,
                salesChannel: project.lead.salesChannel.id,
            },
        })
        await projectUpdate.request({
            data: {
                ...data,
                lead: project.lead.id,
            },
        })

        onUpdate()
    }

    return (
        <div>
            <b>Редактировать Проекта</b><br /><br />
            <ServerError error={projectUpdate.error} />

            <ProjectForm
                initialValues={{ ...project.lead, description: project.lead.description || '', ...project }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={projectUpdate.loading || leadUpdate.loading}
                updateWithLead />
        </div>
    )
}
