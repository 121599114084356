import React from 'react'

import { useModal } from '../hooks/modal'
import { useLoad } from '../hooks/request'
import Layout from '../components/Layout'
import { QQC_QUARTERLY_LIST } from '../urls'
import ZeroTabs from '../components/ZeroTabs'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import QqcQuarterlyItem from '../components/QqcQuarterlyItem'
import QqcQuarterlyCreate from '../components/QqcQuarterlyCreate'

export default function QqcQuarterly() {
    const params = useQueryParams()
    const qqcMonthly = useLoad({ url: QQC_QUARTERLY_LIST, params }, [params])
    const reportItem = qqcMonthly.response ? qqcMonthly.response.results : []

    const [showModal, hideModal] = useModal(
        <QqcQuarterlyCreate
            onSuccess={() => {
                hideModal()
                qqcMonthly.request()
            }}
            onCancel={() => hideModal()}
        />,
    )

    return (
        <Layout>
            <ZeroTabs />
            <div className="columns mt-3 mr-3">
                <div className="column is-capitalized ml-4">
                    <span className="is-size-4">ҚҚС Кварталлик:</span>
                </div>
                <div className="column mt-3">
                    <div className="columns is-pulled-right">
                        <Button icon="ion-md-calendar" text="Янги ой қўшиш" onClick={showModal} className="button is-success is-outlined" />
                    </div>
                </div>
            </div>
            <Table
                loading={qqcMonthly.loading}
                items={reportItem}
                columns={{ id: '№', name: 'Ой номи', stir: 'Мижозлар', directorStir: 'Ҳисоботлар Жунатилиши', ssuploadRotation: 'Солиқлар тўланиши', xdirectorStir: 'ИНПС реестр жунатилиши', ad: '', uploadRotation: '', assd: '' }}
                renderItem={(item, index) => (
                    <QqcQuarterlyItem reportItem={item} index={index} key={item.id} onUpdate={qqcMonthly.request} onDelete={qqcMonthly.request} request={qqcMonthly.request} reportItems={reportItem.response ? reportItem.response.results : []} finance={item} />
                )}
            />
        </Layout>
    )
}
