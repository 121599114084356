import React from 'react'
import { usePostRequest } from '../hooks/request'
import { ASSET_LIST } from '../urls'
import ServerError from './common/ServerError'
import AssetForm from './AssetForm'

export default function AssetCreate({ onCancel, onSuccess }) {
    const assetCreate = usePostRequest({ url: ASSET_LIST })

    async function onSubmit(data, actions) {
        const { success } = await assetCreate.request({
            data: {
                ...data,
                type: data.type || undefined,
                wallet: data.wallet || undefined,
            },
        })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить новый актив</b>
            <br />
            <br />
            <ServerError error={assetCreate.error} />

            <AssetForm onSubmit={onSubmit} onCancel={onCancel} loading={assetCreate.loading} />
        </div>
    )
}
