import React from 'react'
import { MANTHLY_QQC_QUARTERLY_ITEM_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ServerError from './componentsFinance/common/ServerError'
import ManthlyQqcQuarterlyItemForm from './ManthlyQqcQuarterlyItemForm'

export default function ManthlyQqcQuarterlyItemUpdate({ onCancel, onSuccess, reportItem, finance, qqcQuarterlyId }) {
    const url = MANTHLY_QQC_QUARTERLY_ITEM_DETAIL.replace('{id}', reportItem.id)
    const qqcQuarterlyItemUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        const { success } = await qqcQuarterlyItemUpdate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Изменить данные</b><br /><br />
            <ServerError error={qqcQuarterlyItemUpdate.error} />
            <ManthlyQqcQuarterlyItemForm
                initialValues={reportItem}
                onSubmit={onSubmit}
                onCancel={onCancel}
                qqcQuarterlyId={qqcQuarterlyId}
                finance={finance}
                loading={qqcQuarterlyItemUpdate.loading} />
        </div>
    )
}
