/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Formik, Form } from 'formik'
import Button from './common/Button'
import Input from './common/Input'
import { phone, required, validator } from '../utils/validators'


export default function LeadForm({ loading, onSubmit, onCancel, initialValues }) {
    const values = {
        name: '',
        phone: '',
        description: '',
        ...initialValues,
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={values}>
            <Form>
                <div className="field is-horisontal">
                    <div className="field-body">
                        <Input name="name" label="Имя" validate={required} />
                        <Input name="phone" label="Номер телефона" validate={validator(required, phone)} />
                    </div>
                </div>

                <Input name="description" component="textarea" label="Примечание" optional />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
