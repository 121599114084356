import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Button from './componentsFinance/common/Button';
import { useModal } from '../hooks/modal';
import { domain } from '../utils/request';
import IncomeQuarterlyItemCreate from './IncomeQuarterlyItemCreate';
import IncomeQuarterlyItemUpdate from './IncomeQuarterlyItemUpdate';
import { INCOME_QUARTERLY_ITEM_DETAIL } from '../urls';
import { usePutRequest } from '../hooks/request';

export default function IncomeQuarterlyItemItem({ reportItems, finance, incomeQuarterlyId, index, request }) {
	const reportItem = reportItems.filter(item => item.finance === finance.id)[0];
	const [showModal, hideModal] = useModal(
		<IncomeQuarterlyItemCreate
			onSuccess={() => {
				hideModal();
				request();
			}}
			incomeQuarterlyId={incomeQuarterlyId}
			finance={finance}
			onCancel={() => hideModal()}
		/>
	);
	const [showUpdateModal, hideUpdateModal] = useModal(
		<IncomeQuarterlyItemUpdate
			onSuccess={() => {
				hideUpdateModal();
				request();
			}}
			reportItem={reportItem}
			incomeQuarterlyId={incomeQuarterlyId}
			finance={finance}
			onCancel={() => hideModal()}
		/>
	);
	function onSubmit() {
		if (reportItem) {
			showUpdateModal();
			return;
		}
		showModal();
	}

	const [taxStatus, setTaxStatus] = useState(reportItem && reportItem.tax ? reportItem.tax : false);
	const [inpsStatus, setInpsStatus] = useState(reportItem && reportItem.inps ? reportItem.inps : false);
	const url = INCOME_QUARTERLY_ITEM_DETAIL.replace('{id}', reportItem?.id);
	const qqcMonthlyItemUpdate = usePutRequest({ url });

	useEffect(() => {
		setTaxStatus(reportItem && reportItem.tax ? reportItem.tax : false);
		setInpsStatus(reportItem && reportItem.inps ? reportItem.inps : false);
	}, [reportItem]);

	async function onUpdateTax(status) {
		const { success } = await qqcMonthlyItemUpdate.request({ data: { tax: status } });
		if (success) {
			request();
		}
	}

	async function onUpdateInps(status) {
		const { success } = await qqcMonthlyItemUpdate.request({ data: { inps: status } });
		if (success) {
			request();
		}
	}

	function handleTaxStatusChange(event) {
		const newTaxStatus = event.target.checked;
		setTaxStatus(newTaxStatus);
		onUpdateTax(newTaxStatus);
	}

	function handleInpsStatusChange(event) {
		const newTaxStatus = event.target.checked;
		setInpsStatus(newTaxStatus);
		onUpdateInps(newTaxStatus);
	}

	return (
		<tr className='pl-3 pr-3'>
			<td className='has-text-centered'>{index + 1}</td>

			{reportItem ? <td className={cn('has-text-centered has-text-black')}>{finance.name}</td> : <td className={cn('has-text-centered has-text-danger')}>{finance.name}</td>}

			<td className='has-text-centered'>{finance.stir}</td>
			<td className='has-text-centered'>{finance.directorStir}</td>

			{reportItem ? (
				<td className='has-text-centered'>
					<a className='has-text-black tag is-info is-light' href={domain + reportItem.incomeQuarterlyPdf}>
						Скачать
					</a>
				</td>
			) : (
				<td className='has-text-centered'>Пусто</td>
			)}

			<td className='has-text-centered'>{reportItem?.incomeQuarterlyComment ? reportItem?.incomeQuarterlyComment :
				<span>Пусто</span>}</td>

			<td className='has-text-centered'>
				<input style={{ width: 22, height: 22 }} type='checkbox' checked={taxStatus} onChange={handleTaxStatusChange} />
			</td>

			{/* <td className="has-text-centered">
                <input style={{ width: 22, height: 22 }} type="checkbox" checked={inpsStatus}
                       onChange={handleInpsStatusChange}/>
            </td> */}

			<td>
				<Button onClick={onSubmit} className={reportItem ? 'button is-success is-light' : 'button is-danger is-light'} icon={reportItem ? 'icon ion-md-create' : 'icon ion-md-add'} text={reportItem ? 'Обновить' : 'Добавить'} />
			</td>
		</tr>
	);
}
