import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Draggable } from 'react-beautiful-dnd'
import moment from 'moment'
import TaskPoints from './TaskPoints'
import Avatar from './Avatar'
import { useModal } from '../hooks/modal'
import TaskDetails from './TaskDetails'
import { getDateTime } from '../utils/date'

export default function TaskBoardCard({ task, board, index, onUpdate, onDelete, onPointsUpdate, onCompleted }) {
    const [showDetailsModal, hideDetailsModal] = useModal(
        <TaskDetails
            task={task.task}
            boardTask={task}
            board={board}
            onUpdate={onUpdate}
            onPointsUpdate={onPointsUpdate}
            onCompleted={() => {
                onCompleted(task.id)
                hideDetailsModal()
            }}
            onDelete={(id) => {
                onDelete(id)
                hideDetailsModal()
            }} />,
        styles.modal,
    )

    const [durationString, setDurationString] = useState(task.duration)
    const [formattedDuration, setFormattedDuration] = useState('')
    useEffect(() => {
        // Parse the duration string using moment
        const durationMoment = moment.duration(durationString)

        // Extract days, hours, minutes, and seconds
        const days = durationMoment.days()
        const hours = durationMoment.hours()
        const minutes = durationMoment.minutes()

        // Create the formatted duration string
        let formatted = ''
        if (days > 0) {
            formatted += `${days} kun `
        }
        if (hours > 0) {
            formatted += `${hours} soat `
        }
        formatted += `${minutes} minut`

        // Update the state with the formatted duration
        setFormattedDuration(formatted.trim())
    }, [durationString])

    return (
        <Draggable draggableId={String(task.id)} index={index}>
            {(provider, snap) => (
                <div
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    {...provider.dragHandleProps}
                    onClick={showDetailsModal}
                    className={cn('box', css(styles.card, snap.isDragging && styles.active))}
                    style={provider.draggableProps.style}>

                    <div className="columns">
                        <div className="column">
                            {Math.abs(new Date(task.createdAt) - new Date(board.createdAt)) / 3600000 > 2
                                ? <span className="tag is-success mr-2 is-normal is-light">new</span> : null}
                            <span className={css(styles.title)}>{task.task.title}</span> <br />
                            <p className="tag is-light is-link">#{task.task.id}</p>
                            <p className="has-text-grey-light">{task.duration ? formattedDuration : 'None Time'}</p>
                        </div>

                        <div className="column is-narrow">
                            <TaskPoints points={task.task.points} className="mr-3" />
                            <Avatar employee={task.task.assigned} />

                        </div>

                    </div>
                </div>
            )}
        </Draggable>
    )
}

const styles = StyleSheet.create({
    card: {
        userSelect: 'none',
        marginBottom: '0.5rem',
        borderRadius: '3px',
    },
    title: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
        '@media (max-width: 769px)': {
            maxWidth: '30rem',
        },
    },
    active: {
        // drugging styles goes here
    },
    modal: {
        width: 800,
    },
})
