import React from 'react'
import { StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import { FINANCE_DETAIL, MANTHLY_FINANCE_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { useMessage } from '../hooks/message'
import FinanceUpdate from './FinanceUpdate'
import { domain } from '../utils/request'
import Button from './componentsFinance/common/Button'
import FinanceInfo from './FinanceInfo'
import { getDateOtherFormat } from '../utils/date'

export default function FinanceKeysItem({ item, index, onDelete, onUpdate, page }) {
    const history = useHistory()
    const financeDelete = useDeleteRequest({ url: item.types === 'zero_types' ? FINANCE_DETAIL.replace('{id}', item.id) : MANTHLY_FINANCE_DETAIL.replace('{id}', item.id) })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <FinanceUpdate
            finance={item}
            onUpdate={() => {
                onUpdate()
                hideUpdateModal()
            }}
            onCancel={() => hideUpdateModal()}
        />,
        styles.modal,
    )

    const [showMessage] = useMessage()

    async function deleteFinance() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await financeDelete.request()
        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    const handleClick = () => {
        console.log(item)
        const url = item.types === 'monthly_types' ? `/clients/manthly/${item.id}` : item.types === 'zero_types' && `/clients/zero/${item.id}`

        history.push(url)
    }

    const [showViewModal, hideViewModal] = useModal(<FinanceInfo lead={item} onSuccess={() => hideViewModal()}
        onCancel={() => hideViewModal()} />)
    const itemsPerPage = 10
    const position = (page - 1) * itemsPerPage + index + 1

    const getColorBasedOnDate = (firstValidTo) => {
        if (!firstValidTo) {
            // Red: If firstValidTo is None (null or undefined)
            return 'has-text-danger'
        }

        const today = new Date()
        const validToDate = new Date(firstValidTo)

        // If firstValidTo is in the past (before today)
        if (validToDate < today) {
            return 'has-text-danger' // Red: If firstValidTo is in the past
        }

        // If today <= firstValidTo < today + 30 (within the next 30 days)
        const diffInDays = Math.ceil((validToDate - today) / (1000 * 3600 * 24)) // Calculate days difference

        if (diffInDays <= 30) {
            return 'has-text-warning' // Yellow: If the date is within the next 30 days
        }

        // If more than 30 days away
        return 'has-text-success' // Green: If the date is more than 30 days in the future
    }

    return (
        <tr>
            <td className="has-text-center pr-3 pl-3">{position}</td>
            <td
                onClick={handleClick}
                style={{
                    cursor: 'pointer',
                    color: '#1d4ed8',
                    textDecoration: 'underline',
                    textUnderlineOffset: '3px',
                }}
                className="has-text-center pr-3 pl-3"
            >
                {item.name}
            </td>
            <td className="has-text-center pr-3 pl-3">{item.stir}</td>
            <td className="has-text-center pr-3 pl-3">{item.directorName}</td>
            <td className="has-text-center pr-3 pl-3">{item.directorStir}</td>
            <td className="pr-6">
                <a href={domain + item.first} className="has-text-dark has-text-center">
                    <Button icon="icon ion-md-download" className="is-pulled-right is-black is-outlined is-small" />
                </a>
            </td>
            {item.firstValidTo && item.firstValidFrom ? (
                <td className={`has-text-center pr-3 pl-3 ${getColorBasedOnDate(item.firstValidTo)}`}>
                    {getDateOtherFormat(item.firstValidTo)}
                </td>
            ) : (
                <td><span className="is-small tag is-danger">Калит топилмади</span></td>
            )}


            <td className="pr-6">
                <a href={domain + item.second} className="has-text-dark">
                    <Button icon="icon ion-md-download" className="is-pulled-right is-black is-outlined is-small" />
                </a>
            </td>

            {item.secondValidTo && item.secondValidFrom ? (
                <td className={`has-text-center pr-3 pl-3 ${getColorBasedOnDate(item.secondValidTo)}`}>
                    {getDateOtherFormat(item.secondValidTo)}
                </td>
            ) : (
                <td><span className="is-small tag is-danger">Калит топилмади</span></td>
            )}


        </tr>
    )
}

const styles = StyleSheet.create({ modal: { width: 800 } })
