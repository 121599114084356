import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'

export default function Avatar({ employee, className = 'is-32x32' }) {
    if (!employee) return null

    return (
        <figure
            className={cn('image is-rounded', className, css(styles.figure))}
            title={employee.name}>

            {/* TODO: add photo to employee */}
            {/* <img className="is-rounded" src="/128x128.png" alt={employee.name} /> */}

            <div className={cn('has-background-primary is-uppercase is-32x32', css(styles.default))}>
                {employee.name ? employee.name[0] : null}
            </div>
        </figure>
    )
}

const styles = StyleSheet.create({
    figure: {
        display: 'inline-block',
    },
    default: {
        fontSize: '1.3em',
        color: '#fff',
        borderRadius: '50%',
        placeContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
})
