import React, { Fragment } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
// import moment from 'moment'
import Layout from '../components/Layout'
import { EMPLOYEE_DETAIL, EMPLOYEE_IMAGE } from '../urls'
import { useLoad, usePutRequest } from '../hooks/request'
// import Table from '../components/common/Table'
// import { PermissionDenied } from '../components/PermissionDenied'
import { checkPermission as cp } from '../utils/auth'
import Button from '../components/common/Button'
import { useModal } from '../hooks/modal'
// import EmployeeSalaryItem from '../components/EmployeeSalaryItem'
// import { format } from '../utils/number'
import EmployeePositions from '../components/EmployeePositions'
import Loader from '../components/common/Loader'
import EmployeeInvite from '../components/EmployeeInvite'
import TelegramInvite from '../components/TelegramInvite'
import defaultAvatar from '../static/avatar_default.png'
import { domain } from '../utils/request'

export default function EmployeeDetail() {
    const { employeeId } = useParams()
    // const employeeSalary = useLoad({ url: EMPLOYEE_SALARY.replace('{id}', employeeId) })
    const employeeDetail = useLoad({ url: EMPLOYEE_DETAIL.replace('{id}', employeeId) })
    const employee = employeeDetail.response
    // const [page, setPage] = useState(1)
    const uploadAvatar = usePutRequest({ url: EMPLOYEE_IMAGE.replace('{id}', employeeId) })
    const [showEmployeeInviteModal, hideEmployeeInviteModal] = useModal(
        <EmployeeInvite
            employee={employeeDetail.response}
            onCancel={() => hideEmployeeInviteModal()}
            onSuccess={() => {
                hideEmployeeInviteModal()
                employeeDetail.request()
            }} />,
    )

    async function uploadImage({ target }) {
        const image = target.files[0]
        const newData = new FormData()
        newData.append('image', image, image.name)
        const { success, response } = await uploadAvatar.request({ data: newData })
        if (success) employeeDetail.setResponse({ ...employee, image: response.image })
    }

    const [showTelegramInviteModal, hideTelegramInviteModal] = useModal(
        <TelegramInvite itemId={employeeId} onCancel={() => hideTelegramInviteModal()} userType="employee" />,
    )

    // const transactions = useLoad({
    //     url: SALARY_LIST.replace('{employee_id}', employeeId),
    //     params: { employee: employeeId, page },
    // }, [page])
    //
    // if (!cp('staff.view_employee')) {
    //     return <PermissionDenied />
    // }

    return (
        <Layout>
            <NavLink className="has-text-black button is-white" to="/staff/employee">
                <i className="icon ion-md-arrow-back is-size-4" />
            </NavLink>

            <span className="is-size-4">
                Информация о сотруднике : {employee ? employee.name : ''}
            </span>


            {!employeeDetail.loading && employee ? (
                <div>
                    {cp('staff.add_employeeinvitation') && !employee.user && !employee.invited ? (
                        <article className="message is-warning">
                            <div className="message-body">
                                <b>Данный сотрудник не зарегистрирован в системе!</b><br />
                                Что бы пригласить нажмите на кнопку ниже и введите электронный адрес сотрудника.
                                Мы отправим ему(ей) письмо с ссылкой на приватную страницу быстрой регистрации.<br />
                                <br />

                                <Button
                                    text="Пригласить"
                                    icon="ion-md-person-add"
                                    onClick={showEmployeeInviteModal}
                                    className="is-primary is-small" />
                            </div>
                        </article>
                    ) : null}

                    <hr />
                    <div className="columns">
                        <label className={cn('column is-2', css(styles.default))}>
                            <img
                                alt=""
                                src={employee.image ? domain + employee.image : defaultAvatar} />

                            <input className={css(styles.input)}
                                type="file"
                                name="image"
                                onChange={uploadImage} />
                        </label>

                        <div className="column is-10">
                            <div className="columns">
                                <b className="column">Ф.И.О: </b>
                                <span className="column">{employee.name}</span>

                                <b className="column">Дата рождения: </b>
                                <span className="column">{employee.birthday}</span>

                            </div>

                            <div className="columns">

                                {/* <b className="column">Доля от продаж: </b> */}
                                {/* <span className="column">{employee.sellerShare}%</span> */}
                            </div>

                            <div className="columns">
                                <b className="column">Должность: </b>
                                <span className="column">
                                    <EmployeePositions positions={employee.positions} />
                                </span>

                                <b className="column">Фиксированная зарплата: </b>
                                <span className="column">{employee.salary} Сум</span>
                            </div>

                            <div className="columns">
                                <b className="column is-3">Системный пользователь: </b>
                                <div className="column">
                                    {employee.user ? employee.user.email : (
                                        <Fragment>
                                            <i className="has-text-grey">
                                                {employee.invited ? 'Был приглашен' : 'не привязан'}
                                            </i> &nbsp;

                                            {cp('staff.add_invitation') ? (
                                                <Button
                                                    text={employee.invited ? 'Повторно пригласить' : 'Пригласить'}
                                                    icon="ion-md-person-add"
                                                    onClick={showEmployeeInviteModal}
                                                    className={cn('is-small', employee.invited ? 'is-warning' : 'is-link')} />
                                            ) : null}
                                        </Fragment>
                                    )}
                                </div>

                                <b className="column is-3">Telegram: </b>
                                <div className="column">
                                    {cp('telegram.add_telegraminvitation') ? (
                                        <Button
                                            text="Пригласить"
                                            icon="ion-md-person-add"
                                            onClick={showTelegramInviteModal}
                                            className={cn('is-small is-link')} />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <Loader large center />}
            <hr />

            {/* <span className="is-size-4">История зарплат</span> */}

            {/* <Table */}
            {/*    loading={employeeSalary.loading} */}
            {/*    totalCount={employeeSalary.response ? employeeSalary.response.length : 0} */}
            {/*    pageSize={employeeSalary.response ? employeeSalary.response.length : 0} */}
            {/*    items={employeeSalary.response ? employeeSalary.response : []} */}
            {/*    columns={{ name: 'Месяц', salary: 'Зарплата', actions: '' }} */}
            {/*    renderItem={(item) => ( */}
            {/*        <EmployeeSalaryItem key={item.month} item={item} employee={employee} /> */}
            {/*    )} /> */}

            {/* <br /> */}

            {/* <span className="is-size-4"> */}
            {/*    История выплат */}
            {/* </span> */}

            {/* {cp('finance.view_salary') ? ( */}
            {/*    <Table */}
            {/*        loading={transactions.loading} */}
            {/*        totalCount={transactions.response ? transactions.response.count : 0} */}
            {/*        items={transactions.response ? transactions.response.results : []} */}
            {/*        columns={{ id: '№', amount: 'Сумма', wallet: 'Кошелек', createdAt: 'Дата создания' }} */}
            {/*        activePage={page} */}
            {/*        onPageChange={setPage} */}
            {/*        renderItem={(transaction) => ( */}
            {/*            <tr key={transaction.id}> */}
            {/*                <td>{transaction.id}</td> */}

            {/*                <td className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}> */}
            {/*                    {format(transaction.amount)} */}
            {/*                </td> */}

            {/*                <td>{transaction.wallet.name}</td> */}
            {/*                <td>{moment(transaction.createdAt).calendar()}</td> */}
            {/*            </tr> */}
            {/*        )} /> */}
            {/* ) : null} */}
        </Layout>
    )
}

const styles = StyleSheet.create({
    default: {
        placeContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: '5px',
        width: '200px',
        height: '190px',
        cursor: 'pointer',
    },
    input: {
        width: 'auto',
        display: 'none',
    },
})
