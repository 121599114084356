import React from 'react';
import { StyleSheet } from 'aphrodite';

import { MANTHLY_FINANCE_LIST } from '../urls';
import Table from '../components/common/Table';
import { useLoad, useSortTableData } from '../hooks/request';
import ManthlyFinanceItem from '../components/ManthlyFinanceItem';

export default function ManthlyFinance({ params }) {
	const finance = useLoad({ url: MANTHLY_FINANCE_LIST, params }, [params]);
	const { items } = useSortTableData(finance.response ? finance.response.results : []);

	return (
		<Table
			loading={finance.loading}
			items={items}
			columns={{ id: '№', name: 'Корхона номи', stir: 'Корхона стири', directorName: 'Корхона рахбари', directorStir: 'Рахбари стири', first: 'ЭРИ(Корхона)', second: 'ЭРИ(Раҳбар)', assds: '', asds: '' }}
			renderItem={(item, index) => <ManthlyFinanceItem key={item.id} index={index} page={1} onUpdate={finance.request} onDelete={finance.request} item={item} />}
		/>
	);
}

const styles = StyleSheet.create({ modal: { width: 800 } });
