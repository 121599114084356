import React from 'react'
import { usePostRequest } from '../hooks/request'
import { MANTHLY_INCOME_QUARTERLY_LIST } from '../urls'
import ManthlyIncomeQuarterlyForm from './ManthlyIncomeQuarterlyForm'

export default function ManthlyIncomeQuarterlyCreate({ onCancel, onSuccess }) {
    const rotationCreate = usePostRequest({ url: MANTHLY_INCOME_QUARTERLY_LIST })

    async function onSubmit(data, actions) {
        const { success } = await rotationCreate.request({ data })

        if (success) {
            onSuccess()
            actions.resetForm()
        }
    }

    return (
        <div>

            <b>Добавить</b><br /><br />
            <ManthlyIncomeQuarterlyForm onSubmit={onSubmit} onCancel={onCancel} loading={rotationCreate.loading} />
        </div>
    )
}
