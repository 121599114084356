import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { useInfiniteScroll, useLoad } from '../hooks/request';
import { BOARD_DETAIL, BOARD_TASK_LIST } from '../urls';
import Loader from '../components/common/Loader';
import TaskBoard from '../components/TaskBoard';
import { taskStatuses } from '../utils/types';
import TaskProjectBoardActions from '../components/TaskProjectBoardActions';
import BoardTabs from '../components/BoardTabs';

export default function TaskProjectBoard() {
	const params = useParams();
	const tasks = useInfiniteScroll({ url: BOARD_TASK_LIST.replace('{board_id}', params.id) });
	const board = useLoad({ url: BOARD_DETAIL.replace('{id}', params.id) });

	if (board.loading || !board.response) {
		return (
			<Layout>
				<Loader center large />
			</Layout>
		);
	}

	function updateTotalPoints(diff) {
		board.setResponse({
			...board.response,
			totalPoints: board.response.totalPoints + diff,
		});
	}

	return (
		<Layout className={css(styles.header)}>
			<div className='columns'>
				<div className='column'>
					<span className='is-size-4'>
						<BoardTabs />
					</span>{' '}
					&nbsp; &nbsp;
				</div>

				<div className='column is-narrow'>
					<TaskProjectBoardActions onUpdate={board.setResponse} board={board.response} tasks={tasks} />
				</div>
			</div>

			<TaskBoard board={board.response} onPointsUpdate={updateTotalPoints} columns={taskStatuses} tasks={tasks} />
		</Layout>
	);
}

const styles = StyleSheet.create({
	header: {
		background: 'whitesmoke',
		zIndex: 1,
		position: 'sticky',
		top: '0',
	},
});
