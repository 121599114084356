import React from 'react'
import { LEAD_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import LeadForm from './LeadForm'

export default function LeadUpdate({ onCancel, onSuccess, lead: item, status }) {
    const url = LEAD_DETAIL.replace('{id}', item.id)
    const leadUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        const lead = await leadUpdate.request({ data: {
            ...data,
            status: status.id,
        } })

        if (lead.success) {
            onSuccess({ ...lead.response })
        }
    }

    return (
        <div>
            <b>Изменить Лид</b><br /><br />

            <LeadForm
                values={{ ...item, salesChannel: item.salesChannel.id }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                status={status}
                loading={leadUpdate.loading} />
        </div>
    )
}
