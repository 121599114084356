import React from 'react'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
// import { checkPermission as cp } from '../utils/auth'
// import TaskProjectBoardFinish from './TaskProjectBoardFinish'
import TaskCreate from './TaskCreate'
import { usePostRequest } from '../hooks/request'
import { BOARD_TASK_LIST } from '../urls'
// import TaskBoardUpdate from './TaskBoardUpdate'


export default function TaskProjectBoardActions({ board, tasks }) {
    const addTaskList = usePostRequest({ url: BOARD_TASK_LIST.replace('{board_id}', board.id) })

    async function handleAddTasks(data) {
        const { response } = await addTaskList.request({ data: { tasks: data } })
        addTasks(response)
    }

    const [showAddModal, hideAddModal] = useModal(
        <TaskCreate
            tasks={tasks}
            board={board}
            loading={addTaskList.loading}
            onCancel={() => hideAddModal()}
            onAdd={(data) => {
                handleAddTasks(data)
                hideAddModal()
            }}
            onSuccess={() => {
                tasks.request()
                hideAddModal()
            }}
        />,
    )

    function addTasks(data) {
        const results = tasks.response.results.map((task) => {
            if (task.status !== 'todo') return task
            return { ...task, position: task.position + data.length }
        })
        tasks.setResponse({ results: [...results, ...data] })
    }

    return (
        <div className="field has-addons">
            <div className="control">
                <Button
                    icon="ion-md-add"
                    onClick={showAddModal}
                    text="Добавить задания"
                    className="is-link is-outlined" />
            </div>
        </div>
    )
}
