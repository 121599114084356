import { Form, Formik } from 'formik'
import React from 'react'
import { useLoad } from '../hooks/request'
import { EMPLOYEE_LIST } from '../urls'
import Input from './common/Input'
import { required } from '../utils/validators'
import Button from './common/Button'
import Select from './common/Select'

export default function TaskForm({ onSubmit, onCancel, loading, initialValues }) {
    const users = useLoad({ url: EMPLOYEE_LIST })

    return (
        <Formik initialValues={{
            title: '',
            ...initialValues,
            assigned: initialValues ? initialValues.assigned.id : '',
            dueTime: initialValues && initialValues.dueTime ? initialValues.dueTime : '',
            description: initialValues ? initialValues.description : '',
        }} onSubmit={onSubmit}>
            <Form>
                <Select
                    empty
                    name="assigned"
                    label="Масъул ходим"
                    validate={required}
                    optionLabel={(item) => `${item.name}`}
                    options={users.response ? users.response.results : []} />

                <Input
                    name="dueTime"
                    label="Тугаш муддати"
                    type="datetime-local"
                    optional
                    disabled={loading} />
                <Input
                    component="textarea"
                    name="title"
                    label="Топшириқ"
                    placeholder="Вазифа номини киритинг"
                    disabled={loading}
                    validate={required} />


                <br />

                <Button
                    loading={loading}
                    text="Сохранять"
                    type="submit"
                    icon="ion-md-checkmark"
                    disabled={loading}
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    disabled={loading}
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
