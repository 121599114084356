import React from 'react'
import map from 'lodash/map'
import Table from './common/Table'
import { EMPLOYEE_LIST, MEMBERS_LIST } from '../urls'
import ProjectMemberItem from './ProjectMemberItem'
import { checkPermission as cp } from '../utils/auth'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import ProjectMemberCreate from './ProjectMemberCreate'
import { useLoad } from '../hooks/request'


export default function ProjectMember({ project, onCreate, onUpdate, onClose, onBack }) {
    const employees = useLoad({ url: EMPLOYEE_LIST })
    const members = useLoad({ url: MEMBERS_LIST, params: { project: project.id } })
    const employeesList = employees.response ? employees.response.results : []
    const membersList = members.response ? members.response.results : []

    const [showCreateModal, hideCreateModal] = useModal(
        <ProjectMemberCreate
            onBack={() => {
                hideCreateModal()
                onBack()
            }}
            employees={employeesList.filter((employee) => !map(membersList, 'employee').includes(employee.id))}
            onSuccess={() => onCreate()} onCancel={() => hideCreateModal()} project={project} />,
    )

    return (
        <div>
            <span className="is-size-4">Активы</span>

            {cp('project.add_member') ? (
                <Button
                    onClick={showCreateModal}
                    text="Добавить"
                    icon="ion-md-add"
                    className="is-pulled-right is-link is-outlined" />
            ) : null}

            <Table
                loading={members.loading}
                totalCount={members.response ? members.response.count : 0}
                columns={{ employee: 'Сотрудник', role: 'Роль', actions: '' }}
                items={membersList}
                renderItem={(member) => (
                    <ProjectMemberItem
                        onUpdate={onUpdate}
                        key={member.id}
                        member={member}
                        project={project}
                        onBack={onBack}
                        employees={employeesList}
                        reload={members.request} />
                )} />

            <Button
                onClick={onClose}
                icon="ion-md-close"
                text="Закрыть"
                className="is-danger" />
        </div>
    )
}
