import cn from 'classnames'
import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Draggable } from 'react-beautiful-dnd'
import { useModal } from '../hooks/modal'
import LeadActions from './LeadActions'
import LeadUpdate from './LeadUpdate'
import LeadInfo from './LeadInfo'
import ProjectMember from './ProjectMember'

export default function LeadBoardCard({ lead, index, onDelete, onUpdate, status }) {
    const [showUpdateModal, hideUpdateModal] = useModal(
        <LeadUpdate
            lead={lead}
            onCancel={() => hideUpdateModal()}
            status={status}
            onSuccess={(values) => {
                onUpdate(lead.id, values)
                hideUpdateModal()
            }} />,
    )

    const [showMemberModal, hideMemberModal] = useModal(
        <ProjectMember
            project={lead.project}
            onClose={() => hideMemberModal()}
            onCreate={() => showMemberModal()}
            onBack={() => showMemberModal()}
            onUpdate={() => showMemberModal()} />,
    )

    const [showInfoModal, hideInfoModal] = useModal(
        <LeadInfo
            onCancel={() => hideInfoModal()}
            onChange={() => {
                hideInfoModal()
                showUpdateModal()
            }}
            lead={lead} />,
    )

    return (
        <Draggable draggableId={String(lead.id)} index={index}>
            {(provider, snap) => (
                <div
                    onClick={showInfoModal}
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                    {...provider.dragHandleProps}
                    className={cn('box', css(styles.card, snap.isDragging && styles.active))}
                    style={provider.draggableProps.style}>
                    <p className={css(styles.nameLead)}>{lead.name}</p>

                    <LeadActions
                        lead={lead}
                        onClickUpdate={showUpdateModal}
                        className={css(styles.actions)}
                        showMemberModal={showMemberModal}
                        onUpdate={onUpdate}
                        onDelete={onDelete} />
                </div>
            )}
        </Draggable>
    )
}

const styles = StyleSheet.create({
    card: {
        userSelect: 'none',
        marginBottom: '0.5rem',
        borderRadius: '3px',
    },
    nameLead: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto',
        '@media (max-width: 769px)': {
            maxWidth: '30rem',
        },
    },
    actions: {
        marginTop: '-2rem',
    },
    active: {
        // drugging styles goes here
    },
})
