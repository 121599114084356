import { Form, Formik } from 'formik'
import React from 'react'
import Input from './componentsFinance/common/Input'
import Button from './componentsFinance/common/Button'

export default function ManthlyIncomeTaxForm({ onSubmit, onCancel, loading, initialValues }) {
    return (
        <Formik initialValues={{ date: '', ...initialValues }} onSubmit={onSubmit}>
            <Form>
                <Input
                    type="date"
                    name="date"
                    label="Название месяца"
                    disabled={loading}
                    placeholder="Январь 2021" />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
