import React from 'react'
import { usePostRequest } from '../hooks/request'
import { MANTHLY_DUE_ITEM_LIST } from '../urls'
import ManthlyDueItemForm from './ManthlyDueItemForm'

export default function ManthlyDueItemCreate({ onCancel, onSuccess, finance, dueDate }) {
    const monthCreate = usePostRequest({ url: MANTHLY_DUE_ITEM_LIST.replace('{dueDateId}', finance.id) })

    async function onSubmit(data) {
        const { success } = await monthCreate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <ManthlyDueItemForm
                finance={finance}
                dueDate={dueDate}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={monthCreate.loading} />
        </div>
    )
}
