import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Input from "./componentsFinance/common/Input";
import Button from "./componentsFinance/common/Button";
import Select from "./componentsFinance/common/Select";
import { financeTypes, phoneOwnerOptions } from "../utils/position";
import { number } from "../utils/validators";
import { domain } from "../utils/request";
import MultiSelect from "./common/MultiSelect";
import { useGetRequest, useLoad } from "../hooks/request";
import { DUE_LIST, MANTHLY_DUE_LIST, TAX } from "../urls";
import { Radio, RadioGroup } from "./common/Radio";

export default function FinanceForm({ onSubmit, onCancel, loading, initialValues, setMonthType, monthType }) {
  const options = Object.entries(financeTypes).map(([value, name]) => ({ id: value, name }));

  const [value, setValue] = useState(null);
  const [hasRequested, setHasRequested] = useState(false);

  const tax = useGetRequest({ url: TAX, params: { tax: value } });

  useEffect(() => {
    if (value && value.length === 9 && !hasRequested) {
      try {
        tax.request();
        setHasRequested(true);
      } catch (error) {
        console.error("Error fetching tax data:", error);
      }
    }
  }, [value, hasRequested, tax]);

  const taxItem = tax.response ? tax.response.results : {};

  const due_list_monthly = useLoad({ url: MANTHLY_DUE_LIST }, []);
  const due_list = useLoad({ url: DUE_LIST }, []);
  const monthOptions = [
    { id: "manthly", name: "Ойлик" },
    { id: "zero", name: "Ноллик " },
  ];

  const due_list_1 = due_list.response ? due_list?.response?.results : [];
  const due_list_2 = due_list_monthly.response ? due_list_monthly?.response?.results : [];

  const all_due_list = [...due_list_1.map((item) => ({ value: item.id, label: item.name })), ...due_list_2.map((item) => ({ value: item.id, label: item.name }))];

  const [second, setSecond] = useState(null);
  const [first, setFirst] = useState(null);

  async function onSuccess(data) {
    const newData = new FormData();
    if (second) newData.append("second", second, second.name);
    if (first) newData.append("first", first, first.name);

    newData.append("name", data.name);
    newData.append("stir", data.stir);
    newData.append("directorName", data.directorName);
    newData.append("directorStir", data.directorStir);
    newData.append("bank", data.bank);
    newData.append("accountNumber", data.accountNumber);
    // newData.append("phoneNumber", data.phoneNumber);
    newData.append("clientPhoneNumber", data.clientPhoneNumber);
    newData.append("mfo", data.mfo);
    newData.append("status", data.status);
    newData.append("login", data.login);
    newData.append("taxDate", data.taxDate);
    newData.append("contractSum", data.contractSum);

    if (data.archivedDate) {
      newData.append("archivedDate", data.archivedDate);
    }
    if (data.dues) {
      data.dues.map((i) => {
        newData.append("dues", i.value);
      });
    }

    if (data.type) newData.append("type", data.type);
    newData.append("selectDate", data.selectDate);
    onSubmit(newData);
  }

  const [rawValue, setRawValue] = useState(String(initialValues?.contractSum || "")); // Ensure rawValue is a string

  const [optionDues, setOptionDues] = useState([]);
  const dues = useGetRequest({
    url: initialValues && initialValues.types === "monthly_types" ? MANTHLY_DUE_LIST : initialValues && initialValues.types === "zero_types" ? DUE_LIST : monthType === "manthly" ? DUE_LIST : MANTHLY_DUE_LIST, // Default to DUE_LIST in other cases
  });

  // This effect will run whenever initialValues or monthType is updated
  useEffect(() => {
    if (dues.request) {
      // Trigger the request when initialValues or monthType changes
      dues.request();
    }
  }, [initialValues, monthType]); // Re-run when initialValues or monthType changes

  // This effect will update optionDues when dues.response changes
  useEffect(() => {
    const duesItem = dues.response ? dues.response.results : [];

    // Mapping the duesItem into the optionDues format
    const newOptionDues = Object.entries(duesItem).map(([, { id, name }]) => ({
      value: id,
      name: id,
      label: name,
    }));

    setOptionDues(newOptionDues); // Update state with the new options
  }, [dues.response]); // Re-run when dues.response updates

  // State to manage form values
  const [values, setValues] = useState({
    name: "",
    stir: "",
    directorName: "",
    directorStir: "",
    bank: "",
    accountNumber: "",
    // phoneNumber: "",
    clientPhoneNumber: "",
    mfo: "",
    archivedDate: "",
    status: "active",
    login: "",
    type: "",
    selectDate: "",
    due: [],
    taxDate: "",
    contractSum: "",
    ...initialValues,
    first: initialValues && !isEmpty(initialValues.first) ? initialValues.first : "",
    second: initialValues && isEmpty(initialValues.second) ? initialValues.second : "",
    dues: Object.entries(initialValues && !isEmpty(initialValues.dues) ? initialValues.dues : []).map(([, { id, name }]) => ({
      value: id,
      name: id,
      label: name,
    })),
  });
  useEffect(() => {
    if (!isEmpty(taxItem.company)) {
      setValues((prevValues) => ({
        ...prevValues,
        stir: value,
        name: taxItem?.company?.shortName || "",
        directorName: `${taxItem?.director?.firstName || ""} ${taxItem?.director?.lastName || ""} ${taxItem?.director?.middleName || ""}`,
        // region: `${taxItem?.director?.firstName || ""} ${taxItem?.director?.lastName || ""} ${taxItem?.director?.middleName || ""}`
      }));
    }
  }, [taxItem.company]);

  return (
    <Formik enableReinitialize={true} initialValues={values} onSubmit={onSuccess}>
      {({ setFieldValue, values }) => {
        console.log(values?.contractSum, "nega nea");
        return (
          <Form>
            {initialValues && initialValues.types === "monthly_types" ? <span className="is-link is-light tag is-medium mb-3">Ноллик Мижоз</span> : null}
            {initialValues && initialValues.types === "zero_types" ? <span className="is-link is-light tag is-medium mb-3">Ойлик Мижоз</span> : null}
            <div className="columns">
              <div className="column is-6">
                <Input name="name" label="Корхона номи" disabled={loading} placeholder="Корхона номи" />
              </div>
              <div className="column is-6">
                <Input
                  name="stir"
                  label="Корхона СТИР"
                  disabled={loading}
                  placeholder="Корхона СТИР"
                  type="number"
                  onChange={(e) => {
                    setFieldValue("stir", e.target.value);
                    setValue(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Input name="directorName" label="Корхона рахбари" disabled={loading} placeholder="Корхона рахбари" />
              </div>
              <div className="column">
                <Input type={number} name="directorStir" label="Корхона рахбари СТИР" disabled={loading} placeholder="Корхона рахбари СТИР" />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Input name="bank" label="Банк" disabled={loading} placeholder="Банк" />
              </div>
              <div className="column">
                <Input type={number} name="accountNumber" label="Х/р" disabled={loading} placeholder="Х/р" />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Input name="mfo" label="МФО" disabled={loading} placeholder="МФО" />
              </div>
              <div className="column">
                <Input type="number" name="clientPhoneNumber" label="Корхона телефони" disabled={loading} placeholder="Введите номер телефон" />
              </div>
              {/* <div className="column"> */}
              {/*   <Input name="inspector" label="Солик инспектори" disabled={loading} placeholder="Солик инспектори" /> */}
              {/* </div> */}
            </div>
            <div className="columns">
              {/* <div className="column"> */}
              {/*   <Input type="number" name="phoneNumber" label="Хизмат кўрсатувчи банк оператори телефон" disabled={loading} placeholder="Введите номер телефон" /> */}
              {/* </div> */}
            </div>
            <div className="columns">
              <div className="column">
                <Input name="login" label="Интернет банк логин" disabled={loading} placeholder="Интернет банк логин" />
              </div>
              <div className="column">
                <Select name="type" options={options} label="Солиқ тури" loading={loading} empty />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Input type="date" className="level-left" name="taxDate" label="ҚҚСга ўтган ой" disabled={loading} placeholder="ҚҚСга ўтган ой" />
              </div>
              <div className="column">
                <Input
                    value={String(rawValue).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} // Convert rawValue to string before formatting
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/\s/g, ""); // Remove spaces while editing
                      if (/^\d*$/.test(inputValue)) { // Only allow numeric input
                        setRawValue(inputValue); // Store the raw value without formatting
                        setFieldValue("contractSum", inputValue); // Update form field with raw value
                      }
                    }}
                    type="text"
                    className="level-right"
                    name="contractSum"
                    label="Сумма контракта"
                    disabled={loading}
                    placeholder="0"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column mt-1">
                <Input type="date" className="level-right" name="selectDate" label="Корхона қўшилаётган ой йили" />
              </div>
              {!initialValues ? (
                <div className="column" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0" }}>
                  <label htmlFor="typeMonth">Ойлик тури</label>
                  <select
                    onChange={(e) => setMonthType(e?.target?.value)}
                    defaultValue={monthType}
                    name="typeMonth"
                    id="typeMonth"
                    style={{
                      marginTop: "0.25rem",
                      width: "100%",
                      border: "1px solid #D1D5DB",
                      borderRadius: "0.375rem",
                      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                      padding: ".7rem",
                      outline: "none",
                      transition: "border-color 0.2s, box-shadow 0.2s",
                    }}
                  >
                    {monthOptions.map((option) => (
                      <option key={option.id} value={option.id} disabled={loading}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
            <div className="columns">
              <div className="column">
                <MultiSelect label={"Қўшимча Cолиқ Tури"} isMulti name="dues" options={optionDues} classNamePrefix="select" placeholder={"Солиқ Tурини Танлаш"} />
              </div>

            </div>
            <div className="columns">
              <div className="column">
                <label>Статус</label>
                <RadioGroup name={'status'} style={{ display: 'flex', gap: '1rem' }}>
                  {phoneOwnerOptions.map(item => (
                      <Radio key={item.value} value={item.value} label={item.label}/>
                  ))}
                </RadioGroup>
              </div>

              {values.status === 'archive' ? <div className="column">
                <Input name="archivedDate" label="Архивланган сана" type="date"/>
              </div> : null}
            </div>


            <div className="">
              <div className="column">
                Корхонанинг ЭРИ калити <br/>
                <input
                    onChange={(e) => {
                      setFieldValue("imageFile", e.target.value);
                      setFirst(e.target.files[0]);
                    }}
                    disabled={loading}
                    name="second"
                    type="file"
                />
                {initialValues ? (domain + initialValues.first).split("/").pop() : "Файл юкланмаган"}
              </div>
              <br/>
              <div className="column">
                Раҳбарнинг ЭРИ калити <br/>
                <input
                  onChange={(e) => {
                    setFieldValue("imageFile", e.target.value);
                    setSecond(e.target.files[0]);
                  }}
                  disabled={loading}
                  name="second"
                  type="file"
                />
                {initialValues ? (domain + initialValues.second).split("/").pop() : "Файл юкланмаган"}
              </div>
            </div>
            <Button loading={loading} text="Сохранить" type="submit" icon="ion-md-checkmark" className="is-success" /> &nbsp;
            <Button onClick={onCancel} icon="ion-md-close" text="Отмена" className="is-danger" />
          </Form>
        );
      }}
    </Formik>
  );
}
