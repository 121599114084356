import React from 'react'
import { usePutRequest } from '../hooks/request'
import { TRANSACTION_CATEGORY_DETAIL } from '../urls'
import TransactionCategoryForm from './TransactionCategoryForm'

export default function TransactionCategoryUpdate({ onCancel, onUpdate, category }) {
    const url = TRANSACTION_CATEGORY_DETAIL.replace('{id}', category.id)
    const transactionUpdate = usePutRequest({ url })

    async function onSubmit(data, actions) {
        const defaultAmount = data.defaultAmount ? data.defaultAmount : null
        await transactionUpdate.request({ data: { ...data, defaultAmount } })
        actions.resetForm()
        onUpdate()
    }

    return (
        <div>
            <b>Изменить категорию</b><br /><br />

            <TransactionCategoryForm
                initialValues={category}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={transactionUpdate.loading} />
        </div>
    )
}
