import React from 'react'
import { useHistory } from 'react-router-dom'
import { usePostRequest } from '../hooks/request'
import { BOARD_LIST } from '../urls'
import TaskBoardForm from './TaskBoardForm'

export default function TaskBoardCreate({ project, moveTasksFrom, onCancel, onSuccess }) {
    const history = useHistory()
    const boardCreate = usePostRequest({ url: BOARD_LIST })

    async function onSubmit(data, actions) {
        const { success, response } = await boardCreate.request({
            data: { ...data, moveTasksFrom, project: project.id },
        })

        if (success) {
            actions.resetForm()
            onSuccess()
            history.push(`/task/projects/${project.id}/board/${response.id}`)
        }
    }

    return (
        <div>
            <b>Добавить новый спринт</b><br /><br />
            <TaskBoardForm loading={boardCreate.loading} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
    )
}
