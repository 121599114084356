import React, { useEffect } from 'react'
import { usePostRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import { TELEGRAM_INVITATION } from '../urls'
import Loader from './common/Loader'
import Button from './common/Button'


export default function TelegramInvite({ itemId, onCancel, userType }) {
    const telegramInvite = usePostRequest({ url: TELEGRAM_INVITATION.replace('{id}', itemId) })
    const [showMessage] = useMessage()

    useEffect(() => {
        invite()
        // eslint-disable-next-line
    }, [])

    async function invite() {
        const { error } = await telegramInvite.request({ data: { userType } })
        if (error) {
            showMessage(error.data.detail, 'is-danger')
        }
    }

    if (telegramInvite.loading) {
        return <Loader large center />
    }

    return (
        <div>
            {telegramInvite.response ? telegramInvite.response.map((item) => (
                <div key={item.id}>
                    <b>Ссылка для приглашения {item.customer ? item.customer.name : item.employee.name}: </b>

                    <input
                        className="input is-disabled"
                        value={`https://t.me/MondayRobot?start=${item.code}`}
                        onFocus={({ target }) => target.select()}
                        readOnly />

                    <br />
                    <br />
                </div>
            )) : null}

            <Button
                onClick={onCancel}
                icon="ion-md-close"
                text="Закрыт"
                className="is-danger" />
        </div>
    )
}
