import React, { Fragment } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { usePutRequest } from '../hooks/request'
import { BOARD_TASK_MOVE } from '../urls'
import Loader from './common/Loader'
import { changePositionAndColumn } from '../utils/board'
import TaskBoardColumn from './TaskBoardColumn'


export default function TaskBoard({ columns, board, tasks, onPointsUpdate }) {
    const moveBoard = usePutRequest()

    async function move(id, position, status) {
        const url = BOARD_TASK_MOVE.replace('{board_id}', board.id).replace('{id}', id)
        await moveBoard.request({ url, data: { position, status } })
    }

    async function onDragEnd({ source, destination, draggableId: id }) {
        if (!destination) return

        // update status
        const results = changePositionAndColumn(tasks.response.results, id, source, destination)
        tasks.setResponse({ results })
        await move(id, destination.index + 1, destination.droppableId)
    }

    function onUpdate(id, data) {
        const results = tasks.response.results.map((item) => (item.id === id ? { ...item, task: data } : item))
        tasks.setResponse({ ...tasks.response, results })
    }

    async function onDelete(id) {
        const results = tasks.response.results.filter((item) => item.id !== id)
        tasks.setResponse({ count: tasks.response.count - 1, results })
    }

    async function onCompleted(id) {
        await move(id, 1, 'done')
        await tasks.request()
    }

    return (
        <Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="columns">
                    {columns.map((column) => (
                        <div key={column.id} className="column">
                            <TaskBoardColumn
                                columnKey={column.id}
                                tasks={sortBy(filter(
                                    tasks.response ? tasks.response.results : [],
                                    { status: column.id },
                                ), 'position')}
                                column={column}
                                board={board}
                                onPointsUpdate={onPointsUpdate}
                                onCompleted={onCompleted}
                                onDelete={(id) => onDelete(id, column.id)}
                                onUpdate={onUpdate}
                                lineColor={column.color} />
                        </div>
                    ))}
                </div>
            </DragDropContext>

            <Loader show={tasks.loading} center large />

            <div ref={tasks.ref} className="has-text-grey-light is-italic has-text-centered">
                {!tasks.hasMore && !tasks.loading && tasks.length !== 0 ? 'Загрузили все задачи' : ''}
            </div>
        </Fragment>
    )
}
