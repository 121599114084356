import React, { useEffect } from 'react'
import find from 'lodash/find'
import { Formik, Form } from 'formik'
import { useLoad, useGetRequest } from '../hooks/request'
import { TRANSACTION_CATEGORY_LIST, SALES_CHANNEL_LIST, EMPLOYEE_LIST, PROJECT_SIMPLE_LIST, ASSET_LIST } from '../urls'
import { required, validator, maxNumber } from '../utils/validators'
import Button from './common/Button'
import Input from './common/Input'
import Select from './common/Select'
import { transactionTypeOptions } from '../utils/finance'
import { getMonth } from '../utils/date'


export default function TransactionForm({ loading, walletBalance, onSubmit, onCancel, values = {}, type }) {
    const initialValues = {
        amount: '',
        category: '',
        description: '',
        salesChannel: '',
        employee: '',
        project: '',
        type: '',
        asset: '',
        ...values,
    }

    const categories = useLoad({
        params: { type },
        url: TRANSACTION_CATEGORY_LIST,
    })

    const salesChannels = useGetRequest({ url: SALES_CHANNEL_LIST })
    const employee = useGetRequest({ url: EMPLOYEE_LIST })
    const projects = useGetRequest({ url: PROJECT_SIMPLE_LIST })
    const assets = useGetRequest({ url: ASSET_LIST })

    useEffect(() => {
        if (values.salesChannel) salesChannels.request()
        if (values.employee) employee.request()
        if (values.project) projects.request()
        if (values.asset) assets.request()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const marketingCategory = find(
        categories.response ? categories.response.results : [],
        { systemName: 'sales_channel' },
    ) || {}

    const salaryCategory = find(
        categories.response ? categories.response.results : [],
        { systemName: 'salary' },
    ) || {}

    const projectCategory = find(
        categories.response ? categories.response.results : [],
        { systemName: 'project' },
    ) || {}

    const assetCategory = find(
        categories.response ? categories.response.results : [],
        { systemName: 'asset' },
    ) || {}

    function onCategoryChange(categoryId, setFieldValue) {
        // get category by id
        const category = find(categories.response.results, { id: parseInt(categoryId, 10) })
        // autofill form values
        if (marketingCategory.id === Number(categoryId)) salesChannels.request()
        if (salaryCategory.id === Number(categoryId)) employee.request()
        if (projectCategory.id === Number(categoryId)) projects.request()
        if (assetCategory.id === Number(categoryId)) assets.request()
        setFieldValue('category', categoryId)
        if (category.defaultAmount) {
            setFieldValue('amount', category.defaultAmount)
        }
        if (category.descriptionTemplate) {
            setFieldValue('description', category.descriptionTemplate)
        }
    }

    function handleSubmit(data) {
        let amount = Math.abs(data.amount)
        if (type === 'outcome') {
            amount *= -1
        }

        onSubmit({ ...data, amount })
    }

    function onChangeSalesChannel(value, setFieldValue) {
        setFieldValue('salesChannel', value)
        if (value === '') return
        const salesChannel = salesChannels.response ? find(
            salesChannels.response.results,
            { id: Number(value) },
        ) : {}
        setFieldValue('description', `Оплата за рекламу, канал: ${salesChannel.name || ''}.`)
    }

    function onChangeEmployee(value, setFieldValue) {
        setFieldValue('employee', value)
        if (value === '') return
        const pickedEmployee = employee.response ? find(
            employee.response.results,
            { id: Number(value) },
        ) : {}
        setFieldValue('description', `Зарплата сотрудника ${pickedEmployee.name}.`)
    }

    function onChangeAsset(value, setFieldValue) {
        setFieldValue('asset', value)
        if (value === '') return

        const pickedAsset = assets.response ? find(
            assets.response.results,
            { id: Number(value) },
        ) : {}

        setFieldValue('description', `Расходы на актив "${pickedAsset.name}".`)
        setFieldValue('amount', pickedAsset.price)
    }

    function onChangeProject(value, setFieldValue) {
        setFieldValue('project', value)
        if (value === '') return

        const pickedProject = projects.response ? find(
            projects.response.results,
            { id: Number(value) },
        ) : {}

        setFieldValue(
            'description',
            `Оплата за услуги разработки программы "${pickedProject.lead.name}" за ${getMonth(new Date(), true)}`,
        )
        setFieldValue('amount', pickedProject.monthlyPrice)
    }

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {({ setFieldValue, values: { category } }) => (
                <Form>
                    <div className="field">
                        <div className="field-body">
                            <Select
                                name="category"
                                options={categories.response ? categories.response.results : []}
                                label="Категория"
                                help="Вы можете добавить новую категорию в настройках"
                                empty
                                loading={categories.loading}
                                onChange={({ target }) => onCategoryChange(target.value, setFieldValue)}
                                validate={required} />

                            {marketingCategory && marketingCategory.id === Number(category) ? (
                                <Select
                                    empty
                                    name="salesChannel"
                                    label="Канал продаж"
                                    loading={salesChannels.loading}
                                    options={salesChannels.response ? salesChannels.response.results : []}
                                    help="Канал продаж можно добавить в настройках"
                                    onChange={({ target }) => onChangeSalesChannel(target.value, setFieldValue)}
                                    validate={required} />
                            ) : null}

                            {salaryCategory && salaryCategory.id === Number(category) ? (
                                <Select
                                    empty
                                    name="employee"
                                    label="Сотрудник"
                                    loading={employee.loading}
                                    options={employee.response ? employee.response.results : []}
                                    help="Вы можете добавить нового сотрудника в меню Персонал"
                                    onChange={({ target }) => onChangeEmployee(target.value, setFieldValue)}
                                    validate={required} />
                            ) : null}

                            {projectCategory && projectCategory.id === Number(category) ? (
                                <Select
                                    empty
                                    name="project"
                                    label="Проект"
                                    loading={projects.loading}
                                    options={projects.response ? projects.response.results : []}
                                    optionLabel={(item) => item.lead.name}
                                    help="Вы можете добавить нового проекта в меню Проекты"
                                    onChange={({ target }) => onChangeProject(target.value, setFieldValue)}
                                    validate={required} />
                            ) : null}

                            {assetCategory && assetCategory.id === Number(category) ? (
                                <Select
                                    empty
                                    name="asset"
                                    label="Актив"
                                    loading={assets.loading}
                                    options={assets.response ? assets.response.results : []}
                                    help="Вы можете добавить нового актив в меню Финансы>Актив"
                                    onChange={({ target }) => onChangeAsset(target.value, setFieldValue)}
                                    validate={required} />
                            ) : null}
                        </div>
                    </div>

                    <div className="field is-horisontal">
                        <div className="field-body">
                            <Input
                                name="amount"
                                type="number"
                                label="Сумма"
                                validate={validator(required, maxNumber(walletBalance))} />

                            <Select
                                name="type"
                                options={transactionTypeOptions}
                                label="Тип"
                                empty
                                validate={required} />
                        </div>
                    </div>

                    <Input name="description" component="textarea" label="Описание" validate={required} />

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
