import React from 'react'
import Select from 'react-select'
import { Field } from 'formik'
import ValidationErrorMessage from './ValidationErrorMessage'

export default function MultiSelect({
    name,
    className,
    options,
    label,
    validate,
    help,
    optional,
    onChange,
    placeholder,
    ...attributes
}) {
    return (
        <div className="field">
            <div className="control">
                {label ? (
                    <label style={{
                        // paddingLeft: 10,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '16px',
                        paddingBottom: 3,
                    }}
                    className="ml-[10px] text-lightModeTextPrimary dark:text-darkModeTextPrimary font-gilroyMd text-base pb-1"
                    htmlFor={name}>
                        {label} &nbsp;
                        {optional ? <span className="form-hint">не обязательно</span> : null}

                        {help ? (
                            <p className="help has-text-grey-light">{help}</p>
                        ) : null}
                    </label>
                ) : null}

                <Field name={name} validate={validate}>
                    {({
						  form,
						  field,
					  }) => (
    <Select
                            isMulti
                            onBlur={form.setFieldTouched}
                            value={field.value}
                            options={options}
                            placeholder={placeholder}
                            maxMenuHeight={150}
                            {...attributes}
                            onChange={(values) => {
                                if (typeof onChange === 'function') {
                                    onChange(values)
                                    return
                                }
                                form.setFieldValue(name, values)
                            }} />
                    )}
                </Field>

                <ValidationErrorMessage name={name} />
            </div>
        </div>
    )
}
