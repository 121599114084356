export function format(number) {
  return typeof number === "number" ? number.toLocaleString("fr") : number;
}

export function integersOnly(value) {
  return String(value).replace(/[^0-9]/gim, "");
}

export function formatNumberWithSpaces(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
