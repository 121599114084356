/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Formik, Form } from 'formik'
import { phone, required, validator } from '../utils/validators'
import { useLoad } from '../hooks/request'
import { CUSTOMER_LIST, SALES_CHANNEL_LIST } from '../urls'
import Button from './common/Button'
import Input from './common/Input'
import Select from './common/Select'
import { Radio, RadioGroup } from './common/Radio'


export default function LeadForm({ loading, onSubmit, onCancel, values }) {
    const description = values && values.description ? values.description : ''
    const customers = useLoad({ url: CUSTOMER_LIST })

    const initialValues = {
        name: '',
        salesChannel: '',
        customerPick: 'create',
        customerName: '',
        customerPhone: '',
        ...values,
        description,
    }

    const salesChannel = useLoad({ url: SALES_CHANNEL_LIST })

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {({ values: data }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            <Input name="name" label="Имя" validate={required} autoFocus autoComplete="off" />
                        </div>

                        <div className="column">
                            <Select
                                name="salesChannel"
                                options={salesChannel.response ? salesChannel.response.results : []}
                                label="Канал продаж"
                                help="Добавить канал продаж можно в настройках"
                                loading={salesChannel.loading}
                                validate={required} />
                        </div>
                    </div>

                    <Input name="description" component="textarea" label="Примечание" optional />

                    {!values ? (
                        <div className="mb-4">
                            <p className="mb-3"><b>Клиент:</b></p>

                            <RadioGroup name="customerPick">
                                <Radio label="Создать новый" value="create" /> &nbsp; &nbsp;
                                <Radio label="Выбрать существующий" value="pick" />
                            </RadioGroup>

                            {data.customerPick === 'create' ? (
                                <div className="field is-horizontal">
                                    <div className="field-body">
                                        <Input name="customerName" label="Имя" validate={required} />
                                        <Input name="customerPhone" type="number" label="Номер телефона"
                                            validate={validator(required, phone)} />
                                    </div>
                                </div>
                            ) : null}

                            {data.customerPick === 'pick' ? (
                                <Select
                                    name="customer"
                                    options={customers.response ? customers.response.results : []}
                                    loading={salesChannel.loading}
                                    validate={required} />
                            ) : null}
                        </div>
                    ) : (
                        <Select
                            label="Клиент"
                            name="customer"
                            options={customers.response ? customers.response.results : []}
                            loading={salesChannel.loading}
                            validate={required} />
                    )}

                    <Button
                        loading={loading}
                        text="Сохранить"
                        type="submit"
                        icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;

                    <Button
                        onClick={onCancel}
                        icon="ion-md-close"
                        text="Отмена"
                        className="is-danger" />
                </Form>
            )}
        </Formik>
    )
}
