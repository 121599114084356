import React from 'react'
import Layout from '../components/Layout'
import WalletItem from '../components/WalletItem'
import { useLoad } from '../hooks/request'
import { TRANSACTION_TYPE_LIST, WALLET_LIST } from '../urls'
import Loader from '../components/common/Loader'
import TransactionTypeItem from '../components/TransactionTypeItem'
import { transactionTypes } from '../utils/finance'
import FinanceTabs from '../components/FinanceTabs'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'

export default function Salary() {
    const wallets = useLoad({ url: WALLET_LIST })
    const transactionType = useLoad({ url: TRANSACTION_TYPE_LIST })

    if (!cp('finance.view_wallet')) {
        return <PermissionDenied />
    }

    return (
        <Layout className="has-background-light">
            {/*    <FinanceTabs />
            <span className="is-size-4">По формам</span><br /><br />

            <div className="columns is-multiline">
                {Object.keys(transactionType.response ? transactionType.response : {}).map((key) => (
                    <TransactionTypeItem
                        key={key}
                        type={transactionTypes[key]}
                        item={transactionType.response[key]} />
                ))}

                <Loader show={transactionType.loading} large center />
            </div>

            <span className="is-size-4">Счета</span><br /><br />

            <div className="columns is-multiline">
                { !wallets.loading && wallets.response ? wallets.response.results.map((wallet) => (
                    <WalletItem key={wallet.id} wallet={wallet} />
                )) : null}

                <Loader show={wallets.loading} large center />
            </div> */}
        </Layout>
    )
}
