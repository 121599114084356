/* eslint-disable jsx-a11y/anchor-is-valid */
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { useState } from 'react'
import { useDeleteRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import { checkPermission as cp } from '../utils/auth'
import { LEAD_DETAIL } from '../urls'
import { useModal } from '../hooks/modal'
import TelegramInvite from './TelegramInvite'

export default function LeadActions({
    lead,
    onDelete,
    onClickUpdate,
    className,
    showMemberModal,
}) {
    const [showDropdown, setShowDropdown] = useState(false)
    const url = LEAD_DETAIL.replace('{id}', lead.id)
    const leadDelete = useDeleteRequest({ url })
    const [showMessage] = useMessage()

    async function deleteLead() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await leadDelete.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }

        onDelete(lead.id)
    }

    return (
        <div onMouseLeave={() => setShowDropdown(false)}
            onClick={(event) => event.stopPropagation()}
            className={cn(
                'dropdown is-right is-pulled-right',
                { 'is-active': showDropdown },
                css(styles.more),
                className,
            )}>

            <div className="dropdown-trigger">
                <button className={cn('button is-white', css(styles.button))}
                    onClick={() => setShowDropdown(!showDropdown)}>

                    <i className="icon ion-md-more" />
                </button>
            </div>

            <div className="dropdown-menu">
                <div className="dropdown-content">
                    {cp('project.change_lead') ? (
                        <a className="dropdown-item" onClick={onClickUpdate}>
                            <i className="icon ion-md-create" />
                            Изменить
                        </a>
                    ) : null}

                    {cp('project.delete_lead') ? (
                        <a className="dropdown-item" onClick={deleteLead}>
                            <i className="icon ion-md-trash" />
                            Удалить
                        </a>
                    ) : null}

                    {cp('project.view_member') && lead.project ? (
                        <a className="dropdown-item" onClick={showMemberModal}>
                            <i className="icon ion-md-people" />
                            Команда
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: 'transparent',
        ':hover': { backgroundColor: '#fcfcfc' },
    },
    more: {
        bottom: '0',
        left: '1rem',
    },
})
