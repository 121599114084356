import React from 'react'
import { CUSTOMER_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import CustomerForm from './CustomerForm'

export default function CustomerUpdate({ onCancel, onSuccess, customer }) {
    const url = CUSTOMER_DETAIL.replace('{id}', customer.id)
    const customerUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        await customerUpdate.request({ data })

        onSuccess()
    }

    return (
        <div>
            <b>Изменить Клиент</b><br /><br />

            <CustomerForm
                initialValues={customer}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={customerUpdate.loading} />
        </div>
    )
}
