import cn from 'classnames';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';

import { getMonth } from '../utils/date';
import Layout from '../components/Layout';
import Table from '../components/common/Table';
import Button from '../components/common/Button';
import { useQueryParams } from '../hooks/queryString';
import { useLoad, useSortTableData } from '../hooks/request';
import QqcQuarterlyItemItem from '../components/QqcQuarterlyItemItem';
import { FINANCE_LIST, QQC_QUARTERLY_DETAIL, QQC_QUARTERLY_ITEM_LIST } from '../urls';

export default function UploadQqcQuarterly() {
	const params = useQueryParams();
	const { qqcQuarterlyId } = useParams();
	const [page, setPage] = useState(1);
	const finances = useLoad({ url: FINANCE_LIST, params: { type: 'qqc_quarterly', qqcQuarterly: qqcQuarterlyId, ...params } }, [page]);

	const reportDetail = useLoad({ url: QQC_QUARTERLY_DETAIL.replace('{id}', qqcQuarterlyId) });
	const reportItems = useLoad({ url: QQC_QUARTERLY_ITEM_LIST.replace('{qqcQuarterlyId}', qqcQuarterlyId) });
	const { items, requestSort, sortConfig } = useSortTableData(finances.response ? finances.response.results : []);
	const getClassNamesFor = name => {
		if (!sortConfig) return;
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	return (
		<Layout>
			<div>
				<div className='level'>
					<div className='level-left'>
						<span className='title is-capitalized'>{reportDetail.response ? getMonth(reportDetail.response.date) : ''}</span>
					</div>
					<div className='level-right'>
						<div className='is-pulled-right is-success is-outlined mr-3'>
							<ExcelFile element={<Button icon='ion-md-download' text='Excel' className='is-pulled-right is-success is-outlined mr-3' />} filename={reportDetail.response ? getMonth(reportDetail.response.date) : ''}>
								<ExcelSheet name='Excel' data={finances.response ? finances.response.results : []}>
									<ExcelColumn label='Корхона номи' value='name' />
									<ExcelColumn label='Юридик стир' value='stir' />
									<ExcelColumn label='Жисмоний стир' value='directorStir' />
								</ExcelSheet>
							</ExcelFile>
						</div>
						<Button icon='ion-md-search' text='Корхона номи' className={cn(getClassNamesFor('name'), 'is-pulled-right is-success is-outlined mr-3')} onClick={() => requestSort('name')} />
						<Button icon='ion-md-search' text='Юридик стир' onClick={() => requestSort('stir')} className={cn(getClassNamesFor('stir'), 'is-pulled-right is-success is-outlined mr-3')} />
						<Button icon='ion-md-search' text='Жисмоний стир' onClick={() => requestSort('directorStir')} className={cn(getClassNamesFor('directorStir'), 'is-pulled-right is-success is-outlined')} />
					</div>
				</div>

				<Table
					loading={finances.loading}
					items={items}
					columns={{ id: '№', name: 'Мижоз корхона номи', stir: 'Юридик стир', directorStir: 'Жисмоний стир', uploadRotation: 'ҚҚС квартал ', commentRotation: 'Изоҳ', tax: 'Солиқ     ', inps: 'ИНПС', ad: '' }}
					totalCount={finances.response ? finances.response.count : 0}
					pageSize={10}
					activePage={page}
					onPageChange={setPage}
					renderItem={(item, index) => <QqcQuarterlyItemItem index={index} key={item.id} onUpdate={finances.request} onDelete={finances.request} request={reportItems.request} reportItems={reportItems.response ? reportItems.response.results : []} qqcQuarterlyId={qqcQuarterlyId} finance={item} />}
				/>
			</div>
		</Layout>
	);
}
