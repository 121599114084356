import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { useLoad } from '../hooks/request'
import { DUE_LIST } from '../urls'

export default function ZeroTabs() {
    const { path } = useRouteMatch()
    const due = useLoad({ url: DUE_LIST })
    const dueItem = due.response ? due.response.results : []

    return (
        <div className="tabs">
            <ul>
                <li className={cn({ 'is-active': path.startsWith('/zero/rotation') })}>
                    <NavLink to="/zero/rotation">Айланма</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/zero/income-tax') })}>
                    <NavLink to="/zero/income-tax">ЖШДС</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/zero/qqc-monthly') })}>
                    <NavLink to="/zero/qqc-monthly">ҚҚС Ойлик</NavLink>
                </li>

                <li className={cn({ 'is-active': path.startsWith('/zero/income-quarterly') })}>
                    <NavLink to="/zero/income-quarterly">Фойда солиғи</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/zero/result') })}>
                    <NavLink to="/zero/result">Молиявий Натижа</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/zero/balance') })}>
                    <NavLink to="/zero/balance">Молиявий Баланс</NavLink>
                </li>
                {dueItem.map((item) => (
                    <li className={cn({ 'is-active': path.startsWith(`/zero/${item.id}`) })}>
                        <NavLink to={`/zero/${item.id}`}>{item.name}</NavLink>
                    </li>
                ))}

            </ul>
        </div>
    )
}
