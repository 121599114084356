import React from 'react'
import { usePostRequest } from '../hooks/request'
import { RESULT_LIST } from '../urls'
import ResultForm from './ResultForm'

export default function ResultCreate({ onCancel, onSuccess }) {
    const rotationCreate = usePostRequest({ url: RESULT_LIST })

    async function onSubmit(data, actions) {
        const { success } = await rotationCreate.request({ data })

        if (success) {
            onSuccess()
            actions.resetForm()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <ResultForm onSubmit={onSubmit} onCancel={onCancel} loading={rotationCreate.loading} />
        </div>
    )
}
