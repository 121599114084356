import React, { useState } from "react";
import FinanceForm from "./FinanceForm";
import { FINANCE_DETAIL, MANTHLY_FINANCE_DETAIL } from "../urls";
import { usePutRequest } from "../hooks/request";
import ServerError from "./componentsFinance/common/ServerError";

export default function FinanceUpdate({ onCancel, onUpdate, finance }) {
  const [monthType, setMonthType] = useState(finance?.month_type === "manthly" ? "manthly" : "zero");
  const url = monthType === "manthly" ? FINANCE_DETAIL.replace("{id}", finance.id) : MANTHLY_FINANCE_DETAIL.replace("{id}", finance.id);
  const financeUpdate = usePutRequest({ url });
  async function onSubmit(data) {
    const { success } = await financeUpdate.request({ data });

    if (success) {
      onUpdate();
    }
  }

  return (
    <div>
      <b>Изменить данные о клиенты</b>
      <br />
      <br />
      <ServerError error={financeUpdate.error} />
      <FinanceForm initialValues={finance} monthType={monthType} onSubmit={onSubmit} onCancel={onCancel} loading={financeUpdate.loading} setMonthType={setMonthType} />
    </div>
  );
}
