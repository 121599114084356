import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { usePostRequest } from '../hooks/request'
import { INVITATION } from '../urls'
import Input from './common/Input'
import { required } from '../utils/validators'
import ServerError from './common/ServerError'
import { useMessage } from '../hooks/message'


export default function EmployeeInvite({ employee, onSuccess, onCancel }) {
    const invite = usePostRequest({ url: INVITATION.replace('{id}', employee.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data, actions) {
        const { success } = await invite.request({
            data: { ...data, userType: 'employee', employee: employee.id },
        })

        if (success) {
            actions.resetForm()
            showMessage('Мы отправили приглашение по электронной почте', 'is-success')
            onSuccess()
        }
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={{ email: '' }}>
            <Form>
                <b>Пригласить сотрудника в качестве пользователя</b><br /><br />
                <ServerError error={invite.error} />
                <Input
                    name="email"
                    type="text"
                    placeholder="Электронная почта"
                    validate={required} />

                <Button
                    loading={invite.loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Отмена"
                    className="is-danger" />
            </Form>
        </Formik>
    )
}
