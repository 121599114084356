/* eslint-disable no-nested-ternary */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { EMPLOYEE_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { checkPermission as cp } from '../utils/auth'
import { format } from '../utils/number'
import { useMessage } from '../hooks/message'
import Loader from './common/Loader'
import EmployeeUpdate from './EmployeeUpdate'
import EmployeePositions from './EmployeePositions'

export default function EmployeeItem({ item, onDelete, onUpdate }) {
    const employeeDelete = useDeleteRequest({ url: EMPLOYEE_DETAIL.replace('{id}', item.id) })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <EmployeeUpdate employee={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteEmployee() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await employeeDelete.request()
        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    return (
        <tr>
            <td>
                <NavLink to={`/staff/employee/${item.id}`} className="has-text-dark">
                    {item.name}
                </NavLink>
            </td>

            <td>
                {item.birthday
                    ? new Date(item.birthday).toLocaleDateString()
                    : <span className="is-italic has-text-grey">не указан</span>}
            </td>

            <td>
                {format(item.salary)} сум
            </td>

            <td>
                <EmployeePositions positions={item.positions} />
            </td>

            <td className="has-text-right">
                {cp('staff.delete_employee') ? (
                    !employeeDelete.loading ? (
                        <i onClick={() => deleteEmployee()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('staff.change_employee') ? (
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                ) : null}
            </td>
        </tr>
    )
}
