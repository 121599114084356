import React from 'react'
import { MANTHLY_DUE_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { getDateTime } from '../utils/date'
import Loader from './common/Loader'
import ManthlyDueUpdate from './ManthlyDueUpdate'

export default function ManthlyDueItem({ item, brand, index, onUpdate }) {
    const brandRemove = useDeleteRequest({ url: MANTHLY_DUE_DETAIL.replace('{id}', item.id) })
    const [showUpdateModal, setShowUpdateModal] = useModal(
        <ManthlyDueUpdate
            item={item}
            onUpdate={onUpdate}
            onCancel={() => setShowUpdateModal()}
        />,
    )

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await brandRemove.request()
            brand.request()
        }
    }

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                {item.name}
            </td>

            <td>
                {getDateTime(item.createdAt)}
            </td>

            <td>
                <div className="is-pulled-right">
                    {!brandRemove.loading ? (
                        <i onClick={onDelete} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />}

                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />

                </div>
            </td>
        </tr>
    )
}
