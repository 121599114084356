import React from 'react'
import { usePostRequest } from '../hooks/request'
import { MANTHLY_INCOME_QUARTERLY_ITEM_LIST } from '../urls'
import IncomeQuarterlyItemForm from './IncomeQuarterlyItemForm'

export default function ManthlyIncomeQuarterlyItemCreate({ onCancel, onSuccess, finance, incomeQuarterlyId }) {
    const monthCreate = usePostRequest({ url: MANTHLY_INCOME_QUARTERLY_ITEM_LIST.replace('{incomeQuarterlyId}', finance.id) })

    async function onSubmit(data) {
        const { success } = await monthCreate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <IncomeQuarterlyItemForm
                finance={finance}
                incomeQuarterlyId={incomeQuarterlyId}
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={monthCreate.loading} />
        </div>
    )
}
