import React, { Fragment } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import LeadBoardCard from './LeadBoardCard'
import LeadCreate from './LeadCreate'
import { useModal } from '../hooks/modal'

export default function LeadBoardColumn({
    columnKey,
    column,
    leads,
    onDelete,
    onUpdate,
    onCreate,
    leadsAll,
    lineColor,
}) {
    const [showCreateModal, hideCreateModal] = useModal(
        <LeadCreate
            status={column}
            onSuccess={(data) => {
                onCreate({
                    count: leadsAll.response.count + 1,
                    results: [data, ...leadsAll.response.results],
                })

                hideCreateModal()
            }}
            onCancel={() => hideCreateModal()} />,
    )

    return (
        <Fragment>
            <div className={css(styles.header)}>
                <span className={css(styles.headerTitle)}>
                    {column.title} &nbsp;
                    <span className={css(styles.count)}>({column.leadsCount || 0})</span>
                </span>

                <hr style={{ background: lineColor }} className={css(styles.line)} />
            </div>

            <div onClick={showCreateModal} className={cn('box has-text-success', css(styles.createButton))}>
                <i className="icon ion-md-add" />
                Добавить
            </div>

            <Droppable droppableId={String(columnKey)}>
                {(provider, snapshot) => (
                    <div ref={provider.innerRef} className={css(snapshot.isDraggingOver && styles.active)}>
                        {leads.map((lead, index) => (
                            <LeadBoardCard
                                status={column}
                                key={lead.id}
                                onDelete={onDelete}
                                lead={lead}
                                index={index}
                                onUpdate={onUpdate} />
                        ))}

                        {provider.placeholder}
                    </div>
                )}
            </Droppable>
        </Fragment>
    )
}

const styles = StyleSheet.create({
    createButton: {
        marginBottom: '0.5rem',
        borderRadius: '3px',
        cursor: 'pointer',
        padding: '0.7rem',
        ':hover': {
            background: '#fbf9f9',
        },
    },
    active: {
        background: '#e8e8e8',
        borderRadius: '3px',
    },
    line: {
        margin: '0.5rem 0 1rem',
    },
    count: {
        color: '#989797',
    },
    header: {
        background: 'whitesmoke',
        margin: '0 -0.6rem',
        padding: '0 0.6rem',
        zIndex: 1,
        position: 'sticky',
        top: '0',
    },
    headerTitle: {
        marginLeft: '0.4rem',
    },
})
