import React from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile'
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet'
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn'
import { useLoad, useSortTableData } from '../hooks/request'
import { MANTHLY_FINANCE_LIST, MANTHLY_INCOME_QUARTERLY_DETAIL, MANTHLY_INCOME_QUARTERLY_ITEM_LIST } from '../urls'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import Layout from '../components/Layout'
import ManthlyIncomeQuarterlyItemItem from '../components/ManthlyIncomeQuarterlyItemItem'
import { getMonth } from '../utils/date'
import { useQueryParams } from '../hooks/queryString'

export default function ManthlyUploadIncomeQuarterly() {
    const params = useQueryParams()
    const { incomeQuarterlyId } = useParams()
    const quarterly = useLoad({ url: MANTHLY_FINANCE_LIST, params: { type: 'qqc_quarterly', ...params } })
    const monthly = useLoad({
        url: MANTHLY_FINANCE_LIST,
        params: { type: 'qqc_monthly', incomeQuarterly: incomeQuarterlyId },
    })
    const finances = [...(quarterly.response ? quarterly.response.results : []), ...(monthly.response ? monthly.response.results : [])]
    const reportDetail = useLoad({ url: MANTHLY_INCOME_QUARTERLY_DETAIL.replace('{id}', incomeQuarterlyId) })
    const reportItems = useLoad({ url: MANTHLY_INCOME_QUARTERLY_ITEM_LIST.replace('{incomeQuarterlyId}', incomeQuarterlyId) })
    const { items, requestSort, sortConfig } = useSortTableData(finances)
    const getClassNamesFor = (name) => {
        if (!sortConfig) return
        return sortConfig.key === name ? sortConfig.direction : undefined
    }

    return (
        <Layout>
            <div className="level">
                <div className="level-left">
                    <span className="title is-capitalized">
                        {reportDetail.response ? reportDetail.response.quarter : ''} - {reportDetail.response ? getMonth(reportDetail.response.date) : ''}
                    </span>
                </div>
                <div className="level-right">
                    <div className="is-pulled-right is-success is-outlined mr-3">
                        <ExcelFile element={(
                            <Button icon="ion-md-download" text="Excel"
                                className="is-pulled-right is-success is-outlined mr-3" />
                        )}
                        filename={reportDetail.response ? getMonth(reportDetail.response.date) : ''}>
                            <ExcelSheet name="Excel" data={finances}>
                                <ExcelColumn label="Корхона номи" value="name" />
                                <ExcelColumn label="Юридик стир" value="stir" />
                                <ExcelColumn label="Жисмоний стир" value="directorStir" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                    <Button icon="ion-md-search" text="Корхона номи"
                        className={cn(getClassNamesFor('name'), 'is-pulled-right is-success is-outlined mr-3')}
                        onClick={() => requestSort('name')} />
                    <Button icon="ion-md-search" text="Юридик стир" onClick={() => requestSort('stir')}
                        className={cn(getClassNamesFor('stir'), 'is-pulled-right is-success is-outlined mr-3')} />
                    <Button icon="ion-md-search" text="Жисмоний стир" onClick={() => requestSort('directorStir')}
                        className={cn(getClassNamesFor('directorStir'), 'is-pulled-right is-success is-outlined')} />
                </div>
            </div>

            <Table
                loading={finances.loading}
                items={items}
                columns={{
                    id: '№',
                    name: 'Мижоз корхона номи',
                    stir: 'Юридик стир',
                    directorStir: 'Жисмоний стир',
                    uploadRotation: 'Фойда солиғи',
                    commentRotation: 'Изоҳ',
                    tax: 'Солиқ     ',
                    ad: '',
                }}
                renderItem={(item, index) => (
                    <ManthlyIncomeQuarterlyItemItem index={index} key={item.id} onUpdate={finances.request}
                        onDelete={finances.request} request={reportItems.request}
                        reportItems={reportItems.response ? reportItems.response.results : []}
                        incomeQuarterlyId={incomeQuarterlyId} finance={item} />
                )}
            />
        </Layout>
    )
}
