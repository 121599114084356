import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Layout from "../../components/Layout";
import { useLoad } from "../../hooks/request";
import Table from "../../components/common/Table";
import QqcMothlyItem from "./Qqc-mothly-item-table";
import RotationItemTableColumn from "./rotation-item-table-column";
import IncomeTaxTableColumn from "./income-tax-table-column";
import IncomeQueryItemTableColumn from "./income-query-item-table-column";
import ReultItemTableColumn from "./reult-item-table-column";
import BalanceItemTableColumn from "./balance-item-table-column";
import { BALANCE_ALL_ITEM_LIST, FINANCE_DETAIL, INCOME_QUARTERLY_ALL_ITEM_LIST, INCOME_TAX_ALL_ITEM_LIST, QQC_MONTHLY_ALL_ITEM_LIST, RESULT_ALL_ITEM_LIST, ROTATION_ALL_ITEM_LIST } from "../../urls";
import { format } from "../../utils/number";

export default function ZeroClientDetail() {
  const { pathname } = useLocation();
  const clientId = pathname.split("/")[3];
  const [url, setUrl] = useState(INCOME_TAX_ALL_ITEM_LIST);

  const item = useLoad({ url, params: { finance: clientId } });

  const finance = useLoad({ url: FINANCE_DETAIL.replace("{id}", clientId) });
  const data = finance?.response;

  // const due = useLoad({ url: DUE_LIST });
  // const dueItem = due.response ? due.response.results : [];

  const handleTagClick = (newUrl) => {
    setUrl(newUrl);
  };

  useEffect(() => {
    item.request();
  }, [url]);

  return (
    <Layout>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
        <Link className="button is-white is-link is-outlined" to="/clients">
          <i className="icon ion-md-arrow-back is-size-4" />
        </Link>
        <h1 style={{ fontSize: "24px", fontWeight: "bold", marginLeft: "16px", color: "#111827" }}>{data?.name}</h1>
      </div>

      <div style={{ backgroundColor: "#fff", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "24px", margin: "0 auto" }}>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "24px" }}>
          <div>
            <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "12px", color: "#374151" }}>Компания ҳақида маълумот</h2>
            <p>
              <strong>Корхона номи:</strong> {data?.name}
            </p>
            <p>
              <strong>Корхона СТИР:</strong> {data?.stir}
            </p>
            <p>
              <strong>Банк:</strong> {data?.bank}
            </p>
            <p>
              <strong>Сумма контракта:</strong> {String(data?.contractSum).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Сўм
            </p>
            <p>
              <strong>Солик Тури:</strong> {data?.type === "rotation" ? "Айланма" : "ҚҚC-ойлик"}
            </p>
          </div>

          <div>
            <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "12px", color: "#374151" }}>Директор ҳақида маълумот</h2>
            <p>
              <strong>Корхона рахбари:</strong> {data?.directorName}
            </p>
            <p>
              <strong>Корхона рахбари СТИР:</strong> {data?.directorStir}
            </p>
           {/* <p>
              <strong>Солик инспектори:</strong> {data?.inspector}
            </p>
            <p>
              <strong>Хизмат кўрсатувчи банк оператори телефон:</strong> {data?.phoneNumber}
            </p>*/}
          </div>

          <div>
            <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "12px", color: "#374151" }}>Молиявий тафсилотлар</h2>
            <p>
              <strong>Х/р:</strong> {data?.accountNumber}
            </p>
            <p>
              <strong>МФО:</strong> {data?.mfo}
            </p>
            <p>
              <strong>ҚҚСга ўтган ой:</strong> {data?.tax_date}
            </p>
            <p>
              <strong>Охирги юбориш санаси :</strong> {new Date(data?.lastSendDate).toLocaleString()}
            </p>
          </div>

          <div>
            <h2 style={{ fontSize: "18px", fontWeight: "500", marginBottom: "12px", color: "#374151" }}>Ҳужжатлар</h2>
            {data?.first && (
              <a href={data?.first} style={{ color: "#1d4ed8", textDecoration: "underline", display: "block", marginBottom: "8px" }} target="_blank" rel="noopener noreferrer">
                Корхонанинг ЭРИ калити
              </a>
            )}
            {data?.second && (
              <a href={data?.second} style={{ color: "#1d4ed8", textDecoration: "underline", display: "block", marginBottom: "8px" }} target="_blank" rel="noopener noreferrer">
                Раҳбарнинг ЭРИ калити
              </a>
            )}
            <p>
              <strong>Интернет банк логин:</strong> {data?.login || "Not Available"}
            </p>
          </div>
        </div>
      </div>

      <div className="is-flex mt-4">
        {data?.type !== "qqc_monthly" && (
          <p
            style={{ backgroundColor: url === "zero/rotation-item" ? "#007bff" : "#f0f0f0", color: url === "zero/rotation-item" ? "white" : "black", cursor: "pointer" }}
            className={`tag is-medium is-light mr-2 ${url === "zero/rotation-item" ? "is-active" : ""}`}
            onClick={() => handleTagClick(ROTATION_ALL_ITEM_LIST)}
          >
            Айланма
          </p>
        )}

        <p
          style={{ backgroundColor: url === "zero/income-tax-item" ? "#007bff" : "#f0f0f0", color: url === "zero/income-tax-item" ? "white" : "black", cursor: "pointer" }}
          className={`tag is-medium is-light mr-2 ${url === "zero/income-tax-item" ? "is-active" : ""}`}
          onClick={() => handleTagClick(INCOME_TAX_ALL_ITEM_LIST)}
        >
          ЖШДС
        </p>

        <p
          style={{ backgroundColor: url === "zero/qqc-monthly-item" ? "#007bff" : "#f0f0f0", color: url === "zero/qqc-monthly-item" ? "white" : "black", cursor: "pointer" }}
          className={`tag is-medium is-light mr-2 is-active ${url === "zero/qqc-monthly-item" ? "is-active" : ""}`}
          onClick={() => handleTagClick(QQC_MONTHLY_ALL_ITEM_LIST)}
        >
          ҚҚС Ойлик
        </p>
        <p
          style={{ backgroundColor: url === "zero/income-quarterly-item" ? "#007bff" : "#f0f0f0", color: url === "zero/income-quarterly-item" ? "white" : "black", cursor: "pointer" }}
          className={`tag is-medium is-light mr-2 ${url === "zero/income-quarterly-item" ? "is-active" : ""}`}
          onClick={() => handleTagClick(INCOME_QUARTERLY_ALL_ITEM_LIST)}
        >
          Фойда солиғи
        </p>
        <p
          style={{ backgroundColor: url === "zero/result-item" ? "#007bff" : "#f0f0f0", color: url === "zero/result-item" ? "white" : "black", cursor: "pointer" }}
          className={`tag is-medium is-light mr-2 ${url === "zero/result-item" ? "is-active" : ""}`}
          onClick={() => handleTagClick(RESULT_ALL_ITEM_LIST)}
        >
          Молиявий Натижа
        </p>
        <p
          style={{ backgroundColor: url === "zero/balance-item" ? "#007bff" : "#f0f0f0", color: url === "zero/balance-item" ? "white" : "black", cursor: "pointer" }}
          className={`tag is-medium is-light mr-2 ${url === "zero/balance-item" ? "is-active" : ""}`}
          onClick={() => handleTagClick(BALANCE_ALL_ITEM_LIST)}
        >
          Молиявий Баланс
        </p>

        {/* {dueItem.map((item) => ( */}
        {/*   <div> */}
        {/*     <span className="tag is-medium is-light mr-2" style={{ cursor: "pointer" }}> */}
        {/*       {item.name} */}
        {/*     </span> */}
        {/*   </div> */}
        {/* ))} */}
      </div>

      {/*   Айланма */}

      {url === "zero/rotation-item" && item.response && (
        <div style={{ overflow: "scroll", marginTop: 20 }}>
          <div style={{ overflow: "scroll" }}>
            <Table
              loading={item.loading}
              items={item.response ? item.response.results : []}
              columns={{ id: "№", selectIncomeTax: "Сана", directorStir: "Юкланган файл", directorName: "Коментария" }}
              renderItem={(item, index) => <RotationItemTableColumn selectedTag={"Айланма"} page={1} key={item.id} index={index} onUpdate={finance.request} onDelete={finance.request} item={item} />}
            />
          </div>
        </div>
      )}

      {/*   ЖШДС */}

      {url === "zero/income-tax-item" && item.response && (
        <div style={{ overflow: "scroll", marginTop: 20 }}>
          <div style={{ overflow: "scroll" }}>
            <Table
              loading={item.loading}
              items={item.response ? item.response.results : []}
              columns={{ id: "№", selectIncomeTax: "Сана", directorStir: "Юкланган файл", directorName: "Коментария", type: "Солиқ", inps: "ИНПС" }}
              renderItem={(item, index) => <IncomeTaxTableColumn selectedTag={"ЖШДС"} page={1} key={item.id} index={index} onUpdate={finance.request} onDelete={finance.request} item={item} />}
            />
          </div>
        </div>
      )}

      {/*   ҚҚС Ойлик */}

      {url === "zero/qqc-monthly-item" && item.response && (
        <div style={{ overflow: "scroll", marginTop: 20 }}>
          <div style={{ overflow: "scroll" }}>
            <Table
              loading={item.loading}
              items={item.response ? item.response.results : []}
              columns={{ id: "№", selectIncomeTax: "Сана", directorStir: "Юкланган файл", directorName: "Коментария", type: "Солиқ" }}
              renderItem={(item, index) => <QqcMothlyItem selectedTag={"ҚҚС Ойлик"} page={1} key={item.id} index={index} onUpdate={finance.request} onDelete={finance.request} item={item} />}
            />
          </div>
        </div>
      )}

      {/*   Фойда солиғи */}

      {url === "zero/income-quarterly-item" && item.response && (
        <div style={{ overflow: "scroll", marginTop: 20 }}>
          <div style={{ overflow: "scroll" }}>
            <Table
              loading={item.loading}
              items={item.response ? item.response.results : []}
              columns={{ id: "№", selectIncomeTax: "Сана", directorStir: "Юкланган файл", directorName: "Коментария", type: "Солиқ" }}
              renderItem={(item, index) => <IncomeQueryItemTableColumn selectedTag={"Фойда солиғи"} page={1} key={item.id} index={index} onUpdate={finance.request} onDelete={finance.request} item={item} />}
            />
          </div>
        </div>
      )}

      {/*   Молиявий Натижа */}

      {url === "zero/result-item" && item.response && (
        <div style={{ overflow: "scroll", marginTop: 20 }}>
          <div style={{ overflow: "scroll" }}>
            <Table
              loading={item.loading}
              items={item.response ? item.response.results : []}
              columns={{ id: "№", selectIncomeTax: "Сана", directorStir: "Юкланган файл", directorName: "Коментария", type: "Солиқ" }}
              renderItem={(item, index) => <ReultItemTableColumn selectedTag={"Молиявий Натижа"} page={1} key={item.id} index={index} onUpdate={finance.request} onDelete={finance.request} item={item} />}
            />
          </div>
        </div>
      )}

      {/*   Молиявий Баланс */}

      {url === "zero/balance-item" && item.response && (
        <div style={{ overflow: "scroll", marginTop: 20 }}>
          <div style={{ overflow: "scroll" }}>
            <Table
              loading={item.loading}
              items={item.response ? item.response.results : []}
              columns={{ id: "№", selectIncomeTax: "Сана", directorStir: "Юкланган файл", directorName: "Коментария", type: "Солиқ" }}
              renderItem={(item, index) => <BalanceItemTableColumn selectedTag={"Молиявий Баланс"} page={1} key={item.id} index={index} onUpdate={finance.request} onDelete={finance.request} item={item} />}
            />
          </div>
        </div>
      )}
    </Layout>
  );
}
