import { Form, Formik } from "formik";
import React, { useState } from "react";
import { isEmpty } from "lodash";
import Input from "./componentsFinance/common/Input";
import Button from "./componentsFinance/common/Button";
import Checkbox from "./common/Checkbox";
import { domain } from "../utils/request";

export default function ManthlyRotationItemForm({ onCancel, loading, initialValues, rotationId, finance, onSubmit }) {
  const [file, setFile] = useState(null);

  async function onSuccess(data) {
    const newData = new FormData();
    if (file) {
      newData.append("rotationPdf", file, file.name);
    }
    newData.append("finance", finance.id);
    newData.append("rotation", rotationId);
    newData.append("rotationComment", data.rotationComment);
    newData.append("tax", data.tax);
    newData.append("inps", data.inps);
    onSubmit(newData);
  }

  return (
    <Formik
      onSubmit={onSuccess}
      initialValues={{
        rotationComment: "",
        tax: false,
        inps: false,
        ...initialValues,
        rotationPdf: initialValues && !isEmpty(initialValues.rotationPdf) ? initialValues.rotationPdf : "",
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className="columns">
            <div className="column">
              <Input name="rotationComment" label="Комментарий" disabled={loading} placeholder="Комментарий" />
            </div>
          </div>
          <Checkbox name="tax" label="Солиқлар тўланиши" disabled={loading} placeholder="Комментарий" />
          {/* <Checkbox */}
          {/*     name="inps" */}
          {/*     label="ИНПС реестр" */}
          {/*     disabled={loading} */}
          {/*     placeholder="Комментарий" /> */}
          <div className="columns">
            <div className="column">
              <p className="level-left">Айланма</p>
              <input
                onChange={(e) => {
                  setFieldValue("rotationPdf", e.target.value);
                  setFile(e.target.files[0]);
                }}
                disabled={loading}
                name="rotationPdf"
                type="file"
              />
              {initialValues ? (domain + initialValues.rotationPdf).split("/").pop() : "Файл юкланмаган"}
            </div>
          </div>
          <Button loading={loading} text="Сохранить" type="submit" icon="ion-md-checkmark" className="is-success" /> &nbsp;
          <Button onClick={onCancel} icon="ion-md-close" text="Отмена" className="is-danger" />
        </Form>
      )}
    </Formik>
  );
}
