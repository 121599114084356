import React from 'react'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { DUE_LIST, MANTHLY_DUE_LIST } from '../urls'
import SettingsTabs from '../components/SettingsTabs'
import Card from '../components/common/Card'
import Button from '../components/common/Button'
import DueItem from '../components/DueItem'
import Table from '../components/common/Table'
import DueCreate from '../components/DueCreate'
import { useModal } from '../hooks/modal'
import ManthlyDueCreate from '../components/ManthlyDueCreate'
import ManthlyDueItem from '../components/ManthlyDueItem'

export default function SettingsDue() {
    const due = useLoad({ url: DUE_LIST })
    const manthlydue = useLoad({ url: MANTHLY_DUE_LIST })

    const [showCreateBrandModal, hideCreateBrandModal] = useModal(
        <DueCreate
            onSuccess={() => {
                hideCreateBrandModal()
                due.request()
            }}
            onCancel={() => hideCreateBrandModal()}
        />,
    )
    const [showCreateManthlyDueModal, hideCreateManthlyDueModal] = useModal(
        <ManthlyDueCreate
            onSuccess={() => {
                hideCreateManthlyDueModal()
                manthlydue.request()
            }}
            onCancel={() => hideCreateManthlyDueModal()}
        />,
    )

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="columns">
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Ойлик учун солиқ тури</div>

                            <div className="column is-narrow">
                                <Button onClick={showCreateBrandModal} text="Добавить" icon="add-outline"
                                    className="is-pulled-right is-link is-outlined" />
                            </div>
                        </div>

                        <Table
                            loading={due.loading}
                            totalCount={due.response ? due.response.count : []}
                            items={due.response ? due.response.results : []}
                            renderItem={(item, index) => (
                                <DueItem index={index} brand={due} item={item} key={item.id}
                                    onDelete={due.request} onUpdate={due.request}
                                    status={due} />
                            )}
                        />
                    </Card>
                    <br />
                </div>
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Ноллик учун солиқ тури</div>

                            <div className="column is-narrow">
                                <Button onClick={showCreateManthlyDueModal} text="Добавить" icon="add-outline"
                                    className="is-pulled-right is-link is-outlined" />
                            </div>
                        </div>

                        <Table
                            totalCount={manthlydue.response ? manthlydue.response.count : 0}
                            loading={manthlydue.loading}
                            items={manthlydue.response ? manthlydue.response.results : []}
                            renderItem={(item, index) => (
                                <ManthlyDueItem index={index} category={manthlydue} item={item}
                                    key={item.id} onDelete={manthlydue.request}
                                    onUpdate={manthlydue.request} />
                            )}
                        />
                    </Card>
                    <br />
                </div>
            </div>
        </Layout>
    )
}
