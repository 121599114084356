import React from "react";
import cn from "classnames";
import { Field, useFormikContext } from "formik";
import { css, StyleSheet } from "aphrodite";
import ValidationErrorMessage from "./ValidationErrorMessage";

export default function Input({ br = false, name, className, type, label, validate, imgSrc, component = "input", optional = false, help, left, icon, ...attributes }) {
  const { errors, touched } = useFormikContext();

  return (
    <div className="field">
      {label ? (
        <label htmlFor={name}>
          <div className="columns">
            {imgSrc ? (
              <div className="column is-narrow">
                <img src={imgSrc} width="50" alt="alt" />
              </div>
            ) : null}

            <div className="column">
              {label} &nbsp;
              {optional ? <span className="form-hint">не обязательно</span> : null}
            </div>
          </div>
        </label>
      ) : null}

      {icon ? (
        <div className={cn("column is-narrow", css(styles.icon))}>
          <ion-icon name={icon} />
        </div>
      ) : null}

      {br ? (
        <div>
          <br />
        </div>
      ) : (
        ""
      )}

      <div className="control">
        {left ? (
          <span className={css(styles.leftBlock)}>
            🇺🇿
            <span className={css(styles.leftBlockSpan)}> | +998</span>
          </span>
        ) : null}

        <Field className={cn(component, className, { [css(styles.input)]: left })} type={type || "text"} name={name} id={name} component={component} validate={validate} {...attributes} />

        <ValidationErrorMessage name={name} />
      </div>

      {help && (!errors[name] || !touched[name]) ? <p className="form-hint">{help}</p> : null}
    </div>
  );
}

const styles = StyleSheet.create({
  icon: {
    position: "absolute",
    top: 134,
    zIndex: 1,
  },
  leftBlock: {
    fontWeight: "bold",
    position: "absolute",
    color: "#C4C4C4",
    zIndex: 1,
    marginTop: "8px",
    marginLeft: "7px",
  },
  leftBlockSpan: {
    fontSize: 17,
    color: "#C4C4C4",
    fontWeight: "bold",
  },
  input: {
    paddingLeft: "90px",
  },
});
