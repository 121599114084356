/* eslint-disable no-nested-ternary */
import React from 'react'
import { ASSET_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { checkPermission as cp } from '../utils/auth'
import { useMessage } from '../hooks/message'
import Loader from './common/Loader'
import AssetUpdate from './AssetUpdate'

export default function AssetItem({ item, onDelete, onUpdate }) {
    const assetDelete = useDeleteRequest({ url: ASSET_DETAIL.replace('{id}', item.id) })

    const [showUpdateModal, hideUpdateModal] = useModal(
        <AssetUpdate asset={item} onUpdate={() => {
            onUpdate()
            hideUpdateModal()
        }} onCancel={() => {
            hideUpdateModal()
        }} />,
    )

    const [showMessage] = useMessage()

    async function deleteAsset() {
        if (!global.confirm('Вы действительно хотите удалить?')) return

        const { error } = await assetDelete.request()

        if (error) {
            showMessage(error.data.detail, 'is-danger')
            return
        }
        onDelete()
    }

    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.price}</td>
            <td>{item.usageStart}</td>
            <td>{item.usageEnd}</td>
            <td>{item.responsible.name}</td>

            <td>
                {cp('finance.delete_asset') ? (
                    !assetDelete.loading ? (
                        <i onClick={deleteAsset} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('finance.change_asset') ? (
                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />
                ) : null}
            </td>
        </tr>
    )
}
