import React from 'react'
import map from 'lodash/map'
import { usePostRequest } from '../hooks/request'
import { EMPLOYEE_LIST } from '../urls'
import EmployeeForm from './EmployeeForm'
import ServerError from './common/ServerError'

export default function EmployeeCreate({ onCancel, onSuccess }) {
    const employeeCreate = usePostRequest({ url: EMPLOYEE_LIST })

    async function onSubmit(data, actions) {
        const { success } = await employeeCreate.request({ data: {
            ...data, positions: map(data.positions, 'value'),
        } })

        if (success) {
            actions.resetForm()
            onSuccess()
        }
    }

    return (
        <div>
            <b>Добавить нового сотрудника</b><br /><br />
            <ServerError error={employeeCreate.error} />
            <EmployeeForm onSubmit={onSubmit} onCancel={onCancel} loading={employeeCreate.loading} />
        </div>
    )
}
