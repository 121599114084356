import React from "react";

import { getMonth } from "../../utils/date";
import { domain } from "../../utils/request";

export default function IncomeTaxTableColumn({ item, index, page }) {
  const itemsPerPage = 10;
  const position = (page - 1) * itemsPerPage + index + 1;

  return (
    <tr>
      <td className="has-text-center pr-3 pl-3">{position}</td>
      <td className="has-text-center pr-3 pl-3">{getMonth(item?.selectIncomeTax?.date)}</td>
      <td className="has-text-center pr-3 pl-3">
        <a href={domain + item?.incomeTaxPdf} style={{ color: "#1d4ed8", textDecoration: "underline", display: "block", marginBottom: "8px" }} target="_blank" rel="noopener noreferrer">
          {item?.incomeTaxPdf?.split("/")[4]?.split(".")[0]}
        </a>
      </td>
      <td className="has-text-center pr-3 pl-3">{item.incomeTaxComment}</td>
      <td className="has-text-centered">
        <input style={{ width: 22, height: 22 }} type="checkbox" checked={item.tax} />
      </td>
      <td className="has-text-centered">
        <input style={{ width: 22, height: 22 }} type="checkbox" checked={item.inps} />
      </td>
    </tr>
  );
}
