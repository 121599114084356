import React from 'react'
import cn from 'classnames'
import { NavLink } from 'react-router-dom'

import Button from './common/Button'
import { getMonth } from '../utils/date'
import { useModal } from '../hooks/modal'
import { INCOME_TAX_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import IncomeTaxUpdate from './IncomeTaxUpdate'
import { useDeleteRequest } from '../hooks/request'
import { checkPermission as cp } from '../utils/auth'
import Progress from './componentsFinance/common/Progress'

export default function IncomeTaxItem({ reportItem, index, onUpdate, onDelete }) {
    const [showMessage] = useMessage()
    const rotationDelete = useDeleteRequest({ url: INCOME_TAX_DETAIL.replace('{id}', reportItem ? reportItem.id : '') })
    const [showUpdateModal, hideUpdateModal] = useModal(
        <IncomeTaxUpdate
            report={reportItem}
            onUpdate={() => {
                onUpdate()
                hideUpdateModal()
            }}
            onCancel={() => hideUpdateModal()}
        />,
    )

    async function deleteReport() {
        if (!global.confirm('Вы действительно хотите удалить?')) return
        const { error } = await rotationDelete.request()
        // eslint-disable-next-line consistent-return
        if (error) return showMessage(error.data.detail, 'is-danger')
        onDelete()
    }

    return (
        <tr className="">
            <td className="has-text-centered">{index + 1}</td>
            <td className={cn('mt-3 is-centered')}>
                <NavLink className="" to={`/zero/income-tax-item/${reportItem.id}`}>
                    <span className="tag is-info is-light is-medium">{getMonth(reportItem.date)}</span>
                </NavLink>
            </td>
            <td className={cn('has-text-centered')}>{reportItem.clientCount}</td>
            <td className={cn('has-text-centered')}>{reportItem.taskCount}</td>
            <td className="has-text-centered">{reportItem.taxCount}</td>
            <td className="has-text-centered">{reportItem.inpsCount}</td>
            <td className="has-text-centered">
                <span className="tag is-info is-light mb-2 is-medium">Ҳисоботлар Жунатилиши </span> <br />
                <span className="tag is-success is-light mb-2 is-medium">Солиқлар тўланиши </span> <br />
                <span className="tag is-danger is-light mb-2 is-medium">ИНПС реестр жунатилиши</span> <br />
            </td>
            <td className="has-text-centered">
                <div style={{ width: 150 }} className="mt-2">
                    <Progress value={reportItem.progress} text={`${reportItem.progress} %`} /> <br />
                    <Progress value={reportItem.progressTax} text={`${reportItem.progressTax} %`} /> <br />
                    <Progress value={reportItem.progressInps} text={`${reportItem.progressInps} %`} /> <br />
                </div>
            </td>
            {cp('staff.delete_employee') ? (
                <td className="has-text-centered">
                    <div className="is-flex">
                        <Button className="is-outlined is-danger mb-2 mr-2 is-small" icon="ion-md-trash" onClick={deleteReport} />

                        <Button className="is-outlined is-success is-small" icon="ion-md-create" onClick={showUpdateModal} />
                    </div>
                </td>
            ) : null}
        </tr>
    )
}
