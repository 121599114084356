import React, { useState } from "react";
import Button from "../components/common/Button";
import { useModal } from "../hooks/modal";
import { useLoad } from "../hooks/request";
import { ROTATION_LIST } from "../urls";
import RotationItem from "../components/RotationItem";
import RotationCreate from "../components/RotationCreate";
import Layout from "../components/Layout";
import ZeroTabs from "../components/ZeroTabs";
import Table from "../components/common/Table";

export default function Rotation() {
  const [page, setPage] = useState(1);
  const report = useLoad({ url: ROTATION_LIST });
  const reportItem = report.response ? report.response.results : [];

  const [showModal, hideModal] = useModal(
    <RotationCreate
      onSuccess={() => {
        hideModal();
        report.request();
      }}
      onCancel={() => hideModal()}
    />,
  );

  return (
    <Layout>
      <div style={{ position: "sticky", top: 0, left: 0, width: "100%", zIndex: 10, backgroundColor: "white", paddingTop: "1rem" }}>
        <ZeroTabs />
        <div className="columns mb-4 mr-3">
          <div className="column is-capitalized ml-4">
            <span className="is-size-4">Айланма:</span>
          </div>
          <div className="column mt-3">
            <div className="columns is-pulled-right">
              <Button icon="ion-md-calendar" text="Янги ой қўшиш" onClick={showModal} className="button is-success is-outlined" />
            </div>
          </div>
        </div>
      </div>

      <Table
        loading={report.loading}
        items={reportItem}
        columns={{ id: "№", name: "Ой номи", stir: "Мижозлар", directorStir: "Ҳисоботлар Жунатилиши", ssuploadRotation: "Солиқлар тўланиши", ad: "", uploadRotation: "", assd: "" }}
        totalCount={report.response ? report.response.count : 0}
        pageSize={10}
        activePage={page}
        onPageChange={setPage}
        renderItem={(item, index) => (
          <RotationItem reportItem={item} index={index} key={item.id} onUpdate={report.request} onDelete={report.request} request={report.request} reportItems={reportItem.response ? reportItem.response.results : []} finance={item} />
        )}
      />
    </Layout>
  );
}
