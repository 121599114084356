import React from 'react'
import { useLoad } from '../hooks/request'
import { STATISTICS_OUTCOME } from '../urls'
import StatisticFinanceOutcomeChart from './StatisticsFinanceOutcomeChart'
import Card from './common/Card'


export default function StatisticsFinanceOutcome({ startDate, endDate }) {
    const outcome = useLoad({
        url: STATISTICS_OUTCOME,
        params: { startDate, endDate },
    })

    return (
        <div className="columns">
            <div className="column is-6">
                <Card>
                    <div className="title is-5 has-text-centered">
                        Статистика Расходов
                    </div>

                    <StatisticFinanceOutcomeChart
                        data={outcome.response ? outcome.response : []}
                        loading={outcome.loading}
                        field="outcomeAmount" />
                </Card>
            </div>
        </div>
    )
}
