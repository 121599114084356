import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Layout from '../components/Layout'
import { PermissionDenied } from '../components/PermissionDenied'
import { useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'
import { checkPermission as cp } from '../utils/auth'
import { PROJECT_SIMPLE_LIST } from '../urls'

export default function TaskProjects() {
    const params = useQueryParams()
    const projects = useLoad({ url: PROJECT_SIMPLE_LIST, params })

    if (!cp('tasks.view_task')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <div className={cn('columns', css(styles.heading))}>
                <div className="column">
                    <span className="is-size-4">Проекты</span>&nbsp; &nbsp;
                </div>
            </div>

            <br />

            <Table
                loading={projects.loading}
                totalCount={projects.response ? projects.response.count : 0}
                columns={{ title: 'Заголовок' }}
                items={projects.response ? projects.response.results : []}
                renderItem={(item) => (
                    <tr key={item.id}>
                        <td>
                            <NavLink to={`/task/projects/${item.id}/backlog`} className="has-text-dark">
                                {item.lead.name}
                            </NavLink>
                        </td>
                    </tr>
                )} />

            <br />
        </Layout>
    )
}

const styles = StyleSheet.create({
    heading: {
        marginBottom: 0,
    },
})
