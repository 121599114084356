import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import * as queryString from 'query-string'
import { StyleSheet } from 'aphrodite'
import { useQueryParams } from '../hooks/queryString'
import { useGetRequest, useLoad, useSortTableData } from '../hooks/request'
import { FINANCE_LIST, MANTHLY_FINANCE_LIST } from '../urls'
import TransactionSearch from '../components/TransactionSearch'
import Table from '../components/common/Table'
import Layout from '../components/Layout'
import FinanceKeysItem from '../components/FinanceKeysItem'
import Button from '../components/common/Button'

export default function FinanceKeys({ history }) {
    const params = useQueryParams()
    const [isVisible, setIsVisible] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [formData, setFormData] = useState({
        includeName: false,
        includeStir: false,
        includeDirectorName: false,
        includeDirectorStir: false,
        includeBank: false,
        includeAccount_number: false,
        mfo: false,
        inspector: false,
        phone_number: false,
        login: false,
        type: false,
        tax_date: false,
        contract_sum: false,
        includeMonthly: true,
        includeZero: false,
    })

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target
        setFormData((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }))
    }

    const handleClick = () => {
        setIsVisible(!isVisible)
    }

    const handleExport = () => {
        const headers = ['№']
        const data = []

        let monthlyIndex = 1
        let zeroIndex = 1

        if (formData.includeZero) {
            const monthlyData = finance_manthly.response ? finance_manthly.response.results : []
            monthlyData.forEach((row) => {
                const rowData = [monthlyIndex++]
                if (formData.includeName) {
                    headers.includes('Корхона номи') || headers.push('Корхона номи')
                    rowData.push(row.name)
                }
                if (formData.includeStir) {
                    headers.includes('Юридик стир') || headers.push('Юридик стир')
                    rowData.push(row.stir)
                }
                if (formData.includeDirectorName) {
                    headers.includes('Корхона рахбари') || headers.push('Корхона рахбари')
                    rowData.push(row.directorName)
                }
                if (formData.includeDirectorStir) {
                    headers.includes('Жисмоний стир') || headers.push('Жисмоний стир')
                    rowData.push(row.directorStir)
                }
                if (formData.includeBank) {
                    headers.includes('Банк') || headers.push('Банк')
                    rowData.push(row.bank)
                }
                if (formData.includeAccount_number) {
                    headers.includes('Х/р') || headers.push('Х/р')
                    rowData.push(row.accountNumber)
                }
                if (formData.mfo) {
                    headers.includes('МФО') || headers.push('МФО')
                    rowData.push(row.mfo)
                }
                if (formData.inspector) {
                    headers.includes('Солик инспектори') || headers.push('Солик инспектори')
                    rowData.push(row.inspector)
                }
                if (formData.phone_number) {
                    headers.includes('Корхона рахбари телефони') || headers.push('Корхона рахбари телефони')
                    rowData.push(row.phoneNumber)
                }
                if (formData.login) {
                    headers.includes('Интернет банк логин') || headers.push('Интернет банк логин')
                    rowData.push(row.login)
                }
                if (formData.type) {
                    headers.includes('Солиқ тури') || headers.push('Солиқ тури')
                    rowData.push(row.type === 'qqc_monthly' ? 'Айланма' : 'ҚҚC-ойлик')
                }
                if (formData.tax_date) {
                    headers.includes('ҚҚСга ўтган ой') || headers.push('ҚҚСга ўтган ой')
                    rowData.push(row.taxDate)
                }
                if (formData.contract_sum) {
                    headers.includes('Сумма контракта') || headers.push('Сумма контракта')
                    rowData.push(row.contractSum)
                }
                data.push(rowData)
            })
        }

        if (formData.includeMonthly) {
            const zeroData = finance.response ? finance.response.results : []
            zeroData.forEach((row) => {
                const rowData = [zeroIndex++ + monthlyIndex - 1]
                if (formData.includeName) {
                    headers.includes('Корхона номи') || headers.push('Корхона номи')
                    rowData.push(row.name)
                }
                if (formData.includeStir) {
                    headers.includes('Юридик стир') || headers.push('Юридик стир')
                    rowData.push(row.stir)
                }
                if (formData.includeDirectorName) {
                    headers.includes('Корхона рахбари') || headers.push('Корхона рахбари')
                    rowData.push(row.directorName)
                }
                if (formData.includeDirectorStir) {
                    headers.includes('Жисмоний стир') || headers.push('Жисмоний стир')
                    rowData.push(row.directorStir)
                }
                if (formData.includeBank) {
                    headers.includes('Банк') || headers.push('Банк')
                    rowData.push(row.bank)
                }
                if (formData.includeAccount_number) {
                    headers.includes('Х/р') || headers.push('Х/р')
                    rowData.push(row.accountNumber)
                }
                if (formData.mfo) {
                    headers.includes('МФО') || headers.push('МФО')
                    rowData.push(row.mfo)
                }
                if (formData.inspector) {
                    headers.includes('Солик инспектори') || headers.push('Солик инспектори')
                    rowData.push(row.inspector)
                }
                if (formData.phone_number) {
                    headers.includes('Корхона рахбари телефони') || headers.push('Корхона рахбари телефони')
                    rowData.push(row.phoneNumber)
                }
                if (formData.login) {
                    headers.includes('Интернет банк логин') || headers.push('Интернет банк логин')
                    rowData.push(row.login)
                }
                if (formData.type) {
                    headers.includes('Солиқ тури') || headers.push('Солиқ тури')
                    rowData.push(row.type === 'qqc_monthly' ? 'Айланма' : 'ҚҚC-ойлик')
                }
                if (formData.tax_date) {
                    headers.includes('ҚҚСга ўтган ой') || headers.push('ҚҚСга ўтган ой')
                    rowData.push(row.tax_date)
                }
                if (formData.contract_sum) {
                    headers.includes('Сумма контракта') || headers.push('Сумма контракта')
                    rowData.push(row.contract_sum)
                }
                data.push(rowData)
            })
        }

        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data])

        const maxLengths = headers.map((header, colIndex) => Math.max(header.length, ...data.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))))
        worksheet['!cols'] = maxLengths.map((length) => ({ width: length + 10 }))
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
        saveAs(blob, 'export.xlsx')
        setModalOpen(false)
    }

    const finance = useLoad({ url: FINANCE_LIST, params: { ...params, status: 'active' } }, [params])
    const { items } = useSortTableData(finance.response ? finance.response.results : [])

    const finance_manthly = useLoad({ url: MANTHLY_FINANCE_LIST, params: { ...params, status: 'active' } }, [params])
    const { items: items_manthly } = useSortTableData(finance_manthly.response ? finance_manthly.response.results : [])
    const allItems = [...items.map((item) => ({
        ...item,
        month_type: 'manthly',
    })), ...items_manthly.map((item) => ({ ...item, month_type: 'zero' }))]


    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ search })}`)
    }

    const updateKeys = useGetRequest({ url: '/zero/zero-pfx' })

    function update() {
        const { success } = updateKeys.request()
        if (success) {
            finance.request()
        }
    }

    return (
        <Layout>
            <div style={{
                position: 'sticky',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 10,
                backgroundColor: 'white',
                margin: '0 auto',
                padding: '0px',
                display: 'flex',
                justifyContent: 'space-between',
            }} />
            <div className="column mt-3" style={{ padding: '5px', maxWidth: '700px' }}>
                <div className="is-flex">
                    <TransactionSearch onSearch={onSearch} />
                    <Button loading={updateKeys.loading} onClick={update} text="Янгилаш"
                        className="is-outlined is-link" />
                </div>
            </div>


            <div style={{ overflow: 'scroll' }}>
                <div style={{ overflow: 'scroll' }}>
                    <Table
                        loading={finance.loading}
                        items={allItems}
                        columns={{
                            id: '№',
                            name: 'Корхона номи',
                            stir: 'Корхона стири',
                            directorName: 'Корхона рахбари',
                            directorStir: 'Рахбари стири',
                            first: 'ЭРИ (Корхона)',
                            assds: 'Aмал қилиш муддати',
                            second: 'ЭРИ (Раҳбар)',
                            asds: 'Aмал қилиш муддати',
                        }}
                        renderItem={(item, index) => (
                            <FinanceKeysItem page={1} key={item.id} index={index}
                                onUpdate={() => {
                                    finance.request() // Call finance request
                                    finance_manthly.request() // Call finance monthly request
                                }}
                                onDelete={() => {
                                    finance.request() // Call finance request
                                    finance_manthly.request() // Call finance monthly request
                                }}
                                item={item} />
                        )}
                    />


                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({ modal: { width: 800 } })
