import React from 'react'
import { Formik, Form } from 'formik'
import Button from './common/Button'
import Select from './common/Select'
import MultiSelect from './common/MultiSelect'
import { memberRoles } from '../utils/memberRoles'
import { required } from '../utils/validators'


export default function ProjectForm({ loading, onSubmit, onCancel, employees, values, onBack }) {
    const initialValues = { employee: '', roles: '', ...values }

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            <Form>
                <Select
                    name="employee"
                    label="Сотрудник"
                    empty
                    validate={required}
                    options={employees} />

                <MultiSelect
                    name="roles"
                    label="Роль"
                    empty
                    validate={required}
                    options={memberRoles} />

                <Button
                    loading={loading}
                    text="Сохранить"
                    type="submit"
                    icon="ion-md-checkmark"
                    className="is-success" /> &nbsp;

                <Button
                    onClick={onCancel}
                    icon="ion-md-close"
                    text="Закрыть"
                    className="is-danger" /> &nbsp;

                <Button
                    onClick={onBack}
                    icon="ion-md-arrow-back"
                    text="Назад"
                    className="is-link is-pulled-right" />

            </Form>
        </Formik>
    )
}
