import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export default function SettingsTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                {/* {cp('finance.view_wallet') ? ( */}
                {/*    <li className={cn({ 'is-active': path.startsWith('/settings/finance') })}> */}
                {/*        <NavLink to="/settings/finance">Финансы</NavLink> */}
                {/*    </li> */}
                {/* ) : null} */}

                {/* {cp('marketing.view_saleschannel') ? ( */}
                {/*    <li className={cn({ 'is-active': path.startsWith('/settings/marketing') })}> */}
                {/*        <NavLink to="/settings/marketing">Маркетинг</NavLink> */}
                {/*    </li> */}
                {/* ) : null} */}

                <li className={cn({ 'is-active': path.startsWith('/settings/user') })}>
                    <NavLink to="/settings/user">Аккаунт пользователя</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/settings/due') })}>
                    <NavLink to="/settings/due">Солиқ турлари</NavLink>
                </li>
            </ul>
        </div>
    )
}
