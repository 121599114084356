import React from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile'
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet'
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn'

import Layout from '../components/Layout'
import { getMonth } from '../utils/date'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import { useQueryParams } from '../hooks/queryString'
import { useLoad, useSortTableData } from '../hooks/request'
import { DUE_DATE_DETAIL, DUE_ITEM_LIST, FINANCE_LIST } from '../urls'
import DueItemItem from '../components/DueItemItem'

export default function UploadDue() {
    const { dueDateId, dueId } = useParams()
    // const { dueId } = useQueryParams()
    const params = useQueryParams()
    const finances = useLoad({ url: FINANCE_LIST, params: { due: dueId, ...params } })
    const reportDetail = useLoad({ url: DUE_DATE_DETAIL.replace('{id}', dueDateId) })
    const reportItems = useLoad({ url: DUE_ITEM_LIST.replace('{dueDateId}', dueDateId) })
    const { items, requestSort, sortConfig } = useSortTableData(finances.response ? finances.response.results : [])
    const getClassNamesFor = (name) => {
        if (!sortConfig) return

        return sortConfig.key === name ? sortConfig.direction : undefined
    }

    return (
        <Layout>
            <div className="">
                <div className="level">
                    <div className="level-left">
                        <span
                            className="title is-capitalized">{reportDetail.response ? getMonth(reportDetail.response.date) : ''}
                        </span>
                    </div>
                    <div className="level-right">
                        <div className="is-pulled-right is-success is-outlined mr-3">
                            <ExcelFile element={(
                                <Button icon="ion-md-download" text="Excel"
                                    className="is-pulled-right is-success is-outlined mr-3" />
                            )}
                                filename={reportDetail.response ? getMonth(reportDetail.response.date) : ''}>
                                <ExcelSheet name="Excel" data={finances.response ? finances.response.results : []}>
                                    <ExcelColumn label="Корхона номи" value="name" />
                                    <ExcelColumn label="Юридик стир" value="stir" />
                                    <ExcelColumn label="Жисмоний стир" value="directorStir" />
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                        <Button icon="ion-md-search" text="Корхона номи"
                            className={cn(getClassNamesFor('name'), 'is-pulled-right is-success is-outlined mr-3')}
                            onClick={() => requestSort('name')} />
                        <Button icon="ion-md-search" text="Юридик стир" onClick={() => requestSort('stir')}
                            className={cn(getClassNamesFor('stir'), 'is-pulled-right is-success is-outlined mr-3')} />
                        <Button icon="ion-md-search" text="Жисмоний стир" onClick={() => requestSort('directorStir')}
                            className={cn(getClassNamesFor('directorStir'), 'is-pulled-right is-success is-outlined')} />
                    </div>
                </div>

                <Table
                    loading={finances.loading}
                    items={items}
                    columns={{
                        id: '№',
                        name: 'Мижоз корхона номи',
                        stir: 'Юридик стир',
                        directorStir: 'Жисмоний стир',
                        uploadRotation: 'Айланма',
                        commentRotation: 'Изоҳ',
                        tax: 'Солиқ',
                        ad: '',
                    }}
                    renderItem={(item, index) => (
                        <DueItemItem index={index} key={item.id}
                            onUpdate={finances.request}
                            onDelete={finances.request}
                            request={reportItems.request}
                            reportItems={reportItems.response ? reportItems.response.results : []}
                            dueDate={dueDateId} finance={item} />
                    )}
                />
            </div>
        </Layout>
    )
}
