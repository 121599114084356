import React from 'react'
import { usePostRequest } from '../hooks/request'
import { CUSTOMER_LIST, LEAD_LIST } from '../urls'
import LeadForm from './LeadForm'
import { useModal } from '../hooks/modal'
import ProjectCreate from './ProjectCreate'

export default function LeadCreate({ status, onCancel, onSuccess }) {
    const leadCreate = usePostRequest({ url: LEAD_LIST })
    const customerCreate = usePostRequest({ url: CUSTOMER_LIST })

    const [showCreteProject, closeCreateProject] = useModal((props) => (
        <ProjectCreate
            lead={props.lead}
            customer={props.customer}
            onCancel={() => closeCreateProject()}
            onSuccess={(project) => {
                onSuccess({ ...props.lead, project })
            }} />
    ))

    async function onSubmit(data, actions) {
        let { customer } = data

        if (data.customerPick === 'create') {
            const { response } = await customerCreate.request({
                data: {
                    name: data.customerName,
                    phone: data.customerPhone,
                },
            })

            customer = response.id
        }

        const lead = await leadCreate.request({ data: { ...data, status: status.id, customer } })

        actions.resetForm()

        if (status.type === 'accepted') {
            showCreteProject({ lead: lead.response, customer })
            return
        }

        onSuccess({ ...lead.response })
    }

    return (
        <div>
            <b>Добавить новый Лид</b><br /><br />

            <LeadForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                status={status}
                loading={leadCreate.loading} />
        </div>
    )
}
