import React from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { BOARD_LIST, PROJECT_SIMPLE_DETAIL } from '../urls'
import Table from '../components/common/Table'
import { checkPermission as cp } from '../utils/auth'
import TaskProjectBoardsItem from '../components/TaskProjectBoardsItem'
import TaskBoardTabs from '../components/TaskBoardTabs'
import Loader from '../components/common/Loader'
import { PermissionDenied } from '../components/PermissionDenied'

export default function TaskProjectBoards() {
    const params = useParams()
    const project = useLoad({ url: PROJECT_SIMPLE_DETAIL.replace('{id}', params.projectId) })
    const boards = useLoad({ url: BOARD_LIST, params: { project: params.projectId } }, [], cp('tasks.view_board'))

    if (project.loading || !project.response) {
        return <Loader center large />
    }
    if (!cp('tasks.view_task') || !cp('tasks.view_board')) {
        return <PermissionDenied />
    }

    return (
        <Layout>
            <TaskBoardTabs project={project.response} />
            <Table
                loading={boards.loading}
                totalCount={boards.response ? boards.response.count : 0}
                items={boards.response ? boards.response.results : []}
                columns={{ name: 'Название', progress: 'Прогресс', isActive: 'Статус' }}
                renderItem={(item) => (
                    <TaskProjectBoardsItem key={item.id} project={project.response} item={item} />
                )} />
        </Layout>
    )
}
