/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import cn from 'classnames'
import React, { useState } from 'react'
import { checkPermission as cp } from '../utils/auth'
import { useDeleteRequest, usePutRequest } from '../hooks/request'
import { WALLET_DETAIL, WALLET_CHANGE_MAIN } from '../urls'
import { useModal } from '../hooks/modal'
import { useMessage } from '../hooks/message'
import WalletUpdate from './WalletUpdate'
import Loader from './common/Loader'


export default function WalletSettingsItem({ wallets, item }) {
    const [showMessage] = useMessage()
    const walletDelete = useDeleteRequest({ url: WALLET_DETAIL.replace('{id}', item.id) })
    const walletChangeMain = usePutRequest()
    const [showDropdown, setShowDropdown] = useState(false)

    async function changeMain() {
        await walletChangeMain.request({ url: WALLET_CHANGE_MAIN.replace('{id}', item.id) })
        wallets.request()
    }


    async function deleteWallet() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const { error } = await walletDelete.request()

            if (error) {
                showMessage(error.data.detail, 'is-danger')
            }
        }
        wallets.request()
    }

    const [showUpdateWallet, hideUpdateWallet] = useModal(
        <WalletUpdate
            wallet={item}
            values={wallets}
            onCancel={() => hideUpdateWallet()}
            onSuccess={() => {
                wallets.request()
                hideUpdateWallet()
            }} />,
    )

    return (
        <tr>
            <td>{item.id}</td>

            <td>
                {item.name}<span className="is-size-7 has-text-success">{item.isMain ? ' (основной)' : ''}</span>
            </td>

            <td><i className={`icon ${item.icon}`} /></td>
            <td>
                {cp('finance.delete_wallet') ? (
                    !walletDelete.loading ? (
                        <i onClick={() => deleteWallet()} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />
                ) : null}

                {cp('finance.change_wallet') ? (
                    <i onClick={showUpdateWallet} className="icon pointer ion-md-create" />
                ) : null}

                <div
                    className={cn('dropdown', { 'is-active': showDropdown })}
                    onMouseLeave={() => setShowDropdown(false)}>
                    <div className="dropdown-trigger">
                        <i className="icon ion-md-more" onClick={() => setShowDropdown(!showDropdown)} />
                    </div>

                    <div className="dropdown-menu">
                        <div className="dropdown-content">
                            {cp('finance.change_wallet') && !item.isMain ? (
                                <a className="dropdown-item" onClick={changeMain}>
                                    <i className="icon ion-md-checkmark" />
                                    Сделать главным
                                </a>
                            ) : (
                                <a className="dropdown-item">
                                    <i className="icon ion-md-checkmark" />
                                    Главный
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}
