import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { useInfiniteScroll, useLoad } from '../hooks/request';
import { BOARD_DETAIL, BOARD_TASK_LIST } from '../urls';
import Loader from '../components/common/Loader';
import Table from '../components/common/Table';
import TaskItem from '../components/TaskItem';
import TaskProjectBoardActions from '../components/TaskProjectBoardActions';
import BoardTabs from '../components/BoardTabs';

export default function TaskProjectBoardTable() {
	const params = useParams();
	const tasks = useInfiniteScroll({ url: BOARD_TASK_LIST.replace('{board_id}', params.id) });
	const board = useLoad({ url: BOARD_DETAIL.replace('{id}', params.id) });
	if (board.loading || !board.response) {
		return (
			<Layout>
				<Loader center large />
			</Layout>
		);
	}

	function updateTotalPoints(diff) {
		board.setResponse({ ...board.response, totalPoints: board.response.totalPoints + diff });
	}

	return (
		<Layout className={css(styles.header)}>
			<div className='columns' style={{ position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 10, backgroundColor: 'white' }}>
				<div className='column'>
					<span className='is-size-4'>
						<BoardTabs />
					</span>{' '}
					&nbsp; &nbsp;
				</div>

				<div className='column is-narrow'>
					<TaskProjectBoardActions onUpdate={board.setResponse} board={board.response} tasks={tasks} />
				</div>
			</div>

			<Table
				loading={tasks.loading}
				totalCount={tasks.response ? tasks.response.count : 0}
				pageSize={15}
				columns={{
					s: '№',
					time: 'Berilgan vaqt',
					title: 'Topshiriq',
					assigned: 'Xodim',
					dueTime: 'Qancha vaqtda bajarganligi',
					status: 'Holati',
					comment: 'Izoh',
				}}
				border
				items={tasks.response ? tasks.response.results : []}
				renderItem={(item, index) => <TaskItem tasks={tasks} board={board.response} onPointsUpdate={updateTotalPoints} index={index} onDelete={tasks.request} onUpdate={tasks.request} key={item.id} item={item} />}
			/>
		</Layout>
	);
}

const styles = StyleSheet.create({
	header: {
		// background: 'whitesmoke',
		zIndex: 1,
		position: 'sticky',
		top: '0',
	},
});
