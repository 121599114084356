import React from 'react'
import { usePutRequest } from '../hooks/request'
import ServerError from './componentsFinance/common/ServerError'
import ManthlyIncomeTaxItemForm from './ManthlyIncomeTaxItemForm'
import { MANTHLY_INCOME_TAX_ITEM_DETAIL } from '../urls'

export default function ManthlyIncomeTaxItemUpdate({ onCancel, onSuccess, reportItem, finance, incomeTaxId }) {
    const url = MANTHLY_INCOME_TAX_ITEM_DETAIL.replace('{id}', reportItem.id)
    const incomeTaxItemUpdate = usePutRequest({ url })

    async function onSubmit(data) {
        const { success } = await incomeTaxItemUpdate.request({ data })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <b>Изменить данные</b><br /><br />
            <ServerError error={incomeTaxItemUpdate.error} />
            <ManthlyIncomeTaxItemForm
                initialValues={reportItem}
                onSubmit={onSubmit}
                onCancel={onCancel}
                incomeTaxId={incomeTaxId}
                finance={finance}
                loading={incomeTaxItemUpdate.loading} />
        </div>
    )
}
