import React from "react";
import { NavLink } from "react-router-dom";
import Layout from "../components/Layout";
import Button from "../components/common/Button";

export default function FinanceType() {
  return (
    <Layout>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <NavLink to="/zero/rotation" style={{ marginRight: "20px" }}>
          <Button text="Ойлик ҳисобот" className="is-link is-outlined" style={{ padding: "15px 30px", fontSize: "20px", fontWeight: "bold" }} />
        </NavLink>

        <div style={{ height: "50px", width: "2px", backgroundColor: "gray", marginRight: "20px" }}></div>

        <NavLink to="/manthly/rotation">
          <Button text="Ноллик ҳисобот" className="is-link is-outlined" style={{ padding: "15px 30px", fontSize: "20px", fontWeight: "bold" }} />
        </NavLink>
      </div>
    </Layout>
  );
}
