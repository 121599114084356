import React from 'react'
import { usePostRequest } from '../hooks/request'
import { BALANCE_LIST } from '../urls'
import BalanceForm from './BalanceForm'

export default function BalanceCreate({ onCancel, onSuccess }) {
    const rotationCreate = usePostRequest({ url: BALANCE_LIST })

    async function onSubmit(data, actions) {
        const { success } = await rotationCreate.request({ data })

        if (success) {
            onSuccess()
            actions.resetForm()
        }
    }

    return (
        <div>
            <b>Добавить</b><br /><br />
            <BalanceForm onSubmit={onSubmit} onCancel={onCancel} loading={rotationCreate.loading} />
        </div>
    )
}
