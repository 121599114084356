import React, { useState } from "react";

import { getMonth } from "../../utils/date";

export default function QqcMothlyItem({ item, index, page }) {
  const itemsPerPage = 10;
  const position = (page - 1) * itemsPerPage + index + 1;

  const [date, setDate] = useState(item?.selectQqcMonthly?.date);
  const [comment, setComment] = useState(item?.qqcMonthlyComment);
  // const [inps, setInps] = useState("");
  // const [tax, setTax] = useState("");
  const [pdf, setPdf] = useState(item?.qqcMonthlyPdf);

  // function handleInpsStatusChange(event) {
  //   const newTaxStatus = event.target.checked;
  //   setInps(newTaxStatus);
  // }
  //
  // function handleTaxStatusChange(event) {
  //   const newTaxStatus = event.target.checked;
  //   setTax(newTaxStatus);
  // }

  return (
    <tr>
      <td className="has-text-center pr-3 pl-3">{position}</td>
      <td className="has-text-center pr-3 pl-3">{getMonth(date)}</td>
      <td className="has-text-center pr-3 pl-3">
        <a
          href={pdf}
          style={{
            color: "#1d4ed8",
            textDecoration: "underline",
            display: "block",
            marginBottom: "8px",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {pdf?.split("/")[4]?.split(".")[0]}
        </a>
      </td>
      <td className="has-text-center pr-3 pl-3">{comment}</td>
      <td className="has-text-centered">
        <input
          style={{
            width: 22,
            height: 22,
          }}
          type="checkbox"
          checked={item.tax}
        />
      </td>
      {/* <td className="has-text-centered"> */}
      {/*   <input style={{ width: 22, height: 22 }} type="checkbox" checked={tax} onChange={handleTaxStatusChange} /> */}
      {/* </td> */}
    </tr>
  );
}
